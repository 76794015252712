/**
 * Generated by '@kontent-ai/model-generator@5.1.1'
 *
 * Project name: 🔴 NESA MAIN
 * Environment: 🔴 PROD
 * Project Id: 341419dc-8ec2-0289-7225-6db7f2d751ef
 */
export const contentTypes = {
  /**
   * 🖇️ Link Focus Area - Curriculum Connections
   * Last modified: Fri Aug 30 2024 04:29:10 GMT+0000 (Coordinated Universal Time)
   */
  web_link_cc_focusarea: {
    codename: 'web_link_cc_focusarea',
    id: 'a7540d09-4cdd-43a7-9fe3-066fe5373f2c',
    externalId: undefined,
    name: '🖇️ Link Focus Area - Curriculum Connections',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '696280a9-6fc6-48c5-8617-0ab97bda1f61',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: 'be1b71bd-ed4a-45ee-93a7-f1d3fa395ea1',
        externalId: undefined,
        name: '🔗 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: 'b93a2dfc-2820-4218-b55a-ef119817d1fb',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * Stage (taxonomy)
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'b06436b5-f744-417e-8660-e209e74d899a',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * ⚠️ Select 2 years within the stage for K-10 syllabuses and only 1 year for Senior syllabuses.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: 'a1b9ee95-05e1-4b28-a86b-d14ea0710912',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📑 Access Content Item
   * Last modified: Mon Dec 04 2023 01:31:12 GMT+0000 (Coordinated Universal Time)
   */
  accesscontentitem: {
    codename: 'accesscontentitem',
    id: 'd1986268-7e60-4c13-a398-f875377919aa',
    externalId: undefined,
    name: '📑 Access Content Item',
    elements: {
      /**
       * 📜 Title (rich_text)
       */
      title: {
        codename: 'title',
        id: '4b84a9e6-c5be-4254-895c-9418aebd9452',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Examples (rich_text)
       *
       * Note: Bullet points CANNOT be used in Examples
       */
      examples: {
        codename: 'examples',
        id: 'abe0f6e4-d952-4ecd-b491-01df9279d3ad',
        externalId: undefined,
        name: '📜 Examples',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Including Statements (rich_text)
       */
      including_statements: {
        codename: 'including_statements',
        id: 'b218a884-eef6-4cee-9e54-e3440b6300ca',
        externalId: undefined,
        name: '📜 Including Statements',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '0a8bd8e5-34a9-419e-bde0-8fd6e362462f',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * National Literacy Learning Progression (taxonomy)
       */
      learningprogression_tags__literacy: {
        codename: 'learningprogression_tags__literacy',
        id: '5334fc75-ea6c-4c8b-a236-3e9f5321d6ae',
        externalId: undefined,
        name: '🔖 National Literacy Learning Progression',
        required: false,
        type: 'taxonomy',
        snippetCodename: 'learningprogression_tags',
      },

      /**
       * National Numeracy Learning Progression (taxonomy)
       */
      learningprogression_tags__numeracy: {
        codename: 'learningprogression_tags__numeracy',
        id: '9183d2fe-8fab-4b2c-b51f-c86265f29d33',
        externalId: undefined,
        name: '🔖 National Numeracy Learning Progression',
        required: false,
        type: 'taxonomy',
        snippetCodename: 'learningprogression_tags',
      },

      /**
       * Code (text)
       */
      code: {
        codename: 'code',
        id: '1013fed2-b787-4d20-a266-aa82d4d0587a',
        externalId: undefined,
        name: 'Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📃 Assessment Stage Content
   * Last modified: Tue Jun 04 2024 06:35:57 GMT+0000 (Coordinated Universal Time)
   */
  syllabus_assessment_stagecontent: {
    codename: 'syllabus_assessment_stagecontent',
    id: 'bb2dbaae-5387-4041-877c-8cc925d89b83',
    externalId: undefined,
    name: '📃 Assessment Stage Content',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '6ad0c8be-fe69-4277-8b44-ef2a0d9e8f7c',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'a3ee3e50-13fa-4271-88bd-abe36f3e1a5a',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📃 Assessment groups (bands or grade) (modular_content)
       */
      gradingitems: {
        codename: 'gradingitems',
        id: '2a69ca46-3431-42e0-b1fc-9948d9217372',
        externalId: undefined,
        name: '📃 Assessment groups (bands or grade)',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📦 Resources files (asset)
       */
      resources: {
        codename: 'resources',
        id: 'ed36ec67-80f6-49d2-8cf8-de6e802769bd',
        externalId: undefined,
        name: '📦 Resources files',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * 📦 Resources other (modular_content)
       */
      resources_other: {
        codename: 'resources_other',
        id: '305b7e65-1a87-4117-8b36-cfe34e8a8450',
        externalId: undefined,
        name: '📦 Resources other',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area: {
        codename: 'key_learning_area',
        id: '7fd7e72a-8f23-4ea0-a5b0-c48a8946eb1b',
        externalId: undefined,
        name: '🏷️ Key learning area',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '47c846ca-e31c-4647-8d24-d761dc76b623',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📜 Content - Course
   * Last modified: Tue Jun 04 2024 06:09:51 GMT+0000 (Coordinated Universal Time)
   */
  content_course: {
    codename: 'content_course',
    id: '469c4dc3-3b80-4622-b752-fe0d6164d1f9',
    externalId: undefined,
    name: '📜 Content - Course',
    elements: {
      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '4a80a39d-0d1e-4bdf-8317-2d6df37370f0',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠Web UI Homepage Tile Callout
   * Last modified: Fri Jul 22 2022 06:17:56 GMT+0000 (Coordinated Universal Time)
   */
  ui_homepage_tile_callout: {
    codename: 'ui_homepage_tile_callout',
    id: '328cca16-590a-4b9a-9b35-59bb9b730947',
    externalId: undefined,
    name: '💠Web UI Homepage Tile Callout',
    elements: {
      /**
       * 📜 Items (modular_content)
       */
      tiles: {
        codename: 'tiles',
        id: '6ee0a4bc-e763-43af-bdc2-49711b84d11e',
        externalId: undefined,
        name: '📜 Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🎨 Background Color (custom)
       */
      background_color: {
        codename: 'background_color',
        id: 'e3e70d6c-9e47-4c3e-a7cd-83c3dfb268a9',
        externalId: undefined,
        name: '🎨 Background Color',
        required: true,
        type: 'custom',
        snippetCodename: undefined,
      },

      /**
       * 🎨 Divider Color (custom)
       */
      divider_color: {
        codename: 'divider_color',
        id: '622c31d1-995e-4433-b498-4d4bb391e87b',
        externalId: undefined,
        name: '🎨 Divider Color',
        required: true,
        type: 'custom',
        snippetCodename: undefined,
      },

      /**
       * 🎨 Font Color (custom)
       */
      font_color: {
        codename: 'font_color',
        id: 'a44f9d21-23c5-4e28-978d-45826da48bf1',
        externalId: undefined,
        name: '🎨 Font Color',
        required: true,
        type: 'custom',
        snippetCodename: undefined,
      },

      /**
       * 🎨 Arrow Color (custom)
       */
      arrow_color: {
        codename: 'arrow_color',
        id: '1c697de6-8262-453c-8479-ba5c0145080b',
        externalId: undefined,
        name: '🎨 Arrow Color',
        required: true,
        type: 'custom',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Tabs
   * Last modified: Thu Jun 30 2022 03:31:11 GMT+0000 (Coordinated Universal Time)
   */
  ui_tabs: {
    codename: 'ui_tabs',
    id: '25ca1d99-a7f8-41ec-9a33-feca8d25763c',
    externalId: undefined,
    name: '💠 Web UI Tabs',
    elements: {
      /**
       * 📜 Items (modular_content)
       */
      items: {
        codename: 'items',
        id: '54435a1f-9e6d-4c1c-a577-92feb018878f',
        externalId: undefined,
        name: '📜 Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔗 Web Link Internal
   * Last modified: Tue Jul 18 2023 03:13:55 GMT+0000 (Coordinated Universal Time)
   */
  weblinkint: {
    codename: 'weblinkint',
    id: '7e4b654e-8663-4dfb-a02a-d872a691f900',
    externalId: undefined,
    name: '🔗 Web Link Internal',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'cc3d42cd-6ffe-480c-bd2d-f6f941ab8f0f',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Sub Title (text)
       */
      subtitle: {
        codename: 'subtitle',
        id: 'd71b13a1-5db4-431c-bae5-9a853befa624',
        externalId: undefined,
        name: '📜 Sub Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: 'c1504125-e679-411c-83e9-16231ff33404',
        externalId: undefined,
        name: '🔗 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * ⚓ Anchorlink (text)
       *
       * Anchorlink name
       */
      anchorlink: {
        codename: 'anchorlink',
        id: '59509db6-08be-42cc-83b9-1c0778f00df4',
        externalId: undefined,
        name: '⚓ Anchorlink',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ❔Querystring (text)
       *
       * e.g. key=value or key1=value1&key2=value2
       */
      querystring: {
        codename: 'querystring',
        id: '076015b2-0eec-41cb-96b7-4616678debdf',
        externalId: undefined,
        name: '❔Querystring',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🚩 Change note - ACE KLA/Stage
   * Last modified: Tue Jun 04 2024 06:26:06 GMT+0000 (Coordinated Universal Time)
   */
  releasenote_ace_kla: {
    codename: 'releasenote_ace_kla',
    id: 'a26ccd63-0a87-4b21-b176-3cb69680ade4',
    externalId: undefined,
    name: '🚩 Change note - ACE KLA/Stage',
    elements: {
      /**
       * 📅 Release date (date_time)
       */
      releasedate: {
        codename: 'releasedate',
        id: '7ee4967f-65e1-4278-a02b-18a260c762c9',
        externalId: undefined,
        name: '📅 Release date',
        required: true,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'bca8af4d-2526-4591-88c5-02621564b502',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🗃️ Subgroup (modular_content)
       */
      subgroup: {
        codename: 'subgroup',
        id: '49285804-6092-4e14-aa4a-853b4c458bc1',
        externalId: undefined,
        name: '🗃️ Subgroup',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'f87880d1-7e9d-41a2-a431-9ba5a09f347a',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       *
       * Select Stages affected by this ACE Rule(s) update.
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'ae67ca7a-d753-47b2-9af9-c15442ebc3c4',
        externalId: undefined,
        name: '🏷️ Stage',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * Select Stage Years affected by this ACE Rule(s) update.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '5fd53e10-d3c0-48bc-bd87-6af5cfc7022d',
        externalId: undefined,
        name: '🏷️ Stage Year',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       *
       * Select Key Learning Areas affected by this ACE Rule(s) update. Leave blank for generic Stage/Year type updates.
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: '1f8c5ce2-c22a-48a3-a961-4378babc375e',
        externalId: undefined,
        name: '🏷️ Key learning area',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '0f4665f6-17d9-40ab-88cb-2cdae1f586b7',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📙 Syllabus Course - Content section
   * Last modified: Fri Sep 06 2024 06:52:28 GMT+0000 (Coordinated Universal Time)
   */
  course_contentsection: {
    codename: 'course_contentsection',
    id: '5c37e01a-a75c-4f1f-ad0e-0c91f9184dcc',
    externalId: undefined,
    name: '📙 Syllabus Course - Content section',
    elements: {
      /**
       * 🏷️ Section (multiple_choice)
       */
      section: {
        codename: 'section',
        id: '9131f377-52be-454d-b916-491ba731fd70',
        externalId: undefined,
        name: '🏷️ Section',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '4b9c3209-b30f-4854-abff-5361200124ca',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📦 Links (modular_content)
       */
      links: {
        codename: 'links',
        id: 'b5c03a66-d31f-4f87-af0c-a930d847ca9c',
        externalId: undefined,
        name: '📦 Links',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📙 Syllabus - Overview page
   * Last modified: Tue Jun 04 2024 06:32:11 GMT+0000 (Coordinated Universal Time)
   */
  syllabus_overviewpage: {
    codename: 'syllabus_overviewpage',
    id: 'a49e3c15-31d4-4a6d-8fa1-dbf2aa48a71c',
    externalId: undefined,
    name: '📙 Syllabus - Overview page',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '4e8e694e-e31a-4c1d-ab8c-8cb7f90cf671',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '5ca29206-e8bc-4747-add4-e6955b90967c',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: 'cb682239-49b5-4bb3-90bf-ca154a67d647',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📒 Syllabus - Previous
   * Last modified: Wed Apr 17 2024 04:08:50 GMT+0000 (Coordinated Universal Time)
   */
  syllabus_previous: {
    codename: 'syllabus_previous',
    id: '22e867a8-c0a7-44cf-bb0d-116e1d44c590',
    externalId: undefined,
    name: '📒 Syllabus - Previous',
    elements: {
      /**
       * 📜 Title (text)
       *
       * Note that "Title" will also be used to generate the SEO Title on the website
       */
      title: {
        codename: 'title',
        id: '8fdbbe7c-29fe-44fc-94d6-638c60b197c1',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Status (taxonomy)
       */
      status: {
        codename: 'status',
        id: '65951a73-0326-4757-b97d-7fa96cac7f18',
        externalId: undefined,
        name: '🏷️ Status',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect url (text)
       */
      redirecturl: {
        codename: 'redirecturl',
        id: '98f30a82-c3ff-4b81-a0b9-25d490238470',
        externalId: undefined,
        name: '🔗 Redirect url',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🖇️ Links placeholder - Curriculum Connections
   * Last modified: Mon Jan 15 2024 23:40:55 GMT+0000 (Coordinated Universal Time)
   */
  links_placeholder_cc: {
    codename: 'links_placeholder_cc',
    id: '98ddd4be-3f6d-486a-aa56-4e4b63d8a739',
    externalId: undefined,
    name: '🖇️ Links placeholder - Curriculum Connections',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '56923add-cb91-4477-abcd-e3b42981e14f',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🖇️ Curriculum Connections links (modular_content)
       */
      links: {
        codename: 'links',
        id: 'b4d2e301-abd4-406c-b132-fea36b5acac6',
        externalId: undefined,
        name: '🖇️ Curriculum Connections links',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📜 Content Rich Text
   * Last modified: Tue Jun 04 2024 06:08:55 GMT+0000 (Coordinated Universal Time)
   */
  contentrichtext: {
    codename: 'contentrichtext',
    id: 'f1f6162f-48ea-4860-8a53-9fc030ff8b51',
    externalId: undefined,
    name: '📜 Content Rich Text',
    elements: {
      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '0126fa89-080d-46e3-9c14-c19e2bea0435',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      stages: {
        codename: 'stages',
        id: '9ed13d63-fe86-4ccc-99a7-868388da5c15',
        externalId: undefined,
        name: '🏷️ Stages',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       */
      stage_years: {
        codename: 'stage_years',
        id: '0d4e4afb-a4d8-4f12-bbfa-f6de5bb9855f',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '04095799-08cb-4c43-9384-00befd94511f',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📚 Syllabus Course
   * Last modified: Mon Aug 05 2024 04:48:07 GMT+0000 (Coordinated Universal Time)
   */
  course: {
    codename: 'course',
    id: 'cf06da5a-2f86-4fef-a1ca-7d9f44562e77',
    externalId: undefined,
    name: '📚 Syllabus Course',
    elements: {
      /**
       * 📜 Course ID (text)
       *
       * Course number (e.g. 300, 302) from exam system.
       */
      courseid: {
        codename: 'courseid',
        id: 'e66b22be-5bd2-4c0d-b977-4814f8040387',
        externalId: undefined,
        name: '📜 Course ID',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Course name (text)
       *
       * e.g. English 7-10
       */
      name: {
        codename: 'name',
        id: '514a083b-91c0-4f3e-9050-f1a91055e15c',
        externalId: undefined,
        name: '📜 Course name',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (modular_content)
       */
      content: {
        codename: 'content',
        id: '86ff8789-cd6d-4bf7-8153-6ea947547a31',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Course Hours (taxonomy)
       */
      coursehours: {
        codename: 'coursehours',
        id: 'a656230f-40e7-479b-bafb-31b6c565d272',
        externalId: undefined,
        name: '🏷️ Course Hours',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Course Units (taxonomy)
       */
      courseunits: {
        codename: 'courseunits',
        id: 'db072623-5444-4d9e-a792-1c25aa917e9c',
        externalId: undefined,
        name: '🏷️ Course Units',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Course Type (taxonomy)
       */
      coursetype: {
        codename: 'coursetype',
        id: '622572b8-fbfb-4553-8d3e-e9c0544b154c',
        externalId: undefined,
        name: '🏷️ Course Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Enrolment Type (taxonomy)
       */
      enrolmenttype: {
        codename: 'enrolmenttype',
        id: '9cd3f6da-3683-42f9-bc17-ebaf66dbd7b8',
        externalId: undefined,
        name: '🏷️ Enrolment Type',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Endorsement type (taxonomy)
       */
      endorsementtype: {
        codename: 'endorsementtype',
        id: 'a93c169a-d338-4afa-8bf4-25a96d75fbf3',
        externalId: undefined,
        name: '🏷️ Endorsement type',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Study via self-tuition (multiple_choice)
       */
      selftuition: {
        codename: 'selftuition',
        id: '98828ff8-05b6-4f95-bc84-1e64e115186f',
        externalId: undefined,
        name: '⚙️ Study via self-tuition',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ HSC Examinable (multiple_choice)
       */
      hscexaminable: {
        codename: 'hscexaminable',
        id: '243d5146-1f8c-4af2-8cf1-decfd3930905',
        externalId: undefined,
        name: '⚙️ HSC Examinable',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Display on course description page (multiple_choice)
       *
       * ⚠️ Should this course be displayed on the advanced course overview page?Yes = This course will be displayed as a section on Syllabus / Overview / Course description tab.No = This course is for content organisation only and will not be displayed on advanced course overview page.
       */
      display_overview: {
        codename: 'display_overview',
        id: '7c1e73e1-aa4f-4c32-b048-7d8bab0d9d41',
        externalId: undefined,
        name: '⚙️ Display on course description page',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '37f72a96-cf04-4815-a324-18fccd45c513',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Content display (multiple_choice)
       *
       * ⚠️ How the teachable content should be displayed on the content tab and in the print document.Yes = This course has content mapped which should be displayed within stage/year content accordionNo = This course does not have any content mapped, it is only displayed on Course Overview page and will be ignored on stage/year content accordions e.g. Music 7-10 or Languages 7-10
       */
      display_mode: {
        codename: 'display_mode',
        id: '2f228cf6-d0e9-4ed7-b4bc-ff7bc6cf144b',
        externalId: undefined,
        name: '⚙️ Content display',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * Content Accordion (taxonomy)
       *
       * ⚠️ Required if Content display is set to Yes.
       */
      content_accordion: {
        codename: 'content_accordion',
        id: '1a23cb02-f1b4-47c5-b88e-2c59e7f08547',
        externalId: undefined,
        name: '🏷️ Content accordion',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content accordion title (text)
       *
       * ⚠️ Required for extension courses, where the title will be appended to the content accordion on the web and the content section in print, e.g., Year 12 - Extension 1.Leave blank for standard (e.g. not extension) courses.
       */
      content_accordion_title: {
        codename: 'content_accordion_title',
        id: '2f687ef9-ccd6-4a80-be9b-f929eb0ff076',
        externalId: undefined,
        name: '📜 Content accordion title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      stages: {
        codename: 'stages',
        id: '757831d9-53e6-4f7f-8e30-2109d414bba8',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       */
      stage_years: {
        codename: 'stage_years',
        id: '95923a48-9d34-4947-b0be-c293069d4b70',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       *
       * Primary Key Learning Area. This field is used to generate the link to this course e.g. /learning-areas/{kla}/{syllabus}/overview/course#{slug}
       */
      key_learning_area_taxo: {
        codename: 'key_learning_area_taxo',
        id: '49b06597-906b-4f90-85e9-c236ab7304cb',
        externalId: undefined,
        name: '🏷️ Key learning area',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus_taxo: {
        codename: 'syllabus_taxo',
        id: 'b69696bc-993e-4857-8816-46e6a1e8495c',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Resources
   * Last modified: Thu May 09 2024 00:35:02 GMT+0000 (Coordinated Universal Time)
   */
  ui_resources: {
    codename: 'ui_resources',
    id: '6301f641-62ed-4b17-9407-56b31b7b60be',
    externalId: undefined,
    name: '💠 Web UI Resources',
    elements: {
      /**
       * 📦 Resources (asset)
       */
      resources: {
        codename: 'resources',
        id: '4b782b3d-f223-4952-bf47-686c5fc20423',
        externalId: undefined,
        name: '📦 Resources',
        required: true,
        type: 'asset',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Collection
   * Last modified: Wed Sep 28 2022 12:33:58 GMT+0000 (Coordinated Universal Time)
   */
  ui_collection: {
    codename: 'ui_collection',
    id: '16b8d520-01af-4168-8f4b-a9350df90b38',
    externalId: undefined,
    name: '💠 Web UI Collection',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '029c354b-7fac-4840-b0c1-bc864362908e',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📂 Items (modular_content)
       */
      items: {
        codename: 'items',
        id: 'b3a23e69-887e-485f-863d-6d013ab337fd',
        externalId: undefined,
        name: '📂 Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🎨 Tile colour (multiple_choice)
       */
      tile_colour: {
        codename: 'tile_colour',
        id: '9c9a7d31-c0f8-4eef-8adf-c6db0cc6e8a0',
        externalId: undefined,
        name: '🎨 Tile colour',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📃 Assessment
   * Last modified: Tue Jun 04 2024 06:38:34 GMT+0000 (Coordinated Universal Time)
   */
  assessment: {
    codename: 'assessment',
    id: '82ff2d1f-e448-4b9c-9ce3-6bcef211c5d2',
    externalId: undefined,
    name: '📃 Assessment',
    elements: {
      /**
       * 📜 Introduction (rich_text)
       */
      introduction: {
        codename: 'introduction',
        id: 'ac7a1534-772c-4f20-9ba1-132dd29047d5',
        externalId: undefined,
        name: '📜 Introduction',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Common grade scale (rich_text)
       */
      commongradescale: {
        codename: 'commongradescale',
        id: 'b380f520-0f77-4e3a-8772-802eeccd4407',
        externalId: undefined,
        name: '📜 Common grade scale',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Course performance descriptions (CPDs) (rich_text)
       */
      courseperformancedescriptions: {
        codename: 'courseperformancedescriptions',
        id: 'd3bb5a8c-f58d-4a19-9357-39afd322ff5b',
        externalId: undefined,
        name: '📜 Course performance descriptions (CPDs)',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Life skills (rich_text)
       */
      lifeskills: {
        codename: 'lifeskills',
        id: '13a70006-f92d-4228-82fd-73722c3ccdf5',
        externalId: undefined,
        name: '📜 Life skills',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Other information (rich_text)
       */
      otherinformation: {
        codename: 'otherinformation',
        id: 'b8bca412-2c4a-4866-bdc3-2a0033ce7776',
        externalId: undefined,
        name: '📜 Other information',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Related ACE rules (rich_text)
       *
       * ACE rules (Phase 3).
       */
      relatedacerules: {
        codename: 'relatedacerules',
        id: '4d8f1e07-5979-4cd9-81b9-c4a689403ee2',
        externalId: undefined,
        name: '📜 Related ACE rules',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Supporting text (modular_content)
       */
      pbd_supportingtext: {
        codename: 'pbd_supportingtext',
        id: 'c7ab7634-cd5f-47ed-993c-cc40b0641ce9',
        externalId: undefined,
        name: '📜 Supporting text',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Performance band descriptors (PBD) 6 (rich_text)
       */
      pbd_6: {
        codename: 'pbd_6',
        id: 'd5afdebd-9c74-4f4c-8d0c-e32fa36b3c2b',
        externalId: undefined,
        name: '📜 Performance band descriptors (PBD) 6',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Performance band descriptors (PBD) 5 (rich_text)
       */
      pbd_5: {
        codename: 'pbd_5',
        id: '7ceb124a-986a-47ee-8b0a-3576c9f74858',
        externalId: undefined,
        name: '📜 Performance band descriptors (PBD) 5',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Performance band descriptors (PBD) 4 (rich_text)
       */
      pbd_4: {
        codename: 'pbd_4',
        id: 'ddf73ff4-ff4e-400e-9845-f450a5e380b5',
        externalId: undefined,
        name: '📜 Performance band descriptors (PBD) 4',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Performance band descriptors (PBD) 3 (rich_text)
       */
      pbd_3: {
        codename: 'pbd_3',
        id: '0df7afdc-4169-444e-a781-e984e962cf4f',
        externalId: undefined,
        name: '📜 Performance band descriptors (PBD) 3',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Performance band descriptors (PBD) 2 (rich_text)
       */
      pbd_2: {
        codename: 'pbd_2',
        id: 'db720062-8ee4-405c-a3da-4a60158eac08',
        externalId: undefined,
        name: '📜 Performance band descriptors (PBD) 2',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Performance band descriptors (PBD) 1 (modular_content)
       */
      performance_band_descriptors__pbd__1: {
        codename: 'performance_band_descriptors__pbd__1',
        id: 'ff1c147f-a4b7-40cc-8e6c-a9d530883ad8',
        externalId: undefined,
        name: '📜 Performance band descriptors (PBD) 1',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Supporting text (modular_content)
       */
      examspec_supportingtext: {
        codename: 'examspec_supportingtext',
        id: 'c27dfa29-4350-4cb2-98e3-dcd7dc38909e',
        externalId: undefined,
        name: '📜 Supporting text',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 HSC specification (rich_text)
       */
      examspec_hscspecification: {
        codename: 'examspec_hscspecification',
        id: '7d90d7be-c549-4af8-8c46-28d528a109b9',
        externalId: undefined,
        name: '📜 HSC specification',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Sample material (rich_text)
       */
      examspec_samplematerial: {
        codename: 'examspec_samplematerial',
        id: 'b15a95c1-9c68-41ea-a457-a77d860f571f',
        externalId: undefined,
        name: '📜 Sample material',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Supporting text (modular_content)
       */
      majorwork_supportingtext: {
        codename: 'majorwork_supportingtext',
        id: '29799ad6-d1fe-4444-9b40-e3fbad610896',
        externalId: undefined,
        name: '📜 Supporting text',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Advice (rich_text)
       */
      majorwork_advice: {
        codename: 'majorwork_advice',
        id: '3c486b18-c214-492a-a730-b5ffa8f13489',
        externalId: undefined,
        name: '📜 Advice',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Supporting text (modular_content)
       */
      sba_supportingtext: {
        codename: 'sba_supportingtext',
        id: '4be0ca4b-cbb3-4689-b052-19b0056aa3cd',
        externalId: undefined,
        name: '📜 Supporting text',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 SBA - 11 (rich_text)
       */
      sba_11: {
        codename: 'sba_11',
        id: 'aa762c75-12d6-47d7-b02c-3fb11a783de3',
        externalId: undefined,
        name: '📜 SBA - 11',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 SBA - 12 (rich_text)
       */
      sba_12: {
        codename: 'sba_12',
        id: '0e40d2e1-204f-4936-8a86-3757d7f542b0',
        externalId: undefined,
        name: '📜 SBA - 12',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Supporting text: Assessment requirements (modular_content)
       */
      assessmentrequirements_supportingtext: {
        codename: 'assessmentrequirements_supportingtext',
        id: '4136ce49-02e6-48ae-a1d7-cdb9b6cfb2c9',
        externalId: undefined,
        name: '📜 Supporting text: Assessment requirements',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Assessment requirements (rich_text)
       */
      assessmentrequirements: {
        codename: 'assessmentrequirements',
        id: '440f86a1-238f-4a17-8221-b1a813dccc2f',
        externalId: undefined,
        name: '📜 Assessment requirements',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Supporting text (modular_content)
       */
      hsc_supportingtext: {
        codename: 'hsc_supportingtext',
        id: '9d4e3803-4a65-44aa-98f0-dfd779ed4f33',
        externalId: undefined,
        name: '📜 Supporting text',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 HSC examinations (rich_text)
       */
      hsc_examinations: {
        codename: 'hsc_examinations',
        id: '030152dc-118f-4932-9437-2c218a613ab6',
        externalId: undefined,
        name: '📜 HSC examinations',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '0d8f7634-58ff-4643-8413-050f2814c6c4',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Global Alert
   * Last modified: Wed Mar 01 2023 01:45:37 GMT+0000 (Coordinated Universal Time)
   */
  ui_globalalert: {
    codename: 'ui_globalalert',
    id: 'e201c53c-a9cf-4d8d-b24e-1ff2b536bfb9',
    externalId: undefined,
    name: '💠 Web UI Global Alert',
    elements: {
      /**
       * 📜 Intro (text)
       */
      intro: {
        codename: 'intro',
        id: '58492a24-0bfe-4a02-a591-0c2cb1da90de',
        externalId: undefined,
        name: '📜 Intro',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '870d7c1b-4e9e-431a-8a16-9e37ee9b52d9',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Button Primary - Text (text)
       */
      btn_primary_text: {
        codename: 'btn_primary_text',
        id: '40ba04e4-d793-4b50-a02b-b7edfb9d951c',
        externalId: undefined,
        name: '📜 Button Primary - Text',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Button Primary - Url (text)
       */
      btn_primary_url: {
        codename: 'btn_primary_url',
        id: 'e932433d-ea83-4d46-83f5-38964562e5ce',
        externalId: undefined,
        name: '🔗 Button Primary - Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Button Secondary - Text (text)
       */
      btn_secondary_text: {
        codename: 'btn_secondary_text',
        id: '9a544a66-fe30-4bef-9f97-17d8e2e24950',
        externalId: undefined,
        name: '📜 Button Secondary - Text',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Button Secondary - Url (text)
       */
      btn_secondary_url: {
        codename: 'btn_secondary_url',
        id: '47b85a4b-80cc-4556-af5a-e3425f2601a9',
        externalId: undefined,
        name: '🔗 Button Secondary - Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Type (multiple_choice)
       */
      type: {
        codename: 'type',
        id: 'cc04620d-027f-43d6-9fee-d79cb03c6baf',
        externalId: undefined,
        name: '⚙️ Type',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Menu
   * Last modified: Tue Jul 18 2023 03:12:57 GMT+0000 (Coordinated Universal Time)
   */
  ui_menu: {
    codename: 'ui_menu',
    id: '24284152-dd5f-45d4-b4ab-7ef282d2211d',
    externalId: undefined,
    name: '💠 Web UI Menu',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'e7f9c20d-a369-4656-bc62-c7ac127825ad',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title Long (text)
       *
       * Used on level 1 main menu popup only
       */
      titlelong: {
        codename: 'titlelong',
        id: 'd8591f24-40bc-4c35-bf02-4c58ecbee5e1',
        externalId: undefined,
        name: '📜 Title Long',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Sub Title (text)
       */
      subtitle: {
        codename: 'subtitle',
        id: '27264d4e-25bb-4474-87d5-104e22d1dff6',
        externalId: undefined,
        name: '📜 Sub Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: '7b773b96-8200-4351-9ccf-07f91b8122c7',
        externalId: undefined,
        name: '🔗 Item',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📂 Subitems (modular_content)
       */
      subitems: {
        codename: 'subitems',
        id: '2fc1dec4-9c31-41a3-8e2b-0d0100f08f46',
        externalId: undefined,
        name: '📂 Subitems',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠Web UI Video Tiles
   * Last modified: Tue Sep 27 2022 07:58:01 GMT+0000 (Coordinated Universal Time)
   */
  ui_video_tiles: {
    codename: 'ui_video_tiles',
    id: '7126e06b-d8f9-4e1b-be76-60d21110dad1',
    externalId: undefined,
    name: '💠Web UI Video Tiles',
    elements: {
      /**
       * 📜 Items (modular_content)
       */
      items: {
        codename: 'items',
        id: '43ae5e34-7cb8-4e9e-89a1-fb511fd45a06',
        externalId: undefined,
        name: '📜 Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📜 Content - Accordion Item
   * Last modified: Tue Jun 04 2024 06:09:23 GMT+0000 (Coordinated Universal Time)
   */
  accordion_item: {
    codename: 'accordion_item',
    id: 'e2243a35-1d03-43fb-9a31-7a85f2cc0974',
    externalId: undefined,
    name: '📜 Content - Accordion Item',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '818dd490-a09d-4afb-94f8-aeab680b7896',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '28c7e51a-c70a-4b0a-8b7f-8dbb467721f8',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🗒️ ACE Rule Content Item
   * Last modified: Mon Jul 24 2023 02:57:51 GMT+0000 (Coordinated Universal Time)
   */
  ace_ruleitem: {
    codename: 'ace_ruleitem',
    id: '1188ca78-28e0-4d22-94a6-8e387a4bbdf8',
    externalId: undefined,
    name: '🗒️ ACE Rule Content Item',
    elements: {
      /**
       * 📜 Title (text)
       *
       * This will become a section heading within the rule content area
       */
      title: {
        codename: 'title',
        id: '2cb9bc17-8f01-4891-a199-3463b283300a',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'f21d63f6-d8f4-4cba-95de-88bb934f658a',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'ad6bb7ea-3c71-4bb8-8f41-02f8ca4aa373',
        externalId: undefined,
        name: '🏷️ Stages',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '926cea31-bcdd-4231-912d-bb3edd6aea52',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: '04682430-3a43-46d2-8de9-d0913122758b',
        externalId: undefined,
        name: '🏷️ Key learning area',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page Stage
   * Last modified: Wed Jul 06 2022 06:34:05 GMT+0000 (Coordinated Universal Time)
   */
  wp_stage: {
    codename: 'wp_stage',
    id: '7f13920c-c08d-4402-af94-3db871391804',
    externalId: undefined,
    name: '🌐 Web Page Stage',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'f9d5c813-6a6a-4448-9363-8ea67c094adf',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Syllabuses (modular_content)
       */
      syllabuses: {
        codename: 'syllabuses',
        id: 'c61586bf-4145-4fae-8df7-c5c32640a234',
        externalId: undefined,
        name: '🌐 Syllabuses',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '7928dc99-4927-446e-8e26-a31a272609f9',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },
    },
  },

  /**
   * 🗄️ ACE Group
   * Last modified: Sat Jul 08 2023 06:41:07 GMT+0000 (Coordinated Universal Time)
   */
  ace_group: {
    codename: 'ace_group',
    id: 'd16d83dc-62bc-4372-bb38-3bc0f1565377',
    externalId: undefined,
    name: '🗄️ ACE Group',
    elements: {
      /**
       * 📜 Code (text)
       */
      code: {
        codename: 'code',
        id: 'a1451d07-9d4b-4c07-89c4-ec608c36886c',
        externalId: undefined,
        name: '📜 Code',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '868559f0-2306-48f7-a239-db7a1250f9b4',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🗃️ Subgroups (modular_content)
       */
      subgroups: {
        codename: 'subgroups',
        id: '14002b63-645b-4690-8557-a995648a067c',
        externalId: undefined,
        name: '🗃️ Subgroups',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },
    },
  },

  /**
   * 🔗 Links placeholder - Overarching
   * Last modified: Mon Jan 15 2024 23:41:36 GMT+0000 (Coordinated Universal Time)
   */
  links_placeholder_overarching: {
    codename: 'links_placeholder_overarching',
    id: '4422e624-a1a3-44aa-a7a5-dbe74914dcf4',
    externalId: undefined,
    name: '🔗 Links placeholder - Overarching',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '0251399d-f211-4021-b9b7-a0514d13d393',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Overarching links (modular_content)
       */
      links: {
        codename: 'links',
        id: '3f7d2d1e-0bc7-418b-b30c-bf4a06fce611',
        externalId: undefined,
        name: '🔗 Overarching links',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📃 Assessment Content Item
   * Last modified: Tue Jun 04 2024 06:36:12 GMT+0000 (Coordinated Universal Time)
   */
  syllabus_assessment_item: {
    codename: 'syllabus_assessment_item',
    id: '7e954adb-a0fc-4b44-b5c4-0f32486074cf',
    externalId: undefined,
    name: '📃 Assessment Content Item',
    elements: {
      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'de93ed47-a576-4ff4-8a7b-1f9fe656da0b',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Code (text)
       */
      code: {
        codename: 'code',
        id: '042769b4-8325-4d75-8142-b9e5a4ae75d6',
        externalId: undefined,
        name: 'Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area: {
        codename: 'key_learning_area',
        id: '8bb08e75-aabf-4c64-9f4e-69a307ccb3bb',
        externalId: undefined,
        name: '🏷️ Key learning area',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '4cabe0f4-9832-47f3-b06e-58114eb3af20',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page
   * Last modified: Sat Jul 08 2023 23:24:49 GMT+0000 (Coordinated Universal Time)
   */
  web_page: {
    codename: 'web_page',
    id: '398abc4f-2591-42f9-a73a-b52a49fc49e9',
    externalId: undefined,
    name: '🌐 Web Page',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'fe81d11c-37c4-4561-930b-aa46b1294f00',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: 'f71c08ab-3d34-48a5-b0e4-c7064ac75094',
        externalId: undefined,
        name: '🌐 Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 🌐 Left navigation links (modular_content)
       */
      left_navigation: {
        codename: 'left_navigation',
        id: '9e2a6512-61ca-4945-b8e1-5eb3ae9c6bad',
        externalId: undefined,
        name: '🌐 Left navigation links',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: 'ed7fba66-01ed-4664-859b-c33843bca5d0',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: '97a9c241-dacd-432b-9387-c26208e15fba',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       *
       * Relative or abosute url
       */
      redirect_url: {
        codename: 'redirect_url',
        id: 'c74868f7-5a67-4430-9aee-8f7e54734769',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📒 Syllabus
   * Last modified: Sun Sep 15 2024 21:51:15 GMT+0000 (Coordinated Universal Time)
   */
  syllabus: {
    codename: 'syllabus',
    id: '430d1ba2-bebe-4cf9-836b-3266ad001d0c',
    externalId: undefined,
    name: '📒 Syllabus',
    elements: {
      /**
       * 📜 Title (text)
       *
       * Note that "Title" will also be used to generate the SEO Title on the website
       */
      title: {
        codename: 'title',
        id: 'cf4c6095-997b-4d90-958a-44cf69f4789d',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description (rich_text)
       *
       * Note that "Description" will also be used to generate the SEO Description on the website. Please ensure the description is less than 160 char's long.
       */
      description: {
        codename: 'description',
        id: '5fdca5de-3285-4ed5-9f97-e37888afef9d',
        externalId: undefined,
        name: '📜 Description',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Implementation title (text)
       */
      implementation_title: {
        codename: 'implementation_title',
        id: '3f623d6f-c068-4cfb-9d3b-c0b0f726a724',
        externalId: undefined,
        name: '📜 Implementation title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Implementation summary (text)
       */
      implementation_summary: {
        codename: 'implementation_summary',
        id: '4cf25969-250c-4927-8b76-5b3d031eff2c',
        externalId: undefined,
        name: '📜 Implementation summary',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Implementation info (rich_text)
       */
      implementation_info: {
        codename: 'implementation_info',
        id: '917c91a8-f424-4ffa-884f-7a8c89e01f59',
        externalId: undefined,
        name: '📜 Implementation info',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Aim (rich_text)
       */
      aim: {
        codename: 'aim',
        id: '3427f867-db4b-41f3-8dbb-31a7d7b90a88',
        externalId: undefined,
        name: '📜 Aim',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Rationale (rich_text)
       *
       * The common understanding of the value and importance of the subject for student learning.
       */
      rationale: {
        codename: 'rationale',
        id: '3e7dd85a-a1a4-402f-a318-b8f29fe32d8e',
        externalId: undefined,
        name: '📜 Rationale',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: 'd4be49ed-5b70-4bf1-8396-c4c4ba8a1877',
        externalId: undefined,
        name: 'Key Learning Area',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'key_learning_area',
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area_default: {
        codename: 'key_learning_area_default',
        id: 'a514a6b0-d3da-4add-9db0-861c750770c9',
        externalId: undefined,
        name: '🏷️ Canonical Key Learning Area',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * 📒 Related life skills syllabus (modular_content)
       */
      relatedlifeskillssyllabus: {
        codename: 'relatedlifeskillssyllabus',
        id: '4be39efb-1553-4c7a-8634-525845927ea3',
        externalId: undefined,
        name: '📒 Related life skills syllabus',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * ⚙️ Self tuition (multiple_choice)
       *
       * Stage 6 only. Whether course is available for study via self-tutition as per ACE rules.
       */
      selftuition: {
        codename: 'selftuition',
        id: '5a2d379b-03ac-4203-8805-970f00053742',
        externalId: undefined,
        name: '⚙️ Self tuition',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 📜 Syllabus Overview (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '48e3a097-31be-4600-b4e9-760b48d9890a',
        externalId: undefined,
        name: '📜 Syllabus Overview',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Course Description (rich_text)
       */
      course_content: {
        codename: 'course_content',
        id: '95902b51-2f2d-47a4-959b-2064921163be',
        externalId: undefined,
        name: '📜 Course Description',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📚 Courses (modular_content)
       */
      courses: {
        codename: 'courses',
        id: 'd3782c9b-9574-4e49-b7a5-c56c79637d04',
        externalId: undefined,
        name: '📚 Courses',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Course Description Footer (rich_text)
       *
       * ⚠️ This section appears below the further information for courses and can be used for related industries, skills and complementary courses (VET). The first line in this content section must be in H4 heading.
       */
      course_content_footer: {
        codename: 'course_content_footer',
        id: '5f5ef20a-f63b-487e-8aaa-8f46832cc56c',
        externalId: undefined,
        name: '📜 Course Description Footer',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📙 Additional Overview Content (modular_content)
       */
      additional_content: {
        codename: 'additional_content',
        id: 'ecb29dce-71b9-4e91-9485-8c6ca68b97b7',
        externalId: undefined,
        name: '📙 Additional Overview Content',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Life Skills info for syllabus (rich_text)
       *
       * This is the content displayed on the first LS section on the printout
       */
      lifeskills_info_syllabus: {
        codename: 'lifeskills_info_syllabus',
        id: 'be920b32-631d-4527-9d25-d43acf3c4977',
        externalId: undefined,
        name: '📜 Life Skills info for syllabus',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Life Skills info for focus areas (rich_text)
       *
       * This is the content displayed for all LS focus areas on the web view
       */
      lifeskills_info_focusareas: {
        codename: 'lifeskills_info_focusareas',
        id: '4803b75f-2893-44de-8c26-2c1bfa34b1fc',
        externalId: undefined,
        name: '📜 Life Skills info for focus areas',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📈 Focus area continum groups (modular_content)
       *
       * Create focus area contimum to group outcomes from related focus areas e.g. Forming groups, Forming groups A and Forming groups B
       */
      focus_area_continum_groups: {
        codename: 'focus_area_continum_groups',
        id: 'd2046dcc-de25-4f69-a6d9-8c33877d7a5b',
        externalId: undefined,
        name: '📈 Focus area continum groups',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Focus areas (modular_content)
       */
      focus_areas: {
        codename: 'focus_areas',
        id: 'eeb3d84a-eab4-4df0-b199-68700855cde4',
        externalId: undefined,
        name: '📃 Focus areas',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Outcomes notifications (modular_content)
       */
      outcomesnotificationslist: {
        codename: 'outcomesnotificationslist',
        id: 'e7a7a08f-5421-4c30-b872-8475f35ef483',
        externalId: undefined,
        name: '📜 Outcomes notifications',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Outcomes (modular_content)
       */
      outcomes: {
        codename: 'outcomes',
        id: '8da96cc9-4ab8-4a21-a41f-b6fa9e5324b8',
        externalId: undefined,
        name: '📃 Outcomes',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Assessments info (K-10 only) (rich_text)
       *
       * This is the Assessment call-out content
       */
      assessments_info: {
        codename: 'assessments_info',
        id: '3b52ecf2-9fcf-47fc-9a28-f838d1746596',
        externalId: undefined,
        name: '📜 Assessments info (K-10 only)',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📃 Assessments (K-10 only) (modular_content)
       */
      assessments: {
        codename: 'assessments',
        id: '43ac34b4-3e76-4b17-813d-b43eb4bea15d',
        externalId: undefined,
        name: '📃 Assessments (K-10 only)',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Course standards assessment info (Stage 6 only) (rich_text)
       */
      cs_assessments_info: {
        codename: 'cs_assessments_info',
        id: '3e3617b2-32ce-49e1-a3dc-2e192a4c65b7',
        externalId: undefined,
        name: '📜 Course standards assessment info (Stage 6 only)',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📃 Course standards assessments (Stage 6 only) (modular_content)
       */
      cs_assessments: {
        codename: 'cs_assessments',
        id: 'b4947f09-9e27-4ae3-a788-d6217e2613bc',
        externalId: undefined,
        name: '📃 Course standards assessments (Stage 6 only)',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 School-based assessment info (Stage 6 only) (rich_text)
       */
      sb_assessments_info: {
        codename: 'sb_assessments_info',
        id: '564b4730-f482-4ce1-91f9-b146a2257af6',
        externalId: undefined,
        name: '📜 School-based assessment info (Stage 6 only)',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📃 School-based assessments (Stage 6 only) (modular_content)
       */
      sb_assessments: {
        codename: 'sb_assessments',
        id: 'ea40de0f-3da3-49dc-89fa-6746c3883d23',
        externalId: undefined,
        name: '📃 School-based assessments (Stage 6 only)',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 HSC assessment info (Stage 6 only) (rich_text)
       */
      hsc_assessments_info: {
        codename: 'hsc_assessments_info',
        id: '8c8277a8-9263-4093-8117-d76b9f5aa16c',
        externalId: undefined,
        name: '📜 HSC assessment info (Stage 6 only)',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📃 HSC assessments (Stage 6 only) (modular_content)
       */
      hsc_assessments: {
        codename: 'hsc_assessments',
        id: 'c0e5366b-3bc4-4d27-8043-6f5816768519',
        externalId: undefined,
        name: '📃 HSC assessments (Stage 6 only)',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * 📜 Code (text)
       *
       * ⚠️ Not used. This is a code which is used to group together outgoing and incomming syllabuses.
       */
      code: {
        codename: 'code',
        id: '96603deb-bdac-4936-97b7-d8ddefe3d95d',
        externalId: undefined,
        name: '📜 Code',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '034c3824-27ae-4ad5-b556-30534e3f64f1',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Pathways (taxonomy)
       */
      pathways: {
        codename: 'pathways',
        id: 'ff2cabe4-167e-47d6-b6b5-5030ea3fc148',
        externalId: undefined,
        name: '🏷️ Pathways',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Language (taxonomy)
       *
       * ⚠️ For Modern and Classical Languages only.
       */
      languages: {
        codename: 'languages',
        id: '5fa90d1f-0be5-44b9-a64f-47274b70cf3c',
        externalId: undefined,
        name: '🏷️ Languages',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Has Examples (multiple_choice)
       *
       * Yes = Show examples checkbox will be displayedNo = Show examples checkbox will not be displayed Field empty = Show examples checkbox will be displayed
       */
      has_examples: {
        codename: 'has_examples',
        id: 'fa00180b-4ef6-4bb6-b339-c57c6c2d765c',
        externalId: undefined,
        name: '⚙️ Has Examples',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * Content Accordion (taxonomy)
       *
       * To enable the Show examples checkbox, add Stages/Years where examples are available.⚠️ Note that examples in senior life skills syllabuses are configured in the life skills itself, not in related mainstream syllabus.Ensure Year 11 and/or Year 12 options, as well as the Life Skills option, are selected for all Life Skills syllabuses. This ensures that the checkbox is displayed within the mainstream Year 11 and/or 12 accordions when switching to the Life Skills view.
       */
      has_examples_in: {
        codename: 'has_examples_in',
        id: 'f0799599-b46d-4a8d-9d3a-c68d2d5fd549',
        externalId: undefined,
        name: '🏷️ Enable Examples in Stage/Year',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * 📅 From date (date_time)
       */
      from_date: {
        codename: 'from_date',
        id: '3336c8a4-e306-499e-ac2e-f94d8036224a',
        externalId: undefined,
        name: '📅 From date',
        required: true,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📅 To date (date_time)
       */
      to_date: {
        codename: 'to_date',
        id: 'cac2e1d8-8a1a-4351-b07c-4919af864cfc',
        externalId: undefined,
        name: '📅 To date',
        required: false,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📜 Keywords (text)
       */
      seo_keywords: {
        codename: 'seo_keywords',
        id: '23f13445-2865-4e5a-9f10-ff34c0e77225',
        externalId: undefined,
        name: '📜 Keywords',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description overview (text)
       */
      seo_description_overview: {
        codename: 'seo_description_overview',
        id: 'e0ad6732-c8d7-4f36-a5f3-9f2d105bf485',
        externalId: undefined,
        name: '📜 Description overview',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description rationale (text)
       */
      seo_description_rationale: {
        codename: 'seo_description_rationale',
        id: '69d86ad8-876b-4259-a247-61d5128aaec8',
        externalId: undefined,
        name: '📜 Description rationale',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description aim (text)
       */
      seo_description_aim: {
        codename: 'seo_description_aim',
        id: '08ce0ed1-a141-4e0e-832f-f7322d72a93a',
        externalId: undefined,
        name: '📜 Description aim',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description outcomes (text)
       */
      seo_description_outcomes: {
        codename: 'seo_description_outcomes',
        id: '128eb039-d7a4-4a8c-b5de-85f875832f57',
        externalId: undefined,
        name: '📜 Description outcomes',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description content (text)
       */
      seo_description_content: {
        codename: 'seo_description_content',
        id: 'd0ab53b6-969f-4702-9f33-4db8c23f396a',
        externalId: undefined,
        name: '📜 Description content',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description assessment (text)
       */
      seo_description_assessment: {
        codename: 'seo_description_assessment',
        id: '076ed6f2-ea63-4079-996d-b70183849a25',
        externalId: undefined,
        name: '📜 Description assessment',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description glossary (text)
       */
      seo_description_glossary: {
        codename: 'seo_description_glossary',
        id: '0a713df7-7802-4da9-9711-2e4cef12732e',
        externalId: undefined,
        name: '📜 Description glossary',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description support (text)
       */
      seo_description_support: {
        codename: 'seo_description_support',
        id: 'bdcf58c9-ea9d-4eaa-8ee8-ebe0961ecb65',
        externalId: undefined,
        name: '📜 Description support',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect to education standards (multiple_choice)
       *
       * ⚠️ IMPORTANTYes = Education standards redirect is activeNo = Education standards redirect is disabled (new syl will be live on curriculum.nsw.edu.au website) DO NOT change to 'No' until syllabus is ready to go live
       */
      doredirect: {
        codename: 'doredirect',
        id: '0910fdd4-268f-473b-9da0-70d62ab01ade',
        externalId: undefined,
        name: '🔗 Redirect to education standards',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect url (text)
       *
       * External Url if Syllabus content is hosted on external site
       */
      redirecturl: {
        codename: 'redirecturl',
        id: 'cf9dfbaa-98ce-47bc-83b5-df635c20667d',
        externalId: undefined,
        name: '🔗 Redirect url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ⚒️ Allow staging view (multiple_choice)
       *
       * Used on https://nesa-staging.vercel.app and https://nesa-uat-content.vercel.app to allow early preview for syllabus content in published mode.In other words this is only used if the syllabus still redirects to education standards e.g. not yet published on NSW curriculum website.
       */
      allowpreview: {
        codename: 'allowpreview',
        id: 'aec3be20-1105-4809-9ec0-5bbdfb162687',
        externalId: undefined,
        name: '⚒️ Allow staging view',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Status (taxonomy)
       */
      current_syllabus_status: {
        codename: 'current_syllabus_status',
        id: 'cff72298-0f8a-4753-b1fb-08b007cd3979',
        externalId: undefined,
        name: '🏷️ Current syllabus status',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * 📅 Implementation year (multiple_choice)
       */
      implementation_year: {
        codename: 'implementation_year',
        id: '7a40c366-4114-4ec1-9476-6b9cac962d41',
        externalId: undefined,
        name: '📅 Implementation year',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🗒️ Current syllabus tooltip (rich_text)
       */
      current_syllabus_tooltip: {
        codename: 'current_syllabus_tooltip',
        id: '0f1b0fcb-530d-4f8d-a664-6864324d583e',
        externalId: undefined,
        name: '🗒️ Current syllabus tooltip',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📒 Previous syllabuses (modular_content)
       */
      previous_syllabuses: {
        codename: 'previous_syllabuses',
        id: 'f22057d5-4a38-495d-817d-dca1feebae53',
        externalId: undefined,
        name: '📒 Previous syllabuses',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🗒️ Previous syllabus tooltip (rich_text)
       */
      previous_syllabus_tooltip: {
        codename: 'previous_syllabus_tooltip',
        id: 'cd466081-2315-4570-a2ca-8a4f9655248d',
        externalId: undefined,
        name: '🗒️ Previous syllabus tooltip',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📃 Early Stage 1 assessment (modular_content)
       */
      assessments_es1: {
        codename: 'assessments_es1',
        id: 'fc9e23e9-27a4-48bd-85a8-da0ad201ca86',
        externalId: undefined,
        name: '📃 Early Stage 1 assessment',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Stage 1–3 assessment (modular_content)
       */
      assessments_primary: {
        codename: 'assessments_primary',
        id: '90985098-5f6a-41a3-933a-6e852947b738',
        externalId: undefined,
        name: '📃 Stage 1–3 assessment',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Stage 4–5 assessment (modular_content)
       */
      assessments_secondary: {
        codename: 'assessments_secondary',
        id: '9629d131-5e43-4763-982b-0cc117b7aeb5',
        externalId: undefined,
        name: '📃 Stage 4–5 assessment',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Assessment of Life Skills (Stage 4–5 only) (modular_content)
       */
      assessments_ls: {
        codename: 'assessments_ls',
        id: '14209ec1-bb9f-4dac-9cd2-55997f0ab483',
        externalId: undefined,
        name: '📃 Assessment of Life Skills (Stage 4–5 only)',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🖇️ Link Content Group - Curriculum Connections
   * Last modified: Thu Jan 11 2024 03:27:17 GMT+0000 (Coordinated Universal Time)
   */
  web_link_cc_contentgroup: {
    codename: 'web_link_cc_contentgroup',
    id: '0ac80a47-dbbd-4e05-a690-09c171bf868b',
    externalId: undefined,
    name: '🖇️ Link Content Group - Curriculum Connections',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '8d227c9b-fd19-4b4d-ba07-6c23195dd5f7',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: 'd303fee4-8423-4a81-8613-cf05148ef993',
        externalId: undefined,
        name: '🔗 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '5511e63e-877a-4c5b-93b4-cdf10fab4a7c',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * Stage (taxonomy)
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '6a050fad-1e66-4c00-a372-7362376b519f',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * ⚠️ Select 2 years within the stage for K-10 syllabuses and only 1 year for Senior syllabuses.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: 'e9c1eb86-9a79-415d-8e1f-7032307a0e0b',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠Web UI Card Newsletter Subscription
   * Last modified: Tue Sep 27 2022 07:57:05 GMT+0000 (Coordinated Universal Time)
   */
  ui_card_newsletter_subscription: {
    codename: 'ui_card_newsletter_subscription',
    id: '4de60c50-569c-4dc1-bb4b-b77286f80ef5',
    externalId: undefined,
    name: '💠Web UI Card Newsletter Subscription',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '10f0625e-05e2-40a7-ad2f-7838735c3cb9',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Input Label (text)
       */
      input_label: {
        codename: 'input_label',
        id: 'bce05436-3b98-4411-9a46-dc14c63a4b90',
        externalId: undefined,
        name: '📜 Input Label',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Button Label (text)
       */
      button_label: {
        codename: 'button_label',
        id: '90dfa831-b68c-4155-8c20-6cda181ec577',
        externalId: undefined,
        name: '📜 Button Label',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ CreateSend Id (text)
       */
      createsend_id: {
        codename: 'createsend_id',
        id: '587040de-fc36-44d2-94bb-c235c678a1a8',
        externalId: undefined,
        name: '⚙️ CreateSend Id',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ CreateSend Action (text)
       */
      createsend_action: {
        codename: 'createsend_action',
        id: '1eeaf937-e7ff-477a-bee6-6cb09dd85fa9',
        externalId: undefined,
        name: '⚙️ CreateSend Action',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔹 TinyMCE Editor
   * Last modified: Tue Dec 13 2022 01:26:33 GMT+0000 (Coordinated Universal Time)
   */
  tinymce: {
    codename: 'tinymce',
    id: '33d8ad35-8967-413e-840a-1c39de5e22be',
    externalId: undefined,
    name: '🔹 TinyMCE Editor',
    elements: {
      /**
       * 📜 Kontent RTB (rich_text)
       */
      _kontent_rtb: {
        codename: '_kontent_rtb',
        id: 'f6911bc9-51fe-4235-94de-af9dbb6ba8da',
        externalId: undefined,
        name: '📜 Kontent RTB',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 TinyMCE RTB (custom)
       */
      formula: {
        codename: 'formula',
        id: 'b20e4113-ce0a-4650-98d5-27e38cc35abf',
        externalId: undefined,
        name: '📜 TinyMCE RTB',
        required: false,
        type: 'custom',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔗 Link Content Group
   * Last modified: Thu Jan 11 2024 03:21:12 GMT+0000 (Coordinated Universal Time)
   */
  web_link_contentgroup: {
    codename: 'web_link_contentgroup',
    id: 'b036ea5d-f2c1-4f23-8ca2-350277b6f1a9',
    externalId: undefined,
    name: '🔗 Link Content Group',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'c5244b44-c62c-48d6-ba9a-8d73da78c0b0',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: 'bf576057-77c1-4e9f-9cc5-7b9d676849fe',
        externalId: undefined,
        name: '🔗 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: 'e605cb21-fae4-4819-9643-58dce36eefec',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * Stage (taxonomy)
       *
       * ⚠️ For mainstream links select 1 stage only.For secondary Life Skills links, select both Stage 4 and Stage 5.
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'd1f64c2b-e45f-4345-aebe-3de6c16b5ad0',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * ⚠️ Select 2 years within the stage for K-10 syllabuses and only 1 year for senior syllabuses. For secondary Life Skills links, select years from both Stage 4 and Stage 5.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '18cc0718-6319-4d86-81d1-25d187e5f187',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page Learning Area
   * Last modified: Wed May 01 2024 04:08:30 GMT+0000 (Coordinated Universal Time)
   */
  wp_learningarea: {
    codename: 'wp_learningarea',
    id: '99e7bf4f-ffdd-41dd-afb1-d1fc10fe3ed9',
    externalId: undefined,
    name: '🌐 Web Page Learning Area',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'ae6e3dee-4e8d-46ad-8c48-776b47026f80',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: 'b1ab3b44-ca1c-4a2c-893d-ce6016ab73ef',
        externalId: undefined,
        name: '🌐 Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 📒 Syllabuses (modular_content)
       */
      syllabuses: {
        codename: 'syllabuses',
        id: '3f586aa8-5544-47b1-8bfb-def32d8fc21d',
        externalId: undefined,
        name: '📒 Syllabuses',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: 'cc1898ec-9e9c-4839-ad66-e80499a45a57',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: 'd4be49ed-5b70-4bf1-8396-c4c4ba8a1877',
        externalId: undefined,
        name: 'Key Learning Area',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'key_learning_area',
      },
    },
  },

  /**
   * 📑 Teaching Advice
   * Last modified: Fri Jul 12 2024 04:46:41 GMT+0000 (Coordinated Universal Time)
   */
  teachingadvice: {
    codename: 'teachingadvice',
    id: '61764978-4d8e-4070-b57b-14ec11ee61d9',
    externalId: undefined,
    name: '📑 Teaching Advice',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'db1c0d89-1fdc-4078-b2ce-15b9c4f7c6d0',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'fda84357-073e-4e92-90b8-141db3611c4d',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📦 Resources (asset)
       */
      resources: {
        codename: 'resources',
        id: 'e002163e-6650-492f-8456-f70cc82016ee',
        externalId: undefined,
        name: '📦 Resources',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '2b7602e5-18cd-4352-8b12-b7d1846fe2bc',
        externalId: undefined,
        name: '📒 Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * 📆 Updated (date_time)
       */
      updated: {
        codename: 'updated',
        id: 'e5e00643-54b0-4ab9-b4e0-b96bd3664b44',
        externalId: undefined,
        name: '📆 Updated',
        required: true,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📜 Code (text)
       *
       * ⚠️ Not used
       */
      code: {
        codename: 'code',
        id: '3db54469-fa1a-4161-9143-9bec01ee349c',
        externalId: undefined,
        name: '📜 Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📜 Content - Outcomes Notification
   * Last modified: Wed Nov 22 2023 05:23:18 GMT+0000 (Coordinated Universal Time)
   */
  content_outcomenotification: {
    codename: 'content_outcomenotification',
    id: '9e46d0f7-f8ba-4117-8d61-568ad3d09fb8',
    externalId: undefined,
    name: '📜 Content - Outcomes Notification',
    elements: {
      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '9fbf7100-2c8c-4313-801e-b9b0c86a5dc6',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      stages: {
        codename: 'stages',
        id: '9538edb0-8edd-42bd-93ea-9d38e44e0cc4',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       */
      stage_years: {
        codename: 'stage_years',
        id: '6ccac18b-57c4-47a7-91a8-88a11e5b39af',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: 'f4191c08-a3c9-4553-b4ae-112aa5293d50',
        externalId: undefined,
        name: '🏷️ Content Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application
       */
      syllabus: {
        codename: 'syllabus',
        id: '6f030a67-37f7-4f42-b9f5-1450afff3e33',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Display (multiple_choice)
       */
      display: {
        codename: 'display',
        id: 'c8478ffe-5353-4687-9c26-221e2ef8ed8e',
        externalId: undefined,
        name: '⚙️ Display',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📄🔹Content Item
   * Last modified: Thu Oct 26 2023 14:20:44 GMT+0000 (Coordinated Universal Time)
   */
  contentitem: {
    codename: 'contentitem',
    id: '0bd86ad0-4862-477a-8a26-3939651ff455',
    externalId: undefined,
    name: '📄🔹Content Item',
    elements: {
      /**
       * 📜 Title (rich_text)
       */
      title: {
        codename: 'title',
        id: 'a822897f-771b-46ee-88ac-daf436ddfc2c',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Examples (rich_text)
       *
       * Note: Bullet points CANNOT be used in Examples
       */
      examples: {
        codename: 'examples',
        id: '4d26b171-54a8-45e4-9975-962ff4229d80',
        externalId: undefined,
        name: '📜 Examples',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Language example variants (modular_content)
       */
      examples_lang: {
        codename: 'examples_lang',
        id: '608290ab-4aab-43fb-9c99-1332c198b715',
        externalId: undefined,
        name: '📜 Language example variants',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Including Statements (rich_text)
       */
      including_statements: {
        codename: 'including_statements',
        id: 'cfbf91e9-7f9f-4c3d-8423-90e25b54e1f7',
        externalId: undefined,
        name: '📜 Including Statements',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '941089ee-4fb7-4d1a-9b41-a09d26142b0d',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Pathways (taxonomy)
       */
      pathway__pathway: {
        codename: 'pathway__pathway',
        id: 'f30d20f5-beeb-4511-8b34-0d2106b74dc5',
        externalId: undefined,
        name: '🏷️Pathway',
        required: false,
        type: 'taxonomy',
        snippetCodename: 'pathway',
      },

      /**
       * National Literacy Learning Progression (taxonomy)
       */
      learningprogression_tags__literacy: {
        codename: 'learningprogression_tags__literacy',
        id: '5334fc75-ea6c-4c8b-a236-3e9f5321d6ae',
        externalId: undefined,
        name: '🔖 National Literacy Learning Progression',
        required: false,
        type: 'taxonomy',
        snippetCodename: 'learningprogression_tags',
      },

      /**
       * National Numeracy Learning Progression (taxonomy)
       */
      learningprogression_tags__numeracy: {
        codename: 'learningprogression_tags__numeracy',
        id: '9183d2fe-8fab-4b2c-b51f-c86265f29d33',
        externalId: undefined,
        name: '🔖 National Numeracy Learning Progression',
        required: false,
        type: 'taxonomy',
        snippetCodename: 'learningprogression_tags',
      },

      /**
       * 📜 Code (text)
       */
      code: {
        codename: 'code',
        id: '674ea232-b0e4-4e44-8125-c5f3f3f52f49',
        externalId: undefined,
        name: '📜 Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page DC Recent Changes
   * Last modified: Sat Jul 08 2023 23:22:09 GMT+0000 (Coordinated Universal Time)
   */
  wp_dc_recentchanges: {
    codename: 'wp_dc_recentchanges',
    id: 'a048daca-7e14-4494-a450-5a8b1c9fd918',
    externalId: undefined,
    name: '🌐 Web Page DC Recent Changes',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'c7613424-4b5b-4e27-9676-b44472857a21',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: 'ae58c10d-a9d9-4cda-8067-e4e44daf0f8e',
        externalId: undefined,
        name: '🌐 Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 🌐 Left navigation links (modular_content)
       */
      left_navigation: {
        codename: 'left_navigation',
        id: '6bc537f9-9978-48c0-b9c1-f0ea02e583f6',
        externalId: undefined,
        name: '🌐 Left navigation links',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '372790cb-4c71-4271-b903-efde7208aa31',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: '458717d1-6e11-41e8-a3a2-ae0980933c7d',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       *
       * Relative or abosute url
       */
      redirect_url: {
        codename: 'redirect_url',
        id: '5a347e30-4510-407e-8692-57cc26264874',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔗 Web Link External
   * Last modified: Mon Nov 20 2023 04:25:20 GMT+0000 (Coordinated Universal Time)
   */
  weblinkext: {
    codename: 'weblinkext',
    id: 'c6e5c373-97a2-4215-be00-1fee2f045e0e',
    externalId: undefined,
    name: '🔗 Web Link External',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'dff5fa00-8ec2-4dd9-a9ae-e70b75a96af4',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Sub Title (text)
       */
      subtitle: {
        codename: 'subtitle',
        id: '1e5aad05-24ec-4cbe-8807-2f70dbaef18a',
        externalId: undefined,
        name: '📜 Sub Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Link Url (text)
       */
      link_url: {
        codename: 'link_url',
        id: 'a25e6d24-fc3c-47e1-bb36-a6d285e385c7',
        externalId: undefined,
        name: '🔗 Link Url',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * Resource type (taxonomy)
       */
      resource_type: {
        codename: 'resource_type',
        id: 'e2904483-ad1e-4a76-b9b9-48d9585a7980',
        externalId: undefined,
        name: '🏷️ Resource Type',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '78de8a11-e7ec-4a1e-9ccb-a58949991a74',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       */
      syllabus_type: {
        codename: 'syllabus_type',
        id: 'dbbfabe1-277d-4918-8f94-5995e6fd18ea',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: 'd4be49ed-5b70-4bf1-8396-c4c4ba8a1877',
        externalId: undefined,
        name: 'Key Learning Area',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'key_learning_area',
      },

      /**
       * 📆 Updated (date_time)
       */
      updated: {
        codename: 'updated',
        id: 'ba1560d9-c413-4b45-885f-36f80529fe0c',
        externalId: undefined,
        name: '📆 Updated',
        required: false,
        type: 'date_time',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠Web UI Video Tile
   * Last modified: Wed Jul 10 2024 03:58:42 GMT+0000 (Coordinated Universal Time)
   */
  ui_video_tile: {
    codename: 'ui_video_tile',
    id: 'f6b03424-43e0-4428-b996-25c945816f27',
    externalId: undefined,
    name: '💠Web UI Video Tile',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '4d991140-89b8-4144-9620-111a930d2b93',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Caption (rich_text)
       */
      caption: {
        codename: 'caption',
        id: 'fe358400-402a-4068-9815-8bff49d83c07',
        externalId: undefined,
        name: '📜 Caption',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📅 Date (date_time)
       */
      date: {
        codename: 'date',
        id: '6d451270-9c58-4935-be27-4a4d0af7904e',
        externalId: undefined,
        name: '📅 Date',
        required: false,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📃 Transcript (asset)
       */
      transcript: {
        codename: 'transcript',
        id: '1bf6d8ef-1ba2-4ca6-9f6f-5dd62450fa58',
        externalId: undefined,
        name: '📃 Transcript',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Video Url (text)
       */
      video_url: {
        codename: 'video_url',
        id: '94cc7b29-c7bf-4eae-9f36-94a9a1867453',
        externalId: undefined,
        name: '🔗 Video Url',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📦 Thumbnail (asset)
       */
      thumbnail: {
        codename: 'thumbnail',
        id: '03f4f51a-b109-4f3e-b93d-dc82e6fbfe1d',
        externalId: undefined,
        name: '📦 Thumbnail',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area: {
        codename: 'key_learning_area',
        id: '8e525e2a-21c9-465b-95fd-0677dd2811aa',
        externalId: undefined,
        name: '🏷️ Key Learning Area',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '0fd57f30-8a56-4f02-93f5-4c099a52b700',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Accordion
   * Last modified: Mon May 20 2024 13:15:27 GMT+0000 (Coordinated Universal Time)
   */
  ui_accordion: {
    codename: 'ui_accordion',
    id: '6d5d5ce3-384d-418f-b7c6-d5c3ba660055',
    externalId: undefined,
    name: '💠 Web UI Accordion',
    elements: {
      /**
       * 📜 Items (modular_content)
       */
      items: {
        codename: 'items',
        id: 'c25a3658-70d9-4916-a1a5-3a89ed66d7fa',
        externalId: undefined,
        name: '📜 Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Show Expand / Collapse (multiple_choice)
       */
      showexpandcollapse: {
        codename: 'showexpandcollapse',
        id: 'c2e9f79f-3826-44f6-a8de-3097f26cc96b',
        externalId: undefined,
        name: '⚙️ Show Expand / Collapse',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Section Level (multiple_choice)
       *
       * ⚠️ Used in print only.Section level where this accordion is placed into. Used during syllabus print to determine the heading level for each child accordion item sections.
       */
      level: {
        codename: 'level',
        id: '7e042067-a156-40d9-bdbf-9998a8dbe2b7',
        externalId: undefined,
        name: '⚙️ Section Level',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📂🔸Options List
   * Last modified: Tue Jun 18 2024 06:49:03 GMT+0000 (Coordinated Universal Time)
   */
  optionslist: {
    codename: 'optionslist',
    id: '08278db3-5298-4a5a-9aac-361027e7b678',
    externalId: undefined,
    name: '📂🔸Options List',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '8db87526-2ee3-406d-97ff-19929816fd02',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '0d37ae7d-7947-4983-b5cd-10a0df92635d',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📃🔸Focus area options (modular_content)
       */
      focus_area_options: {
        codename: 'focus_area_options',
        id: '69e08ee5-780a-4b3d-b4fa-f04b95e38285',
        externalId: undefined,
        name: '📃🔸Focus area options',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '02b9b50a-f591-4325-8c05-837f4ac40dfa',
        externalId: undefined,
        name: '📒 Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * 📚 Course (modular_content)
       */
      course: {
        codename: 'course',
        id: 'bfcccd1e-2171-4c63-8d3d-f3372644a170',
        externalId: undefined,
        name: '📚 Course',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌏 Homepage
   * Last modified: Tue Feb 27 2024 01:46:04 GMT+0000 (Coordinated Universal Time)
   */
  wp_homepage: {
    codename: 'wp_homepage',
    id: 'abeaab42-5c6f-4411-92e5-3fb2d33246b9',
    externalId: undefined,
    name: '🌏 Homepage',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '440ee774-89ed-4df9-946c-b7483b5a5b8b',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Subtitle (text)
       */
      subtitle: {
        codename: 'subtitle',
        id: '6cc0bef7-fe6d-478c-b704-8003813e619d',
        externalId: undefined,
        name: '📜 Subtitle',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: '14cf2042-90a5-43aa-90c4-0debe407d8cb',
        externalId: undefined,
        name: '🌐 Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 💠Hero banner (modular_content)
       */
      hero_banner: {
        codename: 'hero_banner',
        id: '86b4ae66-cb11-4e42-956b-eda491a666eb',
        externalId: undefined,
        name: '💠Hero banner',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 💠Stages (modular_content)
       */
      stages: {
        codename: 'stages',
        id: 'da483490-e187-489b-b503-6b2f44bd3bbc',
        externalId: undefined,
        name: '💠Stages',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 💠Learning areas (modular_content)
       */
      learning_areas: {
        codename: 'learning_areas',
        id: 'f2c0a6f9-914b-4ec0-bb17-d5a0f7ada5f7',
        externalId: undefined,
        name: '💠Learning areas',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 💠Teaching and learning (modular_content)
       */
      teaching_and_learning: {
        codename: 'teaching_and_learning',
        id: '86751c7b-7025-427d-b8d0-3591588ef0b5',
        externalId: undefined,
        name: '💠Teaching and learning',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 💠ACE Rules (modular_content)
       */
      ace_rules: {
        codename: 'ace_rules',
        id: '57c5b650-750a-4604-8492-62e268564881',
        externalId: undefined,
        name: '💠ACE Rules',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 💠Links (modular_content)
       */
      links: {
        codename: 'links',
        id: 'c3d01b68-1cec-420a-bc8f-bbb0b49fca2a',
        externalId: undefined,
        name: '💠Links',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 📜 Site Title (text)
       *
       * Leave empty, if you don't want any site prefix
       */
      site_prefix: {
        codename: 'site_prefix',
        id: '5190cd96-3843-45ce-b3b0-7af96d3b1da6',
        externalId: undefined,
        name: '📜 Site Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📦 Favicon (asset)
       */
      favicon: {
        codename: 'favicon',
        id: '002e759f-e652-43e3-b6d1-8f28316fd35f',
        externalId: undefined,
        name: '📦 Favicon',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * 📜 Descriptor (text)
       *
       * Used as site descriptor
       */
      descriptor: {
        codename: 'descriptor',
        id: 'edbae2c6-d181-4c57-873a-15eca4fbeef1',
        externalId: undefined,
        name: '📜 Descriptor',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Palette (multiple_choice)
       */
      palette: {
        codename: 'palette',
        id: '42a61211-458e-4cb9-a6b4-8d00824cef27',
        externalId: undefined,
        name: '⚙️ Palette',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      disabled_stages: {
        codename: 'disabled_stages',
        id: 'e04c00d8-f4fc-4977-b39b-d5b8efcbd039',
        externalId: undefined,
        name: '⚙️ Disabled Stages',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       */
      disabled_key_learning_areas: {
        codename: 'disabled_key_learning_areas',
        id: 'be606747-db13-44af-b885-ed89c20ef6f1',
        externalId: undefined,
        name: '⚙️ Disabled Key Learning Areas',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * 💠 Global announcement - top (modular_content)
       *
       * For example Caretaker period alert. Only content fields will be displayed.
       */
      global_info: {
        codename: 'global_info',
        id: '86469d6c-6ff8-44dc-a1c2-24c1a0485ad7',
        externalId: undefined,
        name: '💠 Global announcement - top',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 💠 Global notification (modular_content)
       *
       * Other global infofmation notifications. All fileds can be used e.g. content and primary/secondary buttons.
       */
      global_alert: {
        codename: 'global_alert',
        id: '9eb73590-f53a-447c-9f2d-9270e62aeb2f',
        externalId: undefined,
        name: '💠 Global notification',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 💠 Global notification - ACE (modular_content)
       */
      global_alert_ace: {
        codename: 'global_alert_ace',
        id: '1cf533bf-7bfe-4a77-b678-6f04b15bc5bf',
        externalId: undefined,
        name: '💠 Global notification - ACE',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📦 Logo main (asset)
       */
      logo_main: {
        codename: 'logo_main',
        id: '0bc1c95d-1473-4b00-bb79-00e4919d3e07',
        externalId: undefined,
        name: '📦 Logo main',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * 📂 Main Menu (modular_content)
       */
      main_menu: {
        codename: 'main_menu',
        id: '74f7eb26-2d7c-45cf-833c-e83254280fda',
        externalId: undefined,
        name: '📂 Main Menu',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       *
       * Footer top content
       */
      footer_top_content: {
        codename: 'footer_top_content',
        id: '119acf47-b530-48a0-9fea-670f60ffd86a',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Social Links (modular_content)
       */
      social_links: {
        codename: 'social_links',
        id: '13d6ef58-2119-4ab3-9243-7196bcf2116e',
        externalId: undefined,
        name: '🔗 Social Links',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📂 Menu links (modular_content)
       *
       * Footer menu links
       */
      footer_menu_links: {
        codename: 'footer_menu_links',
        id: 'dd95ce4c-8c7c-4694-8767-442f352f9175',
        externalId: undefined,
        name: '📂 Menu links',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Acknowledgement (rich_text)
       *
       * Appears in the footer bottom
       */
      acknowledgement: {
        codename: 'acknowledgement',
        id: '4255b49e-e8f2-46df-9d19-a022fa34d5aa',
        externalId: undefined,
        name: '📜 Acknowledgement',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📂 Secondary links (modular_content)
       */
      secondary_links: {
        codename: 'secondary_links',
        id: '06820a17-3cbd-41d5-a269-33702919e99b',
        externalId: undefined,
        name: '📂 Secondary links',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📂 Copyright link (modular_content)
       */
      copyright_link: {
        codename: 'copyright_link',
        id: 'dd60dad0-09f1-4d30-9363-a3aa25b05764',
        externalId: undefined,
        name: '📂 Copyright link',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🗒️ K-10 Syllabus Outcomes - Related outomes (rich_text)
       */
      syllabusoutcomes_relatedoutomes: {
        codename: 'syllabusoutcomes_relatedoutomes',
        id: '256133ed-2561-4935-8519-8201d9062582',
        externalId: undefined,
        name: '🗒️ K-10 Syllabus Outcomes - Related outomes',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 🗒️ S6 Syllabus Outcomes - Related outomes (rich_text)
       */
      syllabussenioroutcomes_relatedoutomes: {
        codename: 'syllabussenioroutcomes_relatedoutomes',
        id: 'f350c845-6511-4039-a1ba-e2cd69317f86',
        externalId: undefined,
        name: '🗒️ S6 Syllabus Outcomes - Related outomes',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 🗒️ Syllabus Outcomes - Aligned content (rich_text)
       */
      syllabusoutcomes_alignedcontent: {
        codename: 'syllabusoutcomes_alignedcontent',
        id: '59c7bf32-a768-47ac-b3a3-a968b7734429',
        externalId: undefined,
        name: '🗒️ Syllabus Outcomes - Aligned content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Glossary Intro (rich_text)
       *
       * Glossary intro that will be used in Glossary tab.
       */
      glossary_intro: {
        codename: 'glossary_intro',
        id: '569071c5-cc35-4dfe-9bf1-9781f2acf6e3',
        externalId: undefined,
        name: '📜 Glossary Intro',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 No content - teaching advice (text)
       */
      nocontent_teachingadvice: {
        codename: 'nocontent_teachingadvice',
        id: '3235255a-dec9-4db6-8d81-daaabe5713f4',
        externalId: undefined,
        name: '📜 No content - teaching advice',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 External link pop up message (text)
       */
      externallink_popup_message: {
        codename: 'externallink_popup_message',
        id: 'd8e6d218-c66f-413e-82da-dac7a98f5f01',
        externalId: undefined,
        name: '📜 External link pop up message',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Overarching outcome message (text)
       */
      overarchingoutcome_message: {
        codename: 'overarchingoutcome_message',
        id: '9d2642f8-a394-40e6-ae91-ca79ed31a50a',
        externalId: undefined,
        name: '📜 Overarching outcome message',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🗒️ Syllabus Content - Parallel content (rich_text)
       */
      syllabuscontent_parallelcontent: {
        codename: 'syllabuscontent_parallelcontent',
        id: '8eddde4b-1a4a-490f-ab19-b9109015d96d',
        externalId: undefined,
        name: '🗒️ Syllabus Content - Parallel content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 No content - parallel content (text)
       */
      nocontent_parallelcontent: {
        codename: 'nocontent_parallelcontent',
        id: '1e9e5787-bc68-4ad6-80a7-d54423cb0770',
        externalId: undefined,
        name: '📜 No content - parallel content',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🛠️ 404 Error Page
   * Last modified: Tue Aug 22 2023 05:45:43 GMT+0000 (Coordinated Universal Time)
   */
  web_404: {
    codename: 'web_404',
    id: '592e668d-7b82-4aab-8ce8-b93e60dca6c2',
    externalId: undefined,
    name: '🛠️ 404 Error Page',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '96660b40-2dd3-463b-9d3d-6a8ae3ec3e7b',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: 'dd6c86e4-81c5-4ffa-93cf-270add310ccb',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: '37895c09-ab76-4953-913c-fd20150e9fe7',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       *
       * Relative or abosute url
       */
      redirect_url: {
        codename: 'redirect_url',
        id: 'f6c22250-f5e4-4a9b-a44b-38aafb4054e1',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Callout
   * Last modified: Thu Jun 30 2022 03:21:50 GMT+0000 (Coordinated Universal Time)
   */
  ui_callout: {
    codename: 'ui_callout',
    id: '7c82f987-dea9-4f5b-a969-9be7f9a0bfab',
    externalId: undefined,
    name: '💠 Web UI Callout',
    elements: {
      /**
       * 📜 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: '2774d69a-f7fd-4a77-8d32-88e0cf9460ea',
        externalId: undefined,
        name: '📜 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📑 Access Content Group
   * Last modified: Thu Oct 26 2023 14:12:14 GMT+0000 (Coordinated Universal Time)
   */
  accesscontentgroup: {
    codename: 'accesscontentgroup',
    id: '3107ca09-b0be-46d1-8573-ebf3439f91c1',
    externalId: undefined,
    name: '📑 Access Content Group',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '4c8258b5-58f5-4022-a286-6ad2166e7192',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📄 Access Content Items (modular_content)
       */
      access_content_items: {
        codename: 'access_content_items',
        id: 'e0d7dd7b-dde7-4241-9e3c-4910d95ed598',
        externalId: undefined,
        name: '📄 Access Content Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '9660d4cf-2287-44e5-9490-3c6f963d6e05',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Code (text)
       */
      code: {
        codename: 'code',
        id: 'cfa8526e-4726-4134-92fc-e59c3f9425aa',
        externalId: undefined,
        name: 'Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔠 Glossary
   * Last modified: Tue Jun 04 2024 06:30:55 GMT+0000 (Coordinated Universal Time)
   */
  glossary: {
    codename: 'glossary',
    id: '6e8ddfc7-87a9-4e46-84fd-56b17c934704',
    externalId: undefined,
    name: '🔠 Glossary',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '4b8b535f-139b-4a23-9f35-7d07113f2541',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description (rich_text)
       */
      description: {
        codename: 'description',
        id: '631b5158-e8e3-449a-9f69-695a7033fd38',
        externalId: undefined,
        name: '📜 Description',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: 'c8536783-d458-46d7-8dea-d8e8fb2dedaf',
        externalId: undefined,
        name: '🏷️ Related Syllabuses',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Glossary Type (taxonomy)
       */
      type: {
        codename: 'type',
        id: 'caa5d750-6f6b-46b7-a69e-1384854dc452',
        externalId: undefined,
        name: '🏷️ Glossary Type',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * ACE Category (taxonomy)
       */
      ace_category: {
        codename: 'ace_category',
        id: 'e80190e9-ea81-49df-8640-0f9cb0751602',
        externalId: undefined,
        name: '🏷️ ACE Category',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Code (text)
       */
      code: {
        codename: 'code',
        id: 'b59ef167-44aa-470a-a67a-0ca52e3652fa',
        externalId: undefined,
        name: 'Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🚩 Change note - DC Multiple Syllabuses
   * Last modified: Tue Jun 04 2024 06:25:29 GMT+0000 (Coordinated Universal Time)
   */
  releasenote_syllabus_multiple: {
    codename: 'releasenote_syllabus_multiple',
    id: 'ed7fdbec-980a-47e9-9936-26f2d3b3763f',
    externalId: undefined,
    name: '🚩 Change note - DC Multiple Syllabuses',
    elements: {
      /**
       * 📅 Release date (date_time)
       */
      releasedate: {
        codename: 'releasedate',
        id: '07fe815f-3b9a-4d6e-8c00-09389cf7a62e',
        externalId: undefined,
        name: '📅 Release date',
        required: true,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '0d35d391-c390-41e7-8f53-7394ea6b8028',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'c847cadf-28f5-446f-9440-a54655cf22bf',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       *
       * Link to specific syllabuses if this update does not affect all syllabuses within the given Stage/Year or KLA
       */
      syllabuses: {
        codename: 'syllabuses',
        id: '780d161e-2735-4933-af5b-bf3f5f8dc07e',
        externalId: undefined,
        name: '🏷️ Syllabuses',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '683954ba-18f0-45da-86a2-64ce1432a914',
        externalId: undefined,
        name: '🏷️ Stage',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '6994d3cb-727f-4847-80bd-0c6e5da65090',
        externalId: undefined,
        name: '🏷️ Stage Year',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: 'd4be49ed-5b70-4bf1-8396-c4c4ba8a1877',
        externalId: undefined,
        name: 'Key Learning Area',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'key_learning_area',
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '71640beb-1a70-4884-9651-dff3073f16ed',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI List items
   * Last modified: Thu Jun 30 2022 03:28:03 GMT+0000 (Coordinated Universal Time)
   */
  ui_listitems: {
    codename: 'ui_listitems',
    id: 'f03af4bc-f09e-405a-8fa6-937a184818a7',
    externalId: undefined,
    name: '💠 Web UI List items',
    elements: {
      /**
       * 📜 Items (modular_content)
       */
      items: {
        codename: 'items',
        id: 'bb533a51-650f-44bf-8ddf-3497c12aadf7',
        externalId: undefined,
        name: '📜 Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Display (multiple_choice)
       */
      display: {
        codename: 'display',
        id: '738c508f-953a-4c01-b0a3-c85167dbd3c9',
        externalId: undefined,
        name: '⚙️ Display',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔗 Link Syllabus
   * Last modified: Mon Aug 19 2024 02:44:30 GMT+0000 (Coordinated Universal Time)
   */
  web_link_syllabus: {
    codename: 'web_link_syllabus',
    id: '5690dc10-d959-4a3b-a042-037533342850',
    externalId: undefined,
    name: '🔗 Link Syllabus',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'ed6b6651-0874-4bf1-988e-0227dbc290f1',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Sub Title (text)
       */
      subtitle: {
        codename: 'subtitle',
        id: '331eedc2-2711-4760-9646-1474de3d7bda',
        externalId: undefined,
        name: '📜 Sub Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: 'e6854faa-77b3-49f2-9570-5c62d648c0c1',
        externalId: undefined,
        name: '🔗 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * ⚓ Tab (multiple_choice)
       */
      tab: {
        codename: 'tab',
        id: 'bc467736-1b05-43e9-978e-309de0c6f21d',
        externalId: undefined,
        name: '⚓ Tab',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Additional page (modular_content)
       */
      additional_page: {
        codename: 'additional_page',
        id: 'aec443af-4e5b-4344-858b-0978d2c38482',
        externalId: undefined,
        name: '🔗 Additional page',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📂 Collection Web Links
   * Last modified: Fri Jul 01 2022 03:57:21 GMT+0000 (Coordinated Universal Time)
   */
  collection_weblink: {
    codename: 'collection_weblink',
    id: '3d06ad68-3152-4f45-8248-ccc919c47241',
    externalId: undefined,
    name: '📂 Collection Web Links',
    elements: {
      /**
       * Items (modular_content)
       */
      items: {
        codename: 'items',
        id: '497d540c-972e-4444-91bb-3d0df465923a',
        externalId: undefined,
        name: 'Items',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠Web UI Horizontal Line
   * Last modified: Fri Aug 12 2022 03:31:17 GMT+0000 (Coordinated Universal Time)
   */
  ui_horizontal_line: {
    codename: 'ui_horizontal_line',
    id: '43cf7b71-c17d-4d36-ac77-077db02dd4cb',
    externalId: undefined,
    name: '💠Web UI Horizontal Line',
    elements: {},
  },

  /**
   * 📈 Focus Area Continuum
   * Last modified: Thu Oct 26 2023 14:12:09 GMT+0000 (Coordinated Universal Time)
   */
  focusareacontinuum: {
    codename: 'focusareacontinuum',
    id: '919827d5-bc4b-46f3-9e3a-404cca9155ae',
    externalId: undefined,
    name: '📈 Focus Area Continuum',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'fd5688ca-be40-4fab-a362-7d5933a0ffa4',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '99949c96-3b92-4dbf-9266-881290ad74fc',
        externalId: undefined,
        name: '📒 Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Code (text)
       */
      code: {
        codename: 'code',
        id: '283b4c6c-fcd2-4dc0-b3dd-83f4149fc6ef',
        externalId: undefined,
        name: 'Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page Learning Areas
   * Last modified: Tue Sep 27 2022 08:18:21 GMT+0000 (Coordinated Universal Time)
   */
  wp_learningareas: {
    codename: 'wp_learningareas',
    id: '71664279-a9d4-4589-80fd-5ebabedcadef',
    externalId: undefined,
    name: '🌐 Web Page Learning Areas',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '56489684-f711-4bf9-a4ec-4caf613b2640',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: 'be6d97ca-9f4b-4b5f-a781-7e28647334fb',
        externalId: undefined,
        name: '🌐 Subpages',
        required: true,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 💠 Links (modular_content)
       */
      links: {
        codename: 'links',
        id: 'e449f4e4-0c0b-4c39-bf75-d2cfa521bb5b',
        externalId: undefined,
        name: '💠 Links',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '0cecb70f-fbae-4a0f-9f53-60c60a9e08a6',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },
    },
  },

  /**
   * 📜 Content - Language Example
   * Last modified: Mon Jan 15 2024 06:12:51 GMT+0000 (Coordinated Universal Time)
   */
  content_langexample: {
    codename: 'content_langexample',
    id: 'f51d7340-474b-43d8-af50-36025be8921e',
    externalId: undefined,
    name: '📜 Content - Language Example',
    elements: {
      /**
       * 📜 Content (rich_text)
       *
       * Note: Bullet points CANNOT be used in Examples
       */
      content: {
        codename: 'content',
        id: '2c3bacfd-3893-45d2-af67-3d8d81337089',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Language (taxonomy)
       */
      language: {
        codename: 'language',
        id: '27ae6687-09e4-4bd1-ae63-61a63a67b75f',
        externalId: undefined,
        name: '🏷️ Language',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application
       */
      syllabus: {
        codename: 'syllabus',
        id: '035aa9a3-54e4-46a2-9a52-8a37a1bdd47c',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application
       */
      stages: {
        codename: 'stages',
        id: 'b72928a3-6a5c-4a73-bbf1-e332016600ca',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application
       */
      stage_years: {
        codename: 'stage_years',
        id: '88c95899-29f4-48a4-9808-51493a7e74be',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 WebPage Teaching Resources
   * Last modified: Thu Jun 30 2022 02:00:42 GMT+0000 (Coordinated Universal Time)
   */
  wp_resources: {
    codename: 'wp_resources',
    id: '214f5fcd-b244-45aa-bc0f-9b8691c2881d',
    externalId: undefined,
    name: '🌐 WebPage Teaching Resources',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '98f4faa6-da3f-46c4-94d9-36b1a565d858',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: 'fa38cfcb-fa8f-4f37-9849-e56e93c14462',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: '9fe66be0-2b50-428e-9c93-7e912a453ce2',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       */
      redirect_url: {
        codename: 'redirect_url',
        id: '543d5d38-bcf8-4579-8e74-59131f6b91b3',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔹 Custom Editor (math/smallcaps)
   * Last modified: Thu Aug 29 2024 07:52:16 GMT+0000 (Coordinated Universal Time)
   */
  math_content: {
    codename: 'math_content',
    id: '8381338d-fc89-4726-9fe6-bbcbf8cf3410',
    externalId: undefined,
    name: '🔹 Custom Editor (math/smallcaps)',
    elements: {
      /**
       * 📜 Content (custom)
       */
      content: {
        codename: 'content',
        id: 'a5bdbdde-3455-48ed-b98e-921f362adc06',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'custom',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Media
   * Last modified: Thu Aug 04 2022 03:47:34 GMT+0000 (Coordinated Universal Time)
   */
  ui_media: {
    codename: 'ui_media',
    id: '57273f03-c142-4e8b-82a6-93be4b6f6b31',
    externalId: undefined,
    name: '💠 Web UI Media',
    elements: {
      /**
       * 📜 Caption (text)
       */
      caption: {
        codename: 'caption',
        id: '280e4039-5fb9-4aa4-85e4-88d9c07baa87',
        externalId: undefined,
        name: '📜 Caption',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Media type (multiple_choice)
       *
       * Ensure that image or video link is provided based on Media type selected
       */
      type: {
        codename: 'type',
        id: '0633d600-cefd-4ae4-8f8d-11e5188d1770',
        externalId: undefined,
        name: '⚙️ Media type',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 📦 Item image (asset)
       */
      image: {
        codename: 'image',
        id: 'e514690b-7351-46cc-82df-2668b424f17f',
        externalId: undefined,
        name: '📦 Item image',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item video (text)
       *
       * Link to video e.g. https://www.youtube.com/embed/HNHu2zRPSB4
       */
      video: {
        codename: 'video',
        id: '5b758347-c882-4883-9493-6af39b516f2c',
        externalId: undefined,
        name: '🔗 Item video',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Width (multiple_choice)
       */
      width: {
        codename: 'width',
        id: '45227deb-4ebd-4124-ab52-5a7c44a91a5c',
        externalId: undefined,
        name: '⚙️ Width',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Align (multiple_choice)
       */
      align: {
        codename: 'align',
        id: 'ebf2d10f-f3ba-4afa-b265-2f95bcb10175',
        externalId: undefined,
        name: '⚙️ Align',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🎨 Theme (multiple_choice)
       */
      theme: {
        codename: 'theme',
        id: '280ad6f1-6e74-4d56-9e01-1ddcbecb3583',
        externalId: undefined,
        name: '🎨 Theme',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Cards
   * Last modified: Tue Sep 27 2022 08:09:39 GMT+0000 (Coordinated Universal Time)
   */
  ui_cards: {
    codename: 'ui_cards',
    id: '5b5eff72-86c1-40a9-bf0a-ff06fdc6010b',
    externalId: undefined,
    name: '💠 Web UI Cards',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '017a7e95-9be3-4a82-bae9-4dc50b5b6cf5',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Items (modular_content)
       */
      items: {
        codename: 'items',
        id: '72553524-4204-4955-a97d-7e28dc99c843',
        externalId: undefined,
        name: '📜 Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Display (multiple_choice)
       */
      display: {
        codename: 'display',
        id: '59b3242c-ed12-48b0-8afb-6d3de6ccba16',
        externalId: undefined,
        name: '⚙️ Display',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🎨 Theme (multiple_choice)
       */
      theme: {
        codename: 'theme',
        id: '9081c785-e970-403d-9106-fe30d20a5037',
        externalId: undefined,
        name: '🎨 Theme',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📃🔸Focus Area Option
   * Last modified: Wed Jul 31 2024 05:17:17 GMT+0000 (Coordinated Universal Time)
   */
  focusareaoption: {
    codename: 'focusareaoption',
    id: '3d54d0d8-32a8-47ad-952d-eb877110710c',
    externalId: undefined,
    name: '📃🔸Focus Area Option',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'd2efabf0-d9bc-4cd6-9555-e5c26c0fb3ee',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '0c738971-0404-47f0-ae5e-3f92c9c828ea',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📑 Teaching Advice (modular_content)
       */
      teachingadvice: {
        codename: 'teachingadvice',
        id: '6497974e-2906-492b-9179-4affad181caf',
        externalId: undefined,
        name: '📑 Teaching Advice',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Related Focus Areas (modular_content)
       *
       * Related Mainstream / Life Skills Focus Area
       */
      related_focusareas: {
        codename: 'related_focusareas',
        id: 'cca71d86-d6ac-4271-80cb-3528e62ef82e',
        externalId: undefined,
        name: '📃 Related Focus Areas',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📑 Content Groups (modular_content)
       */
      contentgroups: {
        codename: 'contentgroups',
        id: 'a4fa85aa-564e-46fb-af07-fd86215c9849',
        externalId: undefined,
        name: '📑 Content Groups',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Outcomes (modular_content)
       */
      outcomes: {
        codename: 'outcomes',
        id: 'eaea31ef-e322-4196-8b53-3ab7bc0c684c',
        externalId: undefined,
        name: '📃 Outcomes',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Related outcomes overwrite (modular_content)
       *
       * ⚠️ Used for PRINT only
       */
      related_outcomes: {
        codename: 'related_outcomes',
        id: 'efe11057-4a38-4ffb-9203-bc7bbc055d1e',
        externalId: undefined,
        name: '📃 Related outcomes overwrite',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📦 Resources (asset)
       */
      resources: {
        codename: 'resources',
        id: '2e5587d9-8e92-4c6f-ac85-86741ed25130',
        externalId: undefined,
        name: '📦 Resources',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '94a5a565-62d2-4342-9de9-055837cd4d51',
        externalId: undefined,
        name: '📒 Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * 📜 Description (text)
       */
      seo_description: {
        codename: 'seo_description',
        id: '39e97a54-ee05-4ef0-957f-303fb6dfbf27',
        externalId: undefined,
        name: '📜 Description',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📈 Focus Area Continuum Group (modular_content)
       */
      continuumgroups: {
        codename: 'continuumgroups',
        id: 'c601b14a-fe88-40d0-8506-197994c19dc0',
        externalId: undefined,
        name: '📈 Focus Area Continuum Group',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page Teaching Advice
   * Last modified: Thu Jun 30 2022 01:57:51 GMT+0000 (Coordinated Universal Time)
   */
  wp_teachingadvice: {
    codename: 'wp_teachingadvice',
    id: 'ac3b3e04-90e5-498c-8d74-c4eb377e472a',
    externalId: undefined,
    name: '🌐 Web Page Teaching Advice',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '180c1524-8de0-4298-8ca3-6c36f3bc0cb2',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: 'e4e7ed12-1c95-439d-9a71-1044d1b00347',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: '84967834-6502-40d2-aea3-c1c38ff9f363',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       */
      redirect_url: {
        codename: 'redirect_url',
        id: '8d0c41df-ab35-4319-9a0d-abdf355d280b',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📜 Content Block
   * Last modified: Tue Jun 04 2024 06:11:09 GMT+0000 (Coordinated Universal Time)
   */
  contentblock: {
    codename: 'contentblock',
    id: '25c609ed-88c5-4765-be24-6430c36c3652',
    externalId: undefined,
    name: '📜 Content Block',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '919343da-01f8-4d85-b4f0-48ccf64f7a90',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Sub Title (text)
       */
      sub_title: {
        codename: 'sub_title',
        id: 'f5cf990b-ed02-4fcc-96b1-fef9253e73fa',
        externalId: undefined,
        name: '📜 Sub Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '443af4a6-6a88-4844-9d8a-763ec8c72713',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📦 Image (asset)
       */
      image: {
        codename: 'image',
        id: 'c6187010-5f17-448c-8805-9246c3f8fd5a',
        externalId: undefined,
        name: '📦 Image',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * More Info Link (modular_content)
       */
      more_info_link: {
        codename: 'more_info_link',
        id: 'cae29bb5-e9fb-439d-9603-e4404fb43452',
        externalId: undefined,
        name: 'More Info Link',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page Search
   * Last modified: Sun Mar 05 2023 23:30:54 GMT+0000 (Coordinated Universal Time)
   */
  wp_search: {
    codename: 'wp_search',
    id: 'dec34edf-5ead-46db-a695-81ca7691e4cd',
    externalId: undefined,
    name: '🌐 Web Page Search',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '183d0634-308a-4461-9b6a-c8edce62a497',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: 'eff84555-2eed-4757-986e-3001f9d4d43f',
        externalId: undefined,
        name: '🌐 Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '315d9901-5038-42eb-8be5-dcabd31b9d3a',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: 'ba2f389f-30c6-4017-ad72-04fb24704281',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       *
       * Relative or abosute url
       */
      redirect_url: {
        codename: 'redirect_url',
        id: '1a03e9d1-85ff-4826-8993-96e6624311c5',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page ACE Landing
   * Last modified: Wed Mar 22 2023 04:57:09 GMT+0000 (Coordinated Universal Time)
   */
  wp_ace_landing: {
    codename: 'wp_ace_landing',
    id: '5209babf-47a8-48de-a59c-b9e804557656',
    externalId: undefined,
    name: '🌐 Web Page ACE Landing',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '0f2e7b48-497f-4f9e-869d-235471c1e5c2',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: '3417d751-54dd-4f44-bc2d-7331d56face3',
        externalId: undefined,
        name: '🌐 Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '069fb944-35eb-4445-a2a9-34e09cb39b6f',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: 'e61dbc8c-da06-4df9-83ba-11567ec414ef',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       *
       * Relative or abosute url
       */
      redirect_url: {
        codename: 'redirect_url',
        id: '223e2dd2-7ad4-4b9f-9d61-6c915e9b8d86',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page Stage Group
   * Last modified: Thu May 09 2024 01:17:09 GMT+0000 (Coordinated Universal Time)
   */
  wp_stagegroup: {
    codename: 'wp_stagegroup',
    id: 'fa1d7210-fafb-4fa2-a5f6-2fa8734a74bf',
    externalId: undefined,
    name: '🌐 Web Page Stage Group',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '754765ad-5d60-42c9-977f-e3869046a948',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: 'f5bc87fd-0ca8-4ece-94e1-99318a984957',
        externalId: undefined,
        name: '🌐 Subpages',
        required: true,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 📒 Syllabuses (modular_content)
       */
      syllabuses: {
        codename: 'syllabuses',
        id: '0d61cbf2-1d14-4911-b347-b33db984941c',
        externalId: undefined,
        name: '📒 Syllabuses',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '54511cf9-e768-4f1b-aaa8-9752590d1a5d',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },
    },
  },

  /**
   * 🛠️ Redirect rule
   * Last modified: Thu Jun 06 2024 03:39:16 GMT+0000 (Coordinated Universal Time)
   */
  redirectrule: {
    codename: 'redirectrule',
    id: '25146624-dbb8-4c20-9f84-4889afeb55dd',
    externalId: undefined,
    name: '🛠️ Redirect rule',
    elements: {
      /**
       * 📜 From (text)
       *
       * ⚠️ Note that for Focus Area and Syllabus codename redirects, the old codename will be converted to slug format e.g. "hmsls_11_12_ls_fa_coll" will be used as "hmsls-11-12-ls-fa-coll"
       */
      from: {
        codename: 'from',
        id: 'bb4cb194-9dbb-4ddd-afd2-34de3ac4d547',
        externalId: undefined,
        name: '📜 From',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 To (text)
       */
      to: {
        codename: 'to',
        id: '33767944-68ea-43a1-bbcc-98294fed50d3',
        externalId: undefined,
        name: '📜 To',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Type (multiple_choice)
       */
      type: {
        codename: 'type',
        id: '5e5b31a8-d686-4e45-9498-aa21a6e63be2',
        externalId: undefined,
        name: '⚙️ Type',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '53d183dd-41d3-4f13-88b3-3e2d33cbb455',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📃🔹Focus Area
   * Last modified: Mon Jul 01 2024 04:14:46 GMT+0000 (Coordinated Universal Time)
   */
  focusarea: {
    codename: 'focusarea',
    id: '94ae9246-c92e-4c6d-bb49-8d10d38c69b9',
    externalId: undefined,
    name: '📃🔹Focus Area',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'ce77b234-2ba1-41b0-a675-1d7d3ee46e06',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'd9b05050-9716-4fbc-9c52-96864d0c3019',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content stage specific (modular_content)
       */
      content_staged: {
        codename: 'content_staged',
        id: 'bc0cf777-ff44-4800-aa43-367938036b8b',
        externalId: undefined,
        name: '📜 Content stage specific',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📑 Teaching Advice (modular_content)
       */
      teachingadvice: {
        codename: 'teachingadvice',
        id: 'd0bbb364-b8b6-4c10-a848-1baa34e407d1',
        externalId: undefined,
        name: '📑 Teaching Advice',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃🔹Related Focus Areas (modular_content)
       *
       * Related Mainstream / Life Skills Focus Area
       */
      related_focusareas: {
        codename: 'related_focusareas',
        id: 'ac011366-a74a-4dab-97a5-9fd52fc61536',
        externalId: undefined,
        name: '📃🔹Related Focus Areas',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📈 Focus Area Continuum Group (modular_content)
       */
      continuumgroups: {
        codename: 'continuumgroups',
        id: '96731262-4856-4622-84e6-22e88a8fdfb5',
        externalId: undefined,
        name: '📈 Focus Area Continuum Group',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Access Points Content (modular_content)
       */
      accesspointcontent: {
        codename: 'accesspointcontent',
        id: 'bf8b893f-49a3-46e2-8720-199d940c1e01',
        externalId: undefined,
        name: '📜 Access Points Content',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📑 Access Points Content Groups (modular_content)
       *
       * Since Access Points have the same structure, we're using Focus Area Group instead
       */
      accesspointgroups: {
        codename: 'accesspointgroups',
        id: 'aac3977a-7c91-4d93-ad35-4a036a7d571b',
        externalId: undefined,
        name: '📑 Access Points Content Groups',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📑 Content Groups (modular_content)
       */
      contentgroups: {
        codename: 'contentgroups',
        id: 'eb589c21-8e06-4a10-b2f6-7f87e4e9e140',
        externalId: undefined,
        name: '📑 Content Groups',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Outcomes (modular_content)
       */
      outcomes: {
        codename: 'outcomes',
        id: '859ac961-3433-4e0a-b3ed-f56da2755a3b',
        externalId: undefined,
        name: '📃 Outcomes',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📃 Related outcomes overwrite (modular_content)
       *
       * ⚠️ Used for PRINT only
       */
      related_outcomes: {
        codename: 'related_outcomes',
        id: '3910ce2f-fbde-48ad-9795-0d266a5db529',
        externalId: undefined,
        name: '📃 Related outcomes overwrite',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📦 Resources (asset)
       */
      resources: {
        codename: 'resources',
        id: '9ca99dc9-0cb7-4f72-bcba-24aca598d000',
        externalId: undefined,
        name: '📦 Resources',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * 📜 Code (text)
       *
       * ⚠️ Not used
       */
      code: {
        codename: 'code',
        id: '6282a0b7-9e2d-4a5b-b10e-70c9a82fa49b',
        externalId: undefined,
        name: '📜 Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: 'b74bf81f-5a81-4779-a35c-53c4a03fb281',
        externalId: undefined,
        name: '📒 Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * 📚 Course (modular_content)
       */
      course: {
        codename: 'course',
        id: 'c6918742-13c8-434d-af1a-328d03500df0',
        externalId: undefined,
        name: '📚 Course',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description (text)
       */
      seo_description: {
        codename: 'seo_description',
        id: '07c153d9-3c2f-4097-86d9-f486afd4e52d',
        externalId: undefined,
        name: '📜 Description',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🗃️ ACE Subgroup
   * Last modified: Tue Jun 04 2024 06:28:09 GMT+0000 (Coordinated Universal Time)
   */
  ace_subgroup: {
    codename: 'ace_subgroup',
    id: '2fa95097-8fe3-4727-9a03-4d6645069298',
    externalId: undefined,
    name: '🗃️ ACE Subgroup',
    elements: {
      /**
       * 📜 Code (text)
       */
      code: {
        codename: 'code',
        id: 'd2cc31e6-b650-43a8-b897-506675e6ec25',
        externalId: undefined,
        name: '📜 Code',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '98466976-478c-4e81-832d-12b9b2f4d9c0',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📆 Last updated date (date_time)
       */
      lastupdateddate: {
        codename: 'lastupdateddate',
        id: '660c77c5-88c2-4f23-9b24-0c9ee791d881',
        externalId: undefined,
        name: '📆 Last updated date',
        required: true,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📜 Legislative Basis (rich_text)
       */
      legislativebasis: {
        codename: 'legislativebasis',
        id: '275c16f3-ec32-4a94-92d7-c2a9a45f1573',
        externalId: undefined,
        name: '📜 Legislative Basis',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Preamble Title (text)
       */
      preamble_title: {
        codename: 'preamble_title',
        id: '09b6a71d-42ee-45f3-952c-2bceac37f06c',
        externalId: undefined,
        name: '📜 Preamble Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Preamble Content (rich_text)
       *
       * Preamble text that applies to the entire subgroup
       */
      preamble_content: {
        codename: 'preamble_content',
        id: '86fb1b9c-30c4-4a8f-bc1a-25f474ea470c',
        externalId: undefined,
        name: '📜 Preamble Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 🗂️ Rules (modular_content)
       */
      rules: {
        codename: 'rules',
        id: 'b1717461-1004-4bae-89c7-0733bd5b00c7',
        externalId: undefined,
        name: '🗂️ Rules',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 💬 Footnotes (modular_content)
       *
       * Subgroup preamble footnotes
       */
      footnotes: {
        codename: 'footnotes',
        id: '2c24495d-9fb9-4072-94c3-410094c4a154',
        externalId: undefined,
        name: '💬 Footnotes',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔠 Glossary (modular_content)
       */
      glossary: {
        codename: 'glossary',
        id: '9ad6a63c-5ea4-4113-8f6d-206bce9a25d3',
        externalId: undefined,
        name: '🔠 Glossary',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * Stage (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application.
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '9435534d-8938-4207-89ce-91a131f49561',
        externalId: undefined,
        name: '🏷️ Stages',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '5acbf85b-35c2-4299-af12-bd79673cff09',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application. If this rule applies to all syllabuses, select all Key Learning Areas.
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: '5b72b340-111b-464d-adea-c914fdc620f5',
        externalId: undefined,
        name: '🏷️ Key learning area',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🚩 Change note - DC KLA/Stage
   * Last modified: Tue Jun 04 2024 06:25:39 GMT+0000 (Coordinated Universal Time)
   */
  releasenote_syllabus_kla: {
    codename: 'releasenote_syllabus_kla',
    id: '10e305e7-4cdf-4576-8908-c83f23346ee9',
    externalId: undefined,
    name: '🚩 Change note - DC KLA/Stage',
    elements: {
      /**
       * 📅 Release date (date_time)
       */
      releasedate: {
        codename: 'releasedate',
        id: 'd2c49aaf-d412-4cdb-8b2e-e8815c79bbcd',
        externalId: undefined,
        name: '📅 Release date',
        required: true,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'd436c6d0-faa0-4837-b5a4-8bca488c5025',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'c496c3f1-dbe4-42db-875d-6cd8dd734dae',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'edda6e46-878f-420a-adea-9597f1abd0df',
        externalId: undefined,
        name: '🏷️ Stage',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: 'c19a1caf-1952-47af-a712-333a20de23f0',
        externalId: undefined,
        name: '🏷️ Stage Year',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: 'd4be49ed-5b70-4bf1-8396-c4c4ba8a1877',
        externalId: undefined,
        name: 'Key Learning Area',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'key_learning_area',
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '2cf2a5f3-7f64-42b7-b8d9-0a228939bfeb',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page Custom View
   * Last modified: Wed Jul 20 2022 10:05:41 GMT+0000 (Coordinated Universal Time)
   */
  wp_custom_view: {
    codename: 'wp_custom_view',
    id: '211fd9e6-a8ab-486f-8ba5-e776b5cc8d97',
    externalId: undefined,
    name: '🌐 Web Page Custom View',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'cd776aba-8c2a-4fee-879c-1361db43a895',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: 'e476a88e-ca42-43f3-afa1-dd0d73acb307',
        externalId: undefined,
        name: 'Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '538a7831-0cde-454a-bcbf-ee64127be750',
        externalId: undefined,
        name: 'Slug',
        required: false,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: '9e9bdf35-3e80-4e53-8e27-ad33dc345eb5',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       */
      redirect_url: {
        codename: 'redirect_url',
        id: '6f38d267-362b-4f6d-9afa-9d391f987cf2',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🚩 Change note - DC Syllabus
   * Last modified: Tue Jun 04 2024 06:25:19 GMT+0000 (Coordinated Universal Time)
   */
  releasenote_syllabus: {
    codename: 'releasenote_syllabus',
    id: 'c1e80fa8-ce7b-4e74-888c-d495302c0e2b',
    externalId: undefined,
    name: '🚩 Change note - DC Syllabus',
    elements: {
      /**
       * 📅 Release date (date_time)
       */
      releasedate: {
        codename: 'releasedate',
        id: '0e6b6181-7669-499e-a6ec-48894bba59e8',
        externalId: undefined,
        name: '📅 Release date',
        required: true,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'b8b62d34-531a-4cc2-ae24-119b420543d1',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '4f8c75f6-11c1-4934-bb7a-796b86c99080',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: 'da88dd74-ee98-40d3-8d80-2e5a822e82b9',
        externalId: undefined,
        name: '🏷️ Syllabuse',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '7697415f-ce5e-44b6-9df6-4cd35ab92f65',
        externalId: undefined,
        name: '🏷️ Stage',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '6f2c9d3f-d2a2-45c1-8d7a-d63d64cbcfef',
        externalId: undefined,
        name: '🏷️ Stage Year',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: 'd4be49ed-5b70-4bf1-8396-c4c4ba8a1877',
        externalId: undefined,
        name: 'Key Learning Area',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'key_learning_area',
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '9cb5df51-5046-471e-9f78-f9d97f86f1b7',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📂 Collection Syllabuses
   * Last modified: Thu Jun 30 2022 02:07:32 GMT+0000 (Coordinated Universal Time)
   */
  collection_syllabus: {
    codename: 'collection_syllabus',
    id: 'a67388ef-d0bf-44dc-8966-9d4294001b2f',
    externalId: undefined,
    name: '📂 Collection Syllabuses',
    elements: {
      /**
       * Items (modular_content)
       */
      items: {
        codename: 'items',
        id: '962ba352-b8a1-4236-a28b-67d06f7e1519',
        externalId: undefined,
        name: 'Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🖇️ Link Teaching Advice - Curriculum Connections
   * Last modified: Thu Jan 11 2024 03:28:20 GMT+0000 (Coordinated Universal Time)
   */
  web_link_cc_teachingadvice: {
    codename: 'web_link_cc_teachingadvice',
    id: 'f7328f83-8722-407b-baf8-7d78171ecfe9',
    externalId: undefined,
    name: '🖇️ Link Teaching Advice - Curriculum Connections',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '7d51764e-3df1-4de8-838d-6d86d629401f',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: 'de61d510-0c41-4759-8a61-2a9a77a9899a',
        externalId: undefined,
        name: '🔗 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: 'a541bcbc-62df-44bb-8355-48655098667c',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * Stage (taxonomy)
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '3de23693-058f-4d9f-be98-a0eb0e91015f',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * ⚠️ Select 2 years within the stage for K-10 syllabuses and only 1 year for Senior syllabuses.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '92a37e6b-727d-423d-8b45-019506c107ae',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔢 ACE Ordered list paragraph
   * Last modified: Tue Jun 04 2024 06:29:54 GMT+0000 (Coordinated Universal Time)
   */
  ace_paragraph: {
    codename: 'ace_paragraph',
    id: '20a27a6c-cfaf-4306-8df2-d30c9eaf966f',
    externalId: undefined,
    name: '🔢 ACE Ordered list paragraph',
    elements: {
      /**
       * ⚙️ Internal title (text)
       *
       * ⚠️ CMS field only, not used by the web application.
       */
      internal_title: {
        codename: 'internal_title',
        id: '3da9c124-6239-44b5-9533-084a0c679f32',
        externalId: undefined,
        name: '⚙️ Internal title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔢 Ordered list - Start from (number)
       */
      orderedliststartfrom: {
        codename: 'orderedliststartfrom',
        id: '752a5328-9315-4255-82bb-009e6f994464',
        externalId: undefined,
        name: '🔢 Ordered list - Start from',
        required: true,
        type: 'number',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'f2acd898-4d2a-49bd-8271-bf47d965fff1',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page ACE Recent Changes
   * Last modified: Wed Mar 15 2023 02:10:25 GMT+0000 (Coordinated Universal Time)
   */
  wp_ace_recentchanges: {
    codename: 'wp_ace_recentchanges',
    id: '55b2f19a-b494-4ab1-95f2-c761514d0c55',
    externalId: undefined,
    name: '🌐 Web Page ACE Recent Changes',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'c8acc234-21f6-411b-8392-2b46eea71201',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: 'f8219ee7-c158-40ec-a99f-a845907ec3e3',
        externalId: undefined,
        name: '🌐 Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 🌐 Left navigation links (modular_content)
       */
      left_navigation: {
        codename: 'left_navigation',
        id: '40fbaf9e-930b-4e50-bf81-d242d5eaa72f',
        externalId: undefined,
        name: '🌐 Left navigation links',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '4dd4ed28-ba98-4a44-b72a-c386b454df52',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: 'eb4e2f69-659c-44a6-9c55-8811710c4368',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       *
       * Relative or abosute url
       */
      redirect_url: {
        codename: 'redirect_url',
        id: '78622a69-7f13-413d-b8f9-f385ba72c107',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📨 ACE Contact
   * Last modified: Wed Jan 18 2023 14:05:52 GMT+0000 (Coordinated Universal Time)
   */
  ace_contact: {
    codename: 'ace_contact',
    id: '8424b28b-2923-4bf7-91cd-87e32fd4e58c',
    externalId: undefined,
    name: '📨 ACE Contact',
    elements: {
      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '399f1cb0-c80b-409e-a098-7aa32fe2a607',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🚩 Change note - ACE syllabuses
   * Last modified: Tue Jun 04 2024 06:25:55 GMT+0000 (Coordinated Universal Time)
   */
  releasenote_ace_syllabus: {
    codename: 'releasenote_ace_syllabus',
    id: '335e7af1-9871-40ac-921a-008ea2191f3a',
    externalId: undefined,
    name: '🚩 Change note - ACE syllabuses',
    elements: {
      /**
       * 📅 Release date (date_time)
       */
      releasedate: {
        codename: 'releasedate',
        id: '5d45a9c8-bab8-4a53-90e3-eb06e1bf7dd9',
        externalId: undefined,
        name: '📅 Release date',
        required: true,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'e6781c98-ea97-4c21-a25f-75e41a4d0a67',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🗃️ Subgroup (modular_content)
       */
      subgroup: {
        codename: 'subgroup',
        id: '09d1e5ac-1894-4175-acd9-87d20cd04b3d',
        externalId: undefined,
        name: '🗃️ Subgroup',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: 'b1d16c68-3138-4e6d-b593-f74c5fe02138',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       *
       * Select syllabuses affected by this ACE Rule(s) update.
       */
      syllabuses: {
        codename: 'syllabuses',
        id: '531990f9-c43a-4df2-a83a-75358087eef9',
        externalId: undefined,
        name: '🏷️ Syllabuses',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       *
       * Select Stages affected by this ACE Rule(s) update.
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '5f1a879a-3433-49b2-a0b5-9ede880944a9',
        externalId: undefined,
        name: '🏷️ Stage',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * Select Stage Years affected by this ACE Rule(s) update.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '7e744731-4806-4823-824b-eef8c146240e',
        externalId: undefined,
        name: '🏷️ Stage Year',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       *
       * Select Key Learning Areas affected by this ACE Rule(s) update.
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: '817b70d9-d5d8-49d2-91c3-c3217a850141',
        externalId: undefined,
        name: '🏷️ Key learning area',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '8d4a31e5-27f6-4346-9c55-0a7eb1a6c0d3',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📜 Content - Teaching Advice
   * Last modified: Tue Jun 04 2024 06:10:41 GMT+0000 (Coordinated Universal Time)
   */
  content_teachingadvice: {
    codename: 'content_teachingadvice',
    id: 'a1941724-ef26-4b35-acc7-792097dea484',
    externalId: undefined,
    name: '📜 Content - Teaching Advice',
    elements: {
      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '1dbe5ac4-f42f-4f4e-b22f-481c681fe2fa',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      stages: {
        codename: 'stages',
        id: '46dcf5c4-8432-465f-b8dd-db01bf82c2f7',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       */
      stage_years: {
        codename: 'stage_years',
        id: '6ed715e2-19b7-4adc-8b0e-b7147530c9ac',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: 'e8b7c292-da19-4f08-9017-c2c5573cb775',
        externalId: undefined,
        name: '🏷️ Content Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application
       */
      syllabus: {
        codename: 'syllabus',
        id: '8d28545d-de2f-4799-a8f6-b379bf78cf08',
        externalId: undefined,
        name: '🔖 Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI In-page alert
   * Last modified: Thu Jun 30 2022 03:27:18 GMT+0000 (Coordinated Universal Time)
   */
  ui_inpagealert: {
    codename: 'ui_inpagealert',
    id: '2e9d7500-12ad-4076-b903-d2530967db67',
    externalId: undefined,
    name: '💠 Web UI In-page alert',
    elements: {
      /**
       * 📜 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: 'c220adf0-ab52-4470-bf39-64b42fb88605',
        externalId: undefined,
        name: '📜 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Type (multiple_choice)
       */
      type: {
        codename: 'type',
        id: '4e72554c-20d9-4573-ab43-d012400621d6',
        externalId: undefined,
        name: '⚙️ Type',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Display (multiple_choice)
       */
      display: {
        codename: 'display',
        id: 'f54e8ec0-f039-4687-95a2-450e727511f5',
        externalId: undefined,
        name: '⚙️ Display',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠Web UI Link List
   * Last modified: Thu Oct 13 2022 03:13:53 GMT+0000 (Coordinated Universal Time)
   */
  ui_link_list: {
    codename: 'ui_link_list',
    id: '8a14a616-1a42-4eff-9201-7dfd9a5df234',
    externalId: undefined,
    name: '💠Web UI Link List',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'a7754896-2160-4104-857a-9a03d68cd8b3',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📂 Items (modular_content)
       */
      items: {
        codename: 'items',
        id: '732e7560-c6b2-4d02-a279-8c66eb17c0cb',
        externalId: undefined,
        name: '📂 Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Columns (multiple_choice)
       */
      columns: {
        codename: 'columns',
        id: '3d85c580-e788-4856-9458-37c663c98106',
        externalId: undefined,
        name: '⚙️ Columns',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Hero banner
   * Last modified: Thu Jun 30 2022 03:32:47 GMT+0000 (Coordinated Universal Time)
   */
  ui_herobanner: {
    codename: 'ui_herobanner',
    id: 'a0d46085-0884-4941-90d4-3597e57065b6',
    externalId: undefined,
    name: '💠 Web UI Hero banner',
    elements: {
      /**
       * 📜 Items (modular_content)
       */
      items: {
        codename: 'items',
        id: 'd0a89471-0539-4199-8c62-46e9c784f532',
        externalId: undefined,
        name: '📜 Items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * ⚙️ Display (multiple_choice)
       */
      display: {
        codename: 'display',
        id: 'aeae5c7c-7faf-4a2d-8b01-9f85e11fe002',
        externalId: undefined,
        name: '⚙️ Display',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🎨 Theme (multiple_choice)
       */
      theme: {
        codename: 'theme',
        id: '11a37fb8-8af8-4b8f-97ea-08396878854c',
        externalId: undefined,
        name: '🎨 Theme',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔗 Link Focus Area
   * Last modified: Fri Aug 30 2024 04:28:14 GMT+0000 (Coordinated Universal Time)
   */
  web_link_focusarea: {
    codename: 'web_link_focusarea',
    id: '46a9656d-c6eb-40e7-939f-87a5adc887c2',
    externalId: undefined,
    name: '🔗 Link Focus Area',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'f06b42f3-4ed5-4cff-bafb-abe256bc0c11',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: 'a5e43ebb-cc37-47af-9268-f425b92f2645',
        externalId: undefined,
        name: '🔗 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '23116447-d94a-4c7a-8dca-87c67afd24f7',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * Stage (taxonomy)
       *
       * ⚠️ For mainstream links select 1 stage only.For secondary Life Skills links, select both Stage 4 and Stage 5.
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '2fb2e181-818a-4cd5-ac04-786f20dc027e',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * ⚠️ Select 2 years within the stage for K-10 syllabuses and only 1 year for senior syllabuses. For secondary Life Skills links, select years from both Stage 4 and Stage 5.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '32b3d419-0757-4cfd-8d45-c49fd448452a',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page Stages
   * Last modified: Tue Sep 27 2022 08:18:01 GMT+0000 (Coordinated Universal Time)
   */
  wp_stages: {
    codename: 'wp_stages',
    id: '720a7f16-cbe0-454a-b986-dfcc9fa1c05c',
    externalId: undefined,
    name: '🌐 Web Page Stages',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'f7cadda4-8c47-4246-aacd-8397a9d5cdc6',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: '008cf716-4686-45f9-8117-16315e761511',
        externalId: undefined,
        name: '🌐 Subpages',
        required: true,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 💠 Links (modular_content)
       */
      links: {
        codename: 'links',
        id: 'ae31f749-76c8-4df1-b36e-93fbd1216eb2',
        externalId: undefined,
        name: '💠 Links',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: 'a62cc8c7-0df1-4a89-a111-50bdab1338a6',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },
    },
  },

  /**
   * 📃 Assessment Group (Band or Grade)
   * Last modified: Tue Jun 04 2024 06:11:55 GMT+0000 (Coordinated Universal Time)
   */
  syllabus_assessment_group: {
    codename: 'syllabus_assessment_group',
    id: '43d4ddab-a156-49ba-8fbb-0cb8d5b4dc6a',
    externalId: undefined,
    name: '📃 Assessment Group (Band or Grade)',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '759df61d-ffe0-4aae-9318-c84db011ee5c',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '50e3a7da-f570-4065-ad80-e027ae9ef2fb',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📃 Assessment content items (modular_content)
       */
      contentitems: {
        codename: 'contentitems',
        id: '7faa4715-81e4-43e7-8227-d81d9d34bb58',
        externalId: undefined,
        name: '📃 Assessment content items',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Code (text)
       */
      code: {
        codename: 'code',
        id: 'b0c12e92-620d-487f-a58a-d466db35d1aa',
        externalId: undefined,
        name: 'Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area: {
        codename: 'key_learning_area',
        id: '4bdbda5a-97ee-4977-9836-f1e578ea9003',
        externalId: undefined,
        name: '🏷️ Key learning area',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '00fcd23e-46f7-484b-bb75-9b27e602a615',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Assessment Grades (taxonomy)
       */
      assessment_grades: {
        codename: 'assessment_grades',
        id: '27fcce52-b319-4ad7-b558-f7591be252bf',
        externalId: undefined,
        name: '🏷️ Assessment Grades',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 📃 Outcome
   * Last modified: Mon Apr 22 2024 04:30:39 GMT+0000 (Coordinated Universal Time)
   */
  outcome: {
    codename: 'outcome',
    id: '9a898783-88a9-4e0e-86bc-23fbd7989186',
    externalId: undefined,
    name: '📃 Outcome',
    elements: {
      /**
       * 📜 Code (text)
       */
      code: {
        codename: 'code',
        id: '3611f600-e9a9-4be8-aef9-742fbb77b435',
        externalId: undefined,
        name: '📜 Code',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '421c0083-7b3d-45e4-a248-079a7a26419f',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Description (rich_text)
       */
      description: {
        codename: 'description',
        id: '127e9a4b-7221-46ce-82aa-00bc450118bc',
        externalId: undefined,
        name: '📜 Description',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '556ad86a-5af9-493a-92f4-514ca1a39360',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * ⚙️ Is Overarching (multiple_choice)
       */
      isoverarching: {
        codename: 'isoverarching',
        id: 'ec1e469d-da2e-4b7f-b89b-e4abdbb08977',
        externalId: undefined,
        name: '⚙️ Is Overarching',
        required: true,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 📃 Related outcomes (modular_content)
       */
      relatedlifeskillsoutcomes: {
        codename: 'relatedlifeskillsoutcomes',
        id: '5836280b-a5a5-47e3-abdc-23a48eaf1142',
        externalId: undefined,
        name: '📃 Related outcomes',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🗂️ ACE Rule
   * Last modified: Tue Aug 06 2024 06:35:13 GMT+0000 (Coordinated Universal Time)
   */
  ace_rule: {
    codename: 'ace_rule',
    id: '4335c1f9-93c9-485e-9408-2effe71e8ba1',
    externalId: undefined,
    name: '🗂️ ACE Rule',
    elements: {
      /**
       * 📜 Code (text)
       *
       * ACE rule numbers, or future numbering system
       */
      code: {
        codename: 'code',
        id: '8c42beaa-226f-4cd3-b66f-0955da097a96',
        externalId: undefined,
        name: '📜 Code',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Rule Title (text)
       *
       * Title of the rule
       */
      title: {
        codename: 'title',
        id: '49dfb32e-0bee-42cb-bf2b-c13e69d7d3e5',
        externalId: undefined,
        name: '📜 Rule Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Preamble Title (text)
       */
      preamble_title: {
        codename: 'preamble_title',
        id: '6cd548b6-932f-43fc-a462-e127fc4d4245',
        externalId: undefined,
        name: '📜 Preamble Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Preamble Content (rich_text)
       */
      preamble_content: {
        codename: 'preamble_content',
        id: '909da403-64e6-4ac6-97fd-d2c7cf3bff83',
        externalId: undefined,
        name: '📜 Preamble Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📑 Rule content items (modular_content)
       */
      items: {
        codename: 'items',
        id: 'a6fbc87c-da84-4742-8e85-f62ba0db1acd',
        externalId: undefined,
        name: '📑 Rule content items',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📦 Resources (asset)
       */
      resources: {
        codename: 'resources',
        id: '463dfa09-1ada-491a-b3ea-347de1ed3345',
        externalId: undefined,
        name: '📦 Resources',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * 📦 Resources other (modular_content)
       */
      resources_other: {
        codename: 'resources_other',
        id: 'e76670e7-04e9-493e-933a-9550d2391a3d',
        externalId: undefined,
        name: '📦 Resources other',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🗂️ Related rules (modular_content)
       */
      relatedrules: {
        codename: 'relatedrules',
        id: '519e5a32-3b67-4b7d-82be-023b5536171a',
        externalId: undefined,
        name: '🗂️ Related rules',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📨 Contact (modular_content)
       */
      contact: {
        codename: 'contact',
        id: '3cc85200-ebd8-47c4-be7c-7a678acd7163',
        externalId: undefined,
        name: '📨 Contact',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 💬 Footnotes (modular_content)
       */
      footnotes: {
        codename: 'footnotes',
        id: '9007d7b0-e885-4385-83f4-99a102874eed',
        externalId: undefined,
        name: '💬 Footnotes',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📜 Keywords (text)
       */
      seo_keywords: {
        codename: 'seo_keywords',
        id: 'fd59484c-0fd7-4601-a8db-81d810e2847b',
        externalId: undefined,
        name: '📜 Keywords',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * ACE Category (taxonomy)
       */
      ace_category: {
        codename: 'ace_category',
        id: '8ae39783-f004-4972-8282-cac7c065706f',
        externalId: undefined,
        name: '🏷️ ACE Category',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application.
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '5b54084a-b61f-41d7-8be4-ef25a6173f84',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '2480c4e5-449d-4b28-9ec3-f54d1fbf5f7c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Key learning area (taxonomy)
       *
       * ⚠️ CMS field only, not used by the web application. If this rule applies to all syllabuses, select all Key Learning Areas.
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: 'd702c5b4-bd6e-4500-a600-5f80229d5a97',
        externalId: undefined,
        name: '🏷️ Key learning area',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabuses_include__items: {
        codename: 'syllabuses_include__items',
        id: 'ad518b46-6097-4eba-962e-018290102998',
        externalId: undefined,
        name: 'Syllabus',
        required: false,
        type: 'taxonomy',
        snippetCodename: 'syllabuses_include',
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabuses_exclude__items: {
        codename: 'syllabuses_exclude__items',
        id: 'a21fcda4-8cdc-4ed9-9758-304431b0a0c8',
        externalId: undefined,
        name: '🏷️ Exclude syllabuses',
        required: false,
        type: 'taxonomy',
        snippetCodename: 'syllabuses_exclude',
      },
    },
  },

  /**
   * 🚩 Change note - General
   * Last modified: Tue Jun 04 2024 06:25:09 GMT+0000 (Coordinated Universal Time)
   */
  releasenote_general: {
    codename: 'releasenote_general',
    id: '074414b8-fd4b-4768-bcee-6cf84fd56a08',
    externalId: undefined,
    name: '🚩 Change note - General',
    elements: {
      /**
       * 📅 Release date (date_time)
       */
      releasedate: {
        codename: 'releasedate',
        id: 'de5a9d76-6fe7-4d5a-93f9-a89abeb0c36b',
        externalId: undefined,
        name: '📅 Release date',
        required: true,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '720deda9-66c2-4cdc-8927-ebbd316361c0',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '17f441fe-0354-4c43-9014-479aae35225a',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * Stage (taxonomy)
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '8f9147a5-8c75-4f77-9db6-41a133cfbe8f',
        externalId: undefined,
        name: '🏷️ Stage',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '08a5fdd6-133f-4470-bf8f-8017bf653588',
        externalId: undefined,
        name: '🏷️ Stage Year',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: 'b3d44356-1a38-4a7c-a587-a613a0bd1519',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔗 Link Teaching Advice
   * Last modified: Thu Jan 11 2024 03:21:18 GMT+0000 (Coordinated Universal Time)
   */
  web_link_teachingadvice: {
    codename: 'web_link_teachingadvice',
    id: 'ba38dbc5-ac62-4c68-9b17-fe1e4074821e',
    externalId: undefined,
    name: '🔗 Link Teaching Advice',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '15707d08-7545-416c-9258-7c514297201c',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Item (modular_content)
       */
      item: {
        codename: 'item',
        id: '5955b29d-b303-48aa-83af-029cc7e90970',
        externalId: undefined,
        name: '🔗 Item',
        required: true,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: 'c9e5a35d-78e0-4a00-be77-692327610ed8',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       *
       * Please select the Syllabus type for this resource
       */
      syllabus_type__items: {
        codename: 'syllabus_type__items',
        id: '132af31b-4f0d-4c71-ba94-bd0175566543',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'syllabus_type',
      },

      /**
       * Stage (taxonomy)
       *
       * ⚠️ For mainstream links select 1 stage only.For secondary Life Skills links, select both Stage 4 and Stage 5.
       */
      stages__stages: {
        codename: 'stages__stages',
        id: '85c18137-9693-461f-8ca6-9b026cf989c3',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Year (taxonomy)
       *
       * ⚠️ Select 2 years within the stage for K-10 syllabuses and only 1 year for senior syllabuses. For secondary Life Skills links, select years from both Stage 4 and Stage 5.
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '944bdfd3-f215-45b4-bf29-f76552e8c703',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💬 ACE Footnote
   * Last modified: Wed Jan 18 2023 13:48:04 GMT+0000 (Coordinated Universal Time)
   */
  ace_footnote: {
    codename: 'ace_footnote',
    id: '170b8328-5e49-422c-a881-16d6858e70e2',
    externalId: undefined,
    name: '💬 ACE Footnote',
    elements: {
      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '4ad7b4a4-5444-49f6-90db-864145f73db9',
        externalId: undefined,
        name: '📜 Content',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page Glossary
   * Last modified: Thu Jun 30 2022 01:55:43 GMT+0000 (Coordinated Universal Time)
   */
  wp_glossary: {
    codename: 'wp_glossary',
    id: 'd8ab15c7-2b8b-42c9-9c3d-b97af6d45d0d',
    externalId: undefined,
    name: '🌐 Web Page Glossary',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '5436afa8-13ac-417e-bdd5-b3e733923b47',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: '52fd7578-72e4-4c4a-b35f-6c7a5f359ae3',
        externalId: undefined,
        name: '🌐 Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: '4f3420e0-c9a3-442b-b618-984de87d4677',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: '5cf7e5cb-6831-4bcd-99ed-8e5a7df6c3b0',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       */
      redirect_url: {
        codename: 'redirect_url',
        id: 'd0155963-b179-4341-8894-7c42c2fda4cf',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🌐 Web Page ACE Contact Us
   * Last modified: Wed Mar 15 2023 02:09:28 GMT+0000 (Coordinated Universal Time)
   */
  wp_ace_contact: {
    codename: 'wp_ace_contact',
    id: 'eca06cac-eeaa-4c4b-a2a3-9075c7ba05a2',
    externalId: undefined,
    name: '🌐 Web Page ACE Contact Us',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '7bb9f56f-aefd-4c29-b7e8-868c296f64e7',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      web_content_rtb__content: {
        codename: 'web_content_rtb__content',
        id: '0f9bc8e7-294b-4d36-bd85-b40789a81739',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: 'web_content_rtb',
      },

      /**
       * 🌐 Subpages (subpages)
       */
      subpages: {
        codename: 'subpages',
        id: '800272c9-fa59-4bc1-9e19-925e67536802',
        externalId: undefined,
        name: '🌐 Subpages',
        required: false,
        type: 'subpages',
        snippetCodename: undefined,
      },

      /**
       * 🌐 Left navigation links (modular_content)
       */
      left_navigation: {
        codename: 'left_navigation',
        id: '9008fa56-1831-4964-9298-ff73d2f1a3e4',
        externalId: undefined,
        name: '🌐 Left navigation links',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Slug (url_slug)
       */
      slug: {
        codename: 'slug',
        id: 'cf405bcd-0e7e-45ab-95ee-ac00bd883ee8',
        externalId: undefined,
        name: '🔗 Slug',
        required: true,
        type: 'url_slug',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Canonical URL (text)
       */
      seo__canonical_url: {
        codename: 'seo__canonical_url',
        id: '440da44d-add4-58e0-89dc-422779bf5fb4',
        externalId: 'd7ee1702-d35d-4c51-a179-c8b26c87b467',
        name: '🔗 Canonical URL',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Title (text)
       */
      seo__title: {
        codename: 'seo__title',
        id: '9ca3abe2-e63e-5f9c-925b-79a95eb23525',
        externalId: '28610917-4e68-48ef-9a7d-012f286325c7',
        name: '📜 SEO Title',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Description (text)
       */
      seo__description: {
        codename: 'seo__description',
        id: '3072a2d9-3e2c-59e6-bf81-85d9bc696a50',
        externalId: 'a50872bb-2bf5-4b45-8cd7-caff7d4a66fa',
        name: '📜 SEO Description',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * 📜 SEO Keywords (text)
       */
      seo__keywords: {
        codename: 'seo__keywords',
        id: '94c37747-eb31-5521-b6c6-69813bca4ab1',
        externalId: 'f2b4c652-7248-4a31-8d2c-4e485a1dfe39',
        name: '📜 SEO Keywords',
        required: false,
        type: 'text',
        snippetCodename: 'seo',
      },

      /**
       * ⚙️ Robots meta tag (multiple_choice)
       */
      seo__robots: {
        codename: 'seo__robots',
        id: '40c147cd-ba16-5ff1-beed-0228c3dc0494',
        externalId: '81b73859-a544-4eb9-9468-2d93e423c9f9',
        name: '⚙️ Robots meta tag',
        required: false,
        type: 'multiple_choice',
        snippetCodename: 'seo',
      },

      /**
       * 🖇️ Show in-page navigation (multiple_choice)
       */
      show_in_page_navigation: {
        codename: 'show_in_page_navigation',
        id: '091c9656-c34d-4a73-be78-d3278df5bac8',
        externalId: undefined,
        name: '🖇️ Show in-page navigation',
        required: false,
        type: 'multiple_choice',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Redirect Url (text)
       *
       * Relative or abosute url
       */
      redirect_url: {
        codename: 'redirect_url',
        id: '09ff9348-ea41-480c-95ad-a81a9b8da978',
        externalId: undefined,
        name: '🔗 Redirect Url',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🖨️ Print Configuration
   * Last modified: Fri Nov 17 2023 02:23:19 GMT+0000 (Coordinated Universal Time)
   */
  printconfiguration: {
    codename: 'printconfiguration',
    id: 'fb8e73bd-950a-4666-9ee6-2bdfd57ffa84',
    externalId: undefined,
    name: '🖨️ Print Configuration',
    elements: {
      /**
       * 🎴 Logo (asset)
       */
      logo: {
        codename: 'logo',
        id: 'f130953f-bb34-4645-aea1-e2848f21137d',
        externalId: undefined,
        name: '🎴 Logo',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * 📜 Document title (text)
       */
      document_title: {
        codename: 'document_title',
        id: '6bc88b2f-94aa-4e83-9e9b-17b5e6c8ead7',
        externalId: undefined,
        name: '📜 Document title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Copyright information (rich_text)
       */
      copyright_information: {
        codename: 'copyright_information',
        id: '57933261-f304-45f9-9768-592f0b4e8bf8',
        externalId: undefined,
        name: '📜 Copyright information',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 License information (rich_text)
       */
      license_information: {
        codename: 'license_information',
        id: '85567980-309c-4710-acff-dddfa22f226d',
        externalId: undefined,
        name: '📜 License information',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Table of contents title (text)
       */
      table_of_contents_title: {
        codename: 'table_of_contents_title',
        id: '0adf52b2-2db4-47e7-8ce4-5637b79b2683',
        externalId: undefined,
        name: '📜 Table of contents title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Life Skills outcomes note - Stage 4 only (rich_text)
       */
      life_skills_outcomes_note_stage4only: {
        codename: 'life_skills_outcomes_note_stage4only',
        id: '651714e7-635d-4fc3-a089-f473088f7be8',
        externalId: undefined,
        name: '📜 Life Skills outcomes note - Stage 4 only',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Life Skills outcomes note - Stage 4/5 (rich_text)
       */
      life_skills_outcomes_note: {
        codename: 'life_skills_outcomes_note',
        id: '808dc8dd-798b-4800-86d9-761623ce01c5',
        externalId: undefined,
        name: '📜 Life Skills outcomes note - Stage 4/5',
        required: true,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 No content - glossary (text)
       *
       * No glossary items for this syllabus.
       */
      nocontent_glossary: {
        codename: 'nocontent_glossary',
        id: '7315398c-e5fe-4657-a57f-1735bf6208c4',
        externalId: undefined,
        name: '📜 No content - glossary',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 No content - outcomes and content (text)
       */
      nocontent_outcomescontent: {
        codename: 'nocontent_outcomescontent',
        id: 'ecb099c8-ad4c-4150-94e7-16b4fd321d27',
        externalId: undefined,
        name: '📜 No content - outcomes and content',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 No content - life skills (text)
       */
      nocontent_lifeskills: {
        codename: 'nocontent_lifeskills',
        id: '95445269-9e33-4750-8b0f-50d8550cccf2',
        externalId: undefined,
        name: '📜 No content - life skills',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 No content - teaching advice (text)
       *
       * No teaching advice content for this syllabus / stage.
       */
      nocontent_teachingadvice: {
        codename: 'nocontent_teachingadvice',
        id: 'a185f14f-39f8-419f-bc7a-82f2a8135f88',
        externalId: undefined,
        name: '📜 No content - teaching advice',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Overarching outcome message (text)
       */
      overarchingoutcome_message: {
        codename: 'overarchingoutcome_message',
        id: '477f4675-2bb1-4274-bda8-d70583d41bc8',
        externalId: undefined,
        name: '📜 Overarching outcome message',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 🔗 Web Link Video
   * Last modified: Fri Nov 17 2023 01:33:50 GMT+0000 (Coordinated Universal Time)
   */
  weblinkvideo: {
    codename: 'weblinkvideo',
    id: '8a487321-0910-49e8-a274-6ec53add0d4e',
    externalId: undefined,
    name: '🔗 Web Link Video',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: 'f96a57a8-8cbc-4a03-b722-3601edbe145a',
        externalId: undefined,
        name: '📜 Title',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 🔗 Video Url (text)
       */
      video_url: {
        codename: 'video_url',
        id: '8a702eac-4def-4d14-afcd-f1749491c2aa',
        externalId: undefined,
        name: '🔗 Video Url',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📅 Date (date_time)
       */
      date: {
        codename: 'date',
        id: 'd988f0cc-7f06-4c42-8273-db5da4e3112a',
        externalId: undefined,
        name: '📅 Date',
        required: false,
        type: 'date_time',
        snippetCodename: undefined,
      },

      /**
       * 📃 Transcript (asset)
       */
      transcript: {
        codename: 'transcript',
        id: 'c78559e9-5d7c-44c8-93e0-1edcfb394664',
        externalId: undefined,
        name: '📃 Transcript',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * 📦 Thumbnail (asset)
       */
      thumbnail: {
        codename: 'thumbnail',
        id: 'a0166bee-4c31-4c1b-abe5-1a19b7c121aa',
        externalId: undefined,
        name: '📦 Thumbnail',
        required: false,
        type: 'asset',
        snippetCodename: undefined,
      },

      /**
       * Resource type (taxonomy)
       */
      resource_type: {
        codename: 'resource_type',
        id: '76366c07-5601-4f64-8934-13378215cf3b',
        externalId: undefined,
        name: '🏷️ Resource Type',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '424da039-d515-4da6-bc3f-81583199f65c',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Syllabus Type (taxonomy)
       */
      syllabus_type: {
        codename: 'syllabus_type',
        id: '90f63c63-fd24-4f06-a1c1-689dcc7d5322',
        externalId: undefined,
        name: '🏷️ Syllabus Type',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Key learning area (taxonomy)
       */
      key_learning_area__items: {
        codename: 'key_learning_area__items',
        id: 'd4be49ed-5b70-4bf1-8396-c4c4ba8a1877',
        externalId: undefined,
        name: 'Key Learning Area',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'key_learning_area',
      },
    },
  },

  /**
   * 📑🔹Content Group
   * Last modified: Tue Jun 04 2024 06:12:40 GMT+0000 (Coordinated Universal Time)
   */
  contentgroup: {
    codename: 'contentgroup',
    id: '68a8d341-4f48-4882-baab-ac251e8b4741',
    externalId: undefined,
    name: '📑🔹Content Group',
    elements: {
      /**
       * 📜 Title (text)
       */
      title: {
        codename: 'title',
        id: '9a2de5dc-f0a9-46e2-a78c-df11d8079fc7',
        externalId: undefined,
        name: '📜 Title',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content (rich_text)
       */
      content: {
        codename: 'content',
        id: '87d7f488-127e-4da4-ac1a-35cfbd57321a',
        externalId: undefined,
        name: '📜 Content',
        required: false,
        type: 'rich_text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Content stage specific (modular_content)
       */
      content_staged: {
        codename: 'content_staged',
        id: 'bde51638-005c-478a-82f3-426c064e2524',
        externalId: undefined,
        name: '📜 Content stage specific',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 📄 Content Items (modular_content)
       */
      content_items: {
        codename: 'content_items',
        id: '1072dc55-b015-4e24-a594-e64d3fcaaf46',
        externalId: undefined,
        name: '📄 Content Items',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * 🖇️ Curriculum connections links (modular_content)
       */
      chps_links: {
        codename: 'chps_links',
        id: 'e6bdf052-22b1-45c1-9d0a-a05ecd35725e',
        externalId: undefined,
        name: '🖇️ Curriculum connections links',
        required: false,
        type: 'modular_content',
        snippetCodename: undefined,
      },

      /**
       * Syllabus (taxonomy)
       */
      syllabus: {
        codename: 'syllabus',
        id: '6e2b82f1-90e2-406b-88c4-576da01cbc93',
        externalId: undefined,
        name: '🏷️ Syllabus',
        required: false,
        type: 'taxonomy',
        snippetCodename: undefined,
      },

      /**
       * Stage Group (taxonomy)
       */
      stages__stage_groups: {
        codename: 'stages__stage_groups',
        id: 'a5222385-0941-43a7-90fd-06b9440ebadb',
        externalId: undefined,
        name: '🏷️ Stage Groups',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage (taxonomy)
       *
       * Please select all 3 levels e.g. "Secondary" then "Stage 4" and  then "7" and/or "8"
       */
      stages__stages: {
        codename: 'stages__stages',
        id: 'a1eaaaa8-f318-4099-b7c9-477e2cd51093',
        externalId: undefined,
        name: '🏷️ Stages',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * Stage Year (taxonomy)
       */
      stages__stage_years: {
        codename: 'stages__stage_years',
        id: '15c84d16-af82-4481-9368-abcb5b07578c',
        externalId: undefined,
        name: '🏷️ Stage Years',
        required: true,
        type: 'taxonomy',
        snippetCodename: 'stages',
      },

      /**
       * 📜 Code (text)
       *
       * ⚠️ Not used
       */
      code: {
        codename: 'code',
        id: '0e18ae2c-3e9f-43d3-90c8-d8ce91c24f1a',
        externalId: undefined,
        name: '📜 Code',
        required: false,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },

  /**
   * 💠 Web UI Blockquote
   * Last modified: Thu Jun 30 2022 03:21:18 GMT+0000 (Coordinated Universal Time)
   */
  ui_blockquote: {
    codename: 'ui_blockquote',
    id: '1583eb33-b14e-4ee5-88e5-48cd85cd7559',
    externalId: undefined,
    name: '💠 Web UI Blockquote',
    elements: {
      /**
       * 📜 Quote (text)
       */
      quote: {
        codename: 'quote',
        id: '21b5e8e6-0e3f-461d-82eb-06d44fc931ba',
        externalId: undefined,
        name: '📜 Quote',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },

      /**
       * 📜 Cite (text)
       */
      cite: {
        codename: 'cite',
        id: '85e05cf6-29d4-4a3f-8bd4-d981f1934e8b',
        externalId: undefined,
        name: '📜 Cite',
        required: true,
        type: 'text',
        snippetCodename: undefined,
      },
    },
  },
};
