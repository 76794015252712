import { Course, Syllabus } from '@/kontent/content-types'
import { taxonomies } from '@/kontent/project/taxonomies'
import CustomAccordion from '@/legacy-ported/components/custom/CustomAccordion'
import {
	CommonContentTab,
	getStagesOrYearsBasedOnSyllabusAndDisabledStages,
} from '@/pages/learning-areas/[learningarea]/[syllabus]/[tab]'
import {
	CommonPageProps,
	MappingParams,
	TaxoStageWithLifeSkill,
	TaxoStageYearWithLifeSkill,
} from '@/types'
import {
	getCodenameBySlug,
	getLinkedItems,
	getSlugByCodename,
	isYes,
} from '@/utils'
import { ElementModels } from '@kontent-ai/delivery-sdk'
import Link from 'next/link'
import { Fragment, ReactNode } from 'react'
import { useKontentHomeConfig } from '../contexts/KontentHomeConfigProvider'
import { SyllabusTabsTitle } from '../ui/syllabus-tabs/SyllabusTabsTitle'

export interface StageOrYearContentAccordionProps {
	title?: string
	stagesOrYears: ElementModels.TaxonomyTerm<
		TaxoStageWithLifeSkill | TaxoStageYearWithLifeSkill
	>[]
	params: MappingParams
	children?: ReactNode
	defaultFocusAreaPerStageOrYear?: CommonContentTab<Syllabus>['defaultFocusAreaUrls']
	syllabusCourseItems?: Course[]
}

export const isTaxoStageYearWithLifeSkill = (
	obj: ElementModels.TaxonomyTerm<any>,
): obj is ElementModels.TaxonomyTerm<TaxoStageYearWithLifeSkill> => {
	return (
		Object.keys(taxonomies.stage_year.terms).some(
			(t) => t === obj.codename,
		) || obj.codename === 'life_skills'
	)
}

export const getYearTitle = (
	year: ElementModels.TaxonomyTerm<TaxoStageYearWithLifeSkill>,
) => {
	return year.codename === 'life_skills' ? year.name : `Year ${year.name}`
}

export const StageOrYearContentAccordion = ({
	stagesOrYears,
	params,
	defaultFocusAreaPerStageOrYear,
	children,
	syllabusCourseItems,
}: StageOrYearContentAccordionProps) => {
	const { preview, mappings } = useKontentHomeConfig()

	// Filter out courses that are set to not display
	// Should return array of all stages/years that should be displayed
	const filteredStageOrYear = syllabusCourseItems
		.filter((course) => isYes(course.elements.display_mode))
		.flatMap((course) => {
			return course.elements.content_accordion.value
		})

	// Filter out stage/years that shouldnt be shown, ignoring life skills
	const renderStagesOrYears = stagesOrYears.filter((stageOrYear) => {
		return filteredStageOrYear.some((filtered) => {
			return (
				filtered.codename === stageOrYear.codename ||
				stageOrYear.codename === 'life_skills'
			)
		})
	})

	return (
		<div className="px-4 pt-8">
			<SyllabusTabsTitle
				tabId="content"
				mappings={mappings}
				syllabusCodename={getCodenameBySlug(params['syllabus'])}
			/>

			{renderStagesOrYears
				.map((stageOrYear) => {
					const stageCodenameSlugged = getSlugByCodename(
						stageOrYear.codename,
					)

					const title = isTaxoStageYearWithLifeSkill(stageOrYear)
						? getYearTitle(stageOrYear)
						: stageOrYear.name

					const isCurrent = children
						? stageCodenameSlugged === params['stage']
						: false

					const accordionUrl =
						defaultFocusAreaPerStageOrYear?.[
							stageOrYear.codename
						] ||
						`/learning-areas/${params['learningarea']}/${params['syllabus']}/${params['tab']}/${stageCodenameSlugged}`

					const specifiedCourses = syllabusCourseItems?.filter(
						(course) =>
							course.elements.content_accordion?.value[0]
								?.codename === stageOrYear.codename &&
							isYes(course.elements.display_mode),
					)

					if (specifiedCourses?.length) {
						if (specifiedCourses.length > 1) {
							return (
								<Fragment key={stageOrYear.codename}>
									{syllabusCourseItems.map((course) => {
										const displaySpecifiedCourses = isYes(
											course.elements.display_mode,
										)
										const courseUrl =
											defaultFocusAreaPerStageOrYear?.[
												stageOrYear.codename +
													'-' +
													course.elements.slug?.value
											]
										const isExtensionActive = children
											? stageOrYear.codename +
													'-' +
													course.elements.slug
														?.value ==
											  params['stage']
											: false

										if (
											displaySpecifiedCourses &&
											courseUrl?.length > 1
										) {
											return (
												<CustomAccordion
													key={
														stageOrYear.codename +
														course.system.codename
													}
													title={
														title +
														course.elements
															.content_accordion_title
															.value
													}
													className="relative"
													slotAfter={
														isExtensionActive ? null : (
															<Link
																href={courseUrl}
																prefetch={
																	!preview
																}
															>
																<a
																	className="absolute inset-0 hover:bg-[rgba(0,133,179,0.2)] focus:bg-[rgba(0,133,179,0.2)] hover:outline-0"
																	aria-label={
																		title +
																		' - ' +
																		course
																			.elements
																			.content_accordion_title
																			.value
																	}
																></a>
															</Link>
														)
													}
													startOpen={
														isExtensionActive
													}
												>
													{isExtensionActive
														? children
														: null}
												</CustomAccordion>
											)
										}

										return null
									})}
								</Fragment>
							)
						} else {
							return (
								<Fragment
									key={'normal-stage-' + stageOrYear.codename}
								>
									{accordionUrl.length > 1 && (
										<CustomAccordion
											key={stageOrYear.codename}
											title={title}
											className="relative"
											slotAfter={
												isCurrent ? null : (
													<Link
														href={accordionUrl}
														prefetch={!preview}
													>
														<a
															className="absolute inset-0 hover:bg-[rgba(0,133,179,0.2)] focus:bg-[rgba(0,133,179,0.2)] hover:outline-0"
															aria-label={
																stageOrYear.name
															}
														></a>
													</Link>
												)
											}
											startOpen={isCurrent}
										>
											{isCurrent ? children : null}
										</CustomAccordion>
									)}
								</Fragment>
							)
						}
					}
					return (
						<Fragment key={'normal-stage-' + stageOrYear.codename}>
							{accordionUrl.length > 1 && (
								<CustomAccordion
									key={stageOrYear.codename}
									title={title}
									className="relative"
									slotAfter={
										isCurrent ? null : (
											<Link
												href={accordionUrl}
												prefetch={!preview}
											>
												<a
													className="absolute inset-0 hover:bg-[rgba(0,133,179,0.2)] focus:bg-[rgba(0,133,179,0.2)] hover:outline-0"
													aria-label={
														stageOrYear.name
													}
												></a>
											</Link>
										)
									}
									startOpen={isCurrent}
								>
									{isCurrent ? children : null}
								</CustomAccordion>
							)}
						</Fragment>
					)
				})
				.filter(Boolean)}
		</div>
	)
}

export const Content = ({
	params,
	data,
}: CommonPageProps<Syllabus, CommonContentTab<Syllabus>>) => {
	const { stages, years, syllabus, disabledStages } = data || {}
	const stageOrYears = getStagesOrYearsBasedOnSyllabusAndDisabledStages(
		stages,
		years,
		disabledStages,
		syllabus.item,
	)

	const syllabusCourseItems = getLinkedItems(
		syllabus.item.elements.courses,
		syllabus.linkedItems,
	)

	return (
		<StageOrYearContentAccordion
			stagesOrYears={stageOrYears}
			params={params}
			defaultFocusAreaPerStageOrYear={data.defaultFocusAreaUrls}
			syllabusCourseItems={syllabusCourseItems}
		/>
	)
}

export default Content
