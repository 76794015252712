/**
 * Generated by '@kontent-ai/model-generator@5.1.1'
 *
 * Project name: 🔴 NESA MAIN
 * Environment: 🔴 PROD
 * Project Id: 341419dc-8ec2-0289-7225-6db7f2d751ef
 */
export const taxonomies = {
  /**
   * Course Type
   */
  coursetype: {
    codename: 'coursetype',
    id: '4d948519-192d-4b8d-bc11-6c27488ea47e',
    externalId: undefined,
    name: 'Course Type',
    terms: {
      mainstream: {
        codename: 'mainstream',
        id: '7d97577a-48b9-40a6-9924-d7c361b558ed',
        externalId: undefined,
        name: 'Mainstream',
        terms: {},
      },
      life_skills: {
        codename: 'life_skills',
        id: '0b312d3a-3608-41a3-8388-bd36ab4cbf2d',
        externalId: undefined,
        name: 'Life Skills',
        terms: {},
      },
      extension: {
        codename: 'extension',
        id: '59b23d8a-d5f3-4ea2-ae8d-87f2f371b27f',
        externalId: undefined,
        name: 'Extension',
        terms: {},
      },
      vet: {
        codename: 'vet',
        id: '3b45bffa-43b6-4ffc-8af2-996d6a021845',
        externalId: undefined,
        name: 'VET',
        terms: {},
      },
    },
  },

  /**
   * Assessment Grades
   */
  assessment_grades: {
    codename: 'assessment_grades',
    id: '10567768-116c-4fa5-b85f-9eb5156dad56',
    externalId: undefined,
    name: 'Assessment Grades',
    terms: {
      grade_a: {
        codename: 'grade_a',
        id: '20ae766f-8a81-4efe-905e-69f574e086f8',
        externalId: undefined,
        name: 'Grade A',
        terms: {},
      },
      grade_b: {
        codename: 'grade_b',
        id: '5436b4c3-5ffe-42df-a8bb-8e74e67c26b4',
        externalId: undefined,
        name: 'Grade B',
        terms: {},
      },
      grade_c: {
        codename: 'grade_c',
        id: '16dbd3d4-44ba-4959-a31d-48c488da4afd',
        externalId: undefined,
        name: 'Grade C',
        terms: {},
      },
      grade_d: {
        codename: 'grade_d',
        id: 'f2b1fa63-1d07-4d2b-a850-afa142ce0b3b',
        externalId: undefined,
        name: 'Grade D',
        terms: {},
      },
      grade_e: {
        codename: 'grade_e',
        id: '70c57fbb-91ed-42c5-9299-ce4bb8bf30db',
        externalId: undefined,
        name: 'Grade E',
        terms: {},
      },
      band_6: {
        codename: 'band_6',
        id: '20741a05-ecb0-44a3-a4fe-c692aece287e',
        externalId: undefined,
        name: 'Band 6',
        terms: {},
      },
      band_5: {
        codename: 'band_5',
        id: '33785dda-2f8a-4927-9922-f21e19479641',
        externalId: undefined,
        name: 'Band 5',
        terms: {},
      },
      band_4: {
        codename: 'band_4',
        id: '65c9eb86-9cd1-4161-8503-b93ce59454f8',
        externalId: undefined,
        name: 'Band 4',
        terms: {},
      },
      band_3: {
        codename: 'band_3',
        id: '5476b197-ced0-418c-be10-92eeaeadb4a6',
        externalId: undefined,
        name: 'Band 3',
        terms: {},
      },
      band_2: {
        codename: 'band_2',
        id: 'c853d011-5fff-47fa-9c97-4682aa24c30f',
        externalId: undefined,
        name: 'Band 2',
        terms: {},
      },
      band_1: {
        codename: 'band_1',
        id: '11ed4fb7-2186-4e41-bfe8-29efaf484b5e',
        externalId: undefined,
        name: 'Band 1',
        terms: {},
      },
    },
  },

  /**
   * ACE Category
   */
  ace_category: {
    codename: 'ace_category',
    id: '63f34aeb-95bc-4cc3-a455-4562efc967ad',
    externalId: undefined,
    name: 'ACE Category',
    terms: {
      hsc_assessment: {
        codename: 'hsc_assessment',
        id: '1b34260e-3fbc-4791-9a6f-de6d45002153',
        externalId: undefined,
        name: 'HSC (assessment)',
        terms: {},
      },
      hsc_credential: {
        codename: 'hsc_credential',
        id: '77c04c6f-7df9-46df-95bd-22763af38bd9',
        externalId: undefined,
        name: 'HSC credential',
        terms: {},
      },
      hsc_curriculum: {
        codename: 'hsc_curriculum',
        id: 'f2e79077-04e7-4a8a-86e1-07a9e61f028a',
        externalId: undefined,
        name: 'HSC (curriculum)',
        terms: {},
      },
      k_10: {
        codename: 'k_10',
        id: '194e84f9-6f10-481f-8316-28d303a8446c',
        externalId: undefined,
        name: 'K–10',
        terms: {},
      },
      life_skills: {
        codename: 'life_skills',
        id: '2cebfd25-fea2-49c9-b4f8-b49645acc08f',
        externalId: undefined,
        name: 'Life Skills',
        terms: {},
      },
      malpractice: {
        codename: 'malpractice',
        id: '66617a37-86e2-414f-b1e4-358e55e2d6a8',
        externalId: undefined,
        name: 'Malpractice',
        terms: {},
      },
      preliminary: {
        codename: 'preliminary',
        id: '4d1d2f1b-4958-41e6-86e0-c96352ef8374',
        externalId: undefined,
        name: 'Preliminary',
        terms: {},
      },
      rosa: {
        codename: 'rosa',
        id: 'aed95a83-93b8-43f8-87de-cbb819286003',
        externalId: undefined,
        name: 'RoSA',
        terms: {},
      },
      schoolsonline: {
        codename: 'schoolsonline',
        id: 'ef4f13dd-4e09-4bf2-988a-418197db387f',
        externalId: undefined,
        name: 'Schools Online',
        terms: {},
      },
      vet: {
        codename: 'vet',
        id: '1c90454d-055a-4c6e-8bcd-3307356fa155',
        externalId: undefined,
        name: 'VET',
        terms: {},
      },
    },
  },

  /**
   * Resource type
   */
  resource_type: {
    codename: 'resource_type',
    id: '0aab1843-8ce8-4704-803b-1347d5957c0b',
    externalId: undefined,
    name: 'Resource type',
    terms: {
      assessment: {
        codename: 'assessment',
        id: 'f32f8fd3-7cda-43ab-afe8-6d1f572c5f45',
        externalId: undefined,
        name: 'Assessment',
        terms: {},
      },
      biblio: {
        codename: 'biblio',
        id: '626150fc-fd88-46a6-a7f3-2eb8b2c876ff',
        externalId: undefined,
        name: 'Bibliographies',
        terms: {},
      },
      hscpapers: {
        codename: 'hscpapers',
        id: 'c8e13138-dc46-4dc3-8d2a-38024055d831',
        externalId: undefined,
        name: 'HSC exam papers',
        terms: {},
      },
      languagesupport: {
        codename: 'languagesupport',
        id: 'c766a49e-7093-4e0e-9d27-7963a018da01',
        externalId: undefined,
        name: 'Language specific support',
        terms: {},
      },
      lsow: {
        codename: 'lsow',
        id: '71d2700e-d12b-4832-b246-b4edf4d691a0',
        externalId: undefined,
        name: 'Life Skills outcomes worksheet',
        terms: {},
      },
      pguide: {
        codename: 'pguide',
        id: '47e31650-4421-42d8-bec4-fe7595aa78cf',
        externalId: undefined,
        name: 'Parent guides',
        terms: {},
      },
      papa: {
        codename: 'papa',
        id: '20705821-aa40-49a3-ab7f-5d44ca733295',
        externalId: undefined,
        name: 'Planning and programming advice',
        terms: {},
      },
      professional_learning: {
        codename: 'professional_learning',
        id: 'c064de8d-39da-4598-bfe3-544bb9c895a5',
        externalId: undefined,
        name: 'Professional Learning',
        terms: {},
      },
      sampleassessschedules: {
        codename: 'sampleassessschedules',
        id: '2de6c8e2-25b1-4674-bc30-58cb0c46fb0c',
        externalId: undefined,
        name: 'Sample assessment schedules',
        terms: {},
      },
      sampleassesstasks: {
        codename: 'sampleassesstasks',
        id: 'e4f7d867-bea7-4c72-bc67-d30391a86043',
        externalId: undefined,
        name: 'Sample formal assessment tasks',
        terms: {},
      },
      ssas: {
        codename: 'ssas',
        id: '90c85179-3b21-4a9a-9e30-52b5bcb939f2',
        externalId: undefined,
        name: 'Sample scope and sequence',
        terms: {},
      },
      sunit: {
        codename: 'sunit',
        id: 'fa1a871d-08e2-4679-a64b-27321a546f34',
        externalId: undefined,
        name: 'Sample units',
        terms: {},
      },
      swork: {
        codename: 'swork',
        id: '464c1575-2837-47bd-9ee6-50d3f7a7c94a',
        externalId: undefined,
        name: 'Sample work',
        terms: {},
      },
      standardsmaterials: {
        codename: 'standardsmaterials',
        id: 'ccc0a94a-12e1-4740-91de-679adba9a050',
        externalId: undefined,
        name: 'Standards materials',
        terms: {},
      },
      sugis: {
        codename: 'sugis',
        id: 'f0b53837-3731-45c9-b3d2-e92967bc254e',
        externalId: undefined,
        name: 'Suggested instructional sequences',
        terms: {},
      },
      advice: {
        codename: 'advice',
        id: 'f05f7371-aadb-47fb-b8cd-64e92b3f2b5d',
        externalId: undefined,
        name: 'Teaching advice',
        terms: {},
      },
      web_resource: {
        codename: 'web_resource',
        id: '23f6ef56-d788-4f39-affe-5e7a39b8e1f9',
        externalId: undefined,
        name: 'Web resource',
        terms: {},
      },
      other: {
        codename: 'other',
        id: '8363e97e-4e8e-4f72-aaad-8051f42c278c',
        externalId: undefined,
        name: 'Other',
        terms: {},
      },
      ace_appealforms: {
        codename: 'ace_appealforms',
        id: '96737476-f81e-41b2-8370-8e8ef83d184b',
        externalId: undefined,
        name: 'ACE - appeal forms',
        terms: {},
      },
      ace_applicationforms: {
        codename: 'ace_applicationforms',
        id: '669e60ab-6127-43eb-b11d-d552e1a5bdef',
        externalId: undefined,
        name: 'ACE - application forms',
        terms: {},
      },
      ace_eligibilityforms: {
        codename: 'ace_eligibilityforms',
        id: 'ec3016a6-a452-434d-acd6-684ca7ca5ad1',
        externalId: undefined,
        name: 'ACE - eligibility forms',
        terms: {},
      },
      ace_general: {
        codename: 'ace_general',
        id: '05401793-2a72-41cc-89c0-2fc884e34387',
        externalId: undefined,
        name: 'ACE - general',
        terms: {},
      },
    },
  },

  /**
   * Asset Published Year
   */
  assetpublishedyear: {
    codename: 'assetpublishedyear',
    id: '4abee3a5-18cc-42fe-b3b3-ad441b45f7fb',
    externalId: undefined,
    name: 'Asset Published Year',
    terms: {
      n2021: {
        codename: 'n2021',
        id: '0a1540f0-1aae-4180-95ad-cea276ef7109',
        externalId: undefined,
        name: '2021',
        terms: {},
      },
      n2022: {
        codename: 'n2022',
        id: 'dbb13916-c79f-4625-be33-e73e02a34eed',
        externalId: undefined,
        name: '2022',
        terms: {},
      },
      n2023: {
        codename: 'n2023',
        id: '3f20d212-bf24-45d0-be17-ce196e0601e7',
        externalId: undefined,
        name: '2023',
        terms: {},
      },
      n2024: {
        codename: 'n2024',
        id: '7018806f-4f6c-40c7-a23e-12c2d2b83c91',
        externalId: undefined,
        name: '2024',
        terms: {},
      },
    },
  },

  /**
   * Pathways
   */
  pathways: {
    codename: 'pathways',
    id: '4535244e-011e-4d10-94a2-ea364510c296',
    externalId: undefined,
    name: 'Pathways',
    terms: {
      language_pathways: {
        codename: 'language_pathways',
        id: '632c86a0-2aea-4b23-8c80-5d940cc20356',
        externalId: undefined,
        name: '------------ Language Pathways',
        terms: {
          additional: {
            codename: 'additional',
            id: '5cd9d1db-1c36-4e64-bf83-d85dd3550409',
            externalId: undefined,
            name: 'Additional',
            terms: {},
          },
          prior: {
            codename: 'prior',
            id: '9ab928ae-2dce-44fa-b769-ff1ae7d24f97',
            externalId: undefined,
            name: 'Prior',
            terms: {},
          },
          first: {
            codename: 'first',
            id: '7724f10a-59b6-4e61-8567-91cd171693e3',
            externalId: undefined,
            name: 'First',
            terms: {},
          },
        },
      },
    },
  },

  /**
   * Stage Group
   */
  stage_group: {
    codename: 'stage_group',
    id: 'ea1e742a-9284-4364-b262-a5b71a50aea0',
    externalId: undefined,
    name: 'Stage Group',
    terms: {
      primary: {
        codename: 'primary',
        id: '5b481c9d-8c4d-4a56-bfe6-f84c2b96a736',
        externalId: undefined,
        name: 'Primary (K–6)',
        terms: {},
      },
      secondary: {
        codename: 'secondary',
        id: '14e97eb7-9f97-4a90-93d6-73762c4adad8',
        externalId: undefined,
        name: 'Secondary (7–10)',
        terms: {},
      },
      senior: {
        codename: 'senior',
        id: '78bb9046-9d6c-4c1f-b9aa-4cce525de989',
        externalId: undefined,
        name: 'Senior (11–12)',
        terms: {},
      },
    },
  },

  /**
   * Syllabus wide
   */
  curriculum_wide: {
    codename: 'curriculum_wide',
    id: 'd2d6c01e-501d-4fbc-a4ad-6983f687c467',
    externalId: undefined,
    name: 'Syllabus wide',
    terms: {
      yes: {
        codename: 'yes',
        id: '3877a40d-35db-4953-b16c-2a6fc66a5bf5',
        externalId: undefined,
        name: 'Syllabus wide resource',
        terms: {},
      },
    },
  },

  /**
   * Endorsement type
   */
  endorsement_type: {
    codename: 'endorsement_type',
    id: '63907295-d3d2-45d9-80d0-bb1b8ee6844b',
    externalId: undefined,
    name: 'Endorsement type',
    terms: {
      board_endorsed: {
        codename: 'board_endorsed',
        id: '15f19060-e979-415e-9d84-c4508017417c',
        externalId: undefined,
        name: 'Board endorsed',
        terms: {},
      },
      content_endorsed: {
        codename: 'content_endorsed',
        id: '63f2fe14-1045-4695-a358-8a49d2ee4d6d',
        externalId: undefined,
        name: 'Content endorsed',
        terms: {},
      },
      board_developed: {
        codename: 'board_developed',
        id: '23ec4fa0-2d8b-461d-8f41-6cdfc5305409',
        externalId: undefined,
        name: 'Board developed',
        terms: {},
      },
    },
  },

  /**
   * National Numeracy Learning Progression
   */
  numeracy: {
    codename: 'numeracy',
    id: '857fe467-c003-4c78-8b7c-10ef5d1d6ef0',
    externalId: undefined,
    name: 'National Numeracy Learning Progression',
    terms: {
      ________________number_sense_and_algebra___number_and_place_value: {
        codename:
          '________________number_sense_and_algebra___number_and_place_value',
        id: '08c18024-f63e-46b3-8c03-3548ca4a89a4',
        externalId: undefined,
        name: '--------------- Number sense and algebra - Number and place value',
        terms: {},
      },
      npv1: {
        codename: 'npv1',
        id: '831c346d-0c4d-41ce-b26a-dbf0a56fb123',
        externalId: undefined,
        name: 'NPV1',
        terms: {},
      },
      npv2: {
        codename: 'npv2',
        id: 'b866dfb0-8a95-44e7-995a-6e9ac2eda53c',
        externalId: undefined,
        name: 'NPV2',
        terms: {},
      },
      npv3: {
        codename: 'npv3',
        id: '50354e56-3f99-428a-baa9-d3502d56ac5f',
        externalId: undefined,
        name: 'NPV3',
        terms: {},
      },
      npv4: {
        codename: 'npv4',
        id: 'd107f89b-9a9e-482f-a4aa-c2124de271ab',
        externalId: undefined,
        name: 'NPV4',
        terms: {},
      },
      npv5: {
        codename: 'npv5',
        id: '89bd6d70-42c4-4453-ae1f-524053348f34',
        externalId: undefined,
        name: 'NPV5',
        terms: {},
      },
      npv6: {
        codename: 'npv6',
        id: '241f72ae-c40c-4e75-82d7-3d1cea984c14',
        externalId: undefined,
        name: 'NPV6',
        terms: {},
      },
      npv7: {
        codename: 'npv7',
        id: 'f9634a59-2551-4d5a-884e-d74cdfc09231',
        externalId: undefined,
        name: 'NPV7',
        terms: {},
      },
      npv8: {
        codename: 'npv8',
        id: 'deb48ecd-ac20-406b-aa7e-f8edf0c54e79',
        externalId: undefined,
        name: 'NPV8',
        terms: {},
      },
      npv9: {
        codename: 'npv9',
        id: 'a4fe1f1d-530b-449e-bc64-7cdec394d721',
        externalId: undefined,
        name: 'NPV9',
        terms: {},
      },
      npv10: {
        codename: 'npv10',
        id: 'e0b206be-0de2-4650-9787-e0aa420f3b47',
        externalId: undefined,
        name: 'NPV10',
        terms: {},
      },
      ________________number_sense_and_algebra___counting_process: {
        codename: '________________number_sense_and_algebra___counting_process',
        id: '0fc1ee83-8053-4eb2-8d5f-063ddbfd9d16',
        externalId: undefined,
        name: '--------------- Number sense and algebra - Counting process',
        terms: {},
      },
      cpr1: {
        codename: 'cpr1',
        id: '7db7ec78-1239-4e23-a2c1-bf3575cae8ff',
        externalId: undefined,
        name: 'CPr1',
        terms: {},
      },
      cpr2: {
        codename: 'cpr2',
        id: '7ec70e8f-a7ec-4873-be60-d898a57a07ef',
        externalId: undefined,
        name: 'CPr2',
        terms: {},
      },
      cpr3: {
        codename: 'cpr3',
        id: '5d55eed4-0e41-4555-b227-61ba0c2db504',
        externalId: undefined,
        name: 'CPr3',
        terms: {},
      },
      cpr4: {
        codename: 'cpr4',
        id: 'd2b179ef-b75f-4cc8-ba7d-ef5aaeccd92e',
        externalId: undefined,
        name: 'CPr4',
        terms: {},
      },
      cpr5: {
        codename: 'cpr5',
        id: '6f3f8da2-1c3f-492a-bfde-6f65a12279f8',
        externalId: undefined,
        name: 'CPr5',
        terms: {},
      },
      cpr6: {
        codename: 'cpr6',
        id: 'cf4461f5-695e-44a0-b4b5-844f27fd732c',
        externalId: undefined,
        name: 'CPr6',
        terms: {},
      },
      cpr7: {
        codename: 'cpr7',
        id: '2d204929-c766-4e84-af6e-a4a50e114290',
        externalId: undefined,
        name: 'CPr7',
        terms: {},
      },
      cpr8: {
        codename: 'cpr8',
        id: '5bab2a73-e0de-4c20-9407-18e0a032e173',
        externalId: undefined,
        name: 'CPr8',
        terms: {},
      },
      ________________number_sense_and_algebra___additive_strategies: {
        codename:
          '________________number_sense_and_algebra___additive_strategies',
        id: 'a4ffb74b-b3e3-43fc-849b-5fbbab17dc2f',
        externalId: undefined,
        name: '--------------- Number sense and algebra - Additive strategies',
        terms: {},
      },
      ads1: {
        codename: 'ads1',
        id: '070408d3-5650-449f-b581-0f740986ec02',
        externalId: undefined,
        name: 'AdS1',
        terms: {},
      },
      ads2: {
        codename: 'ads2',
        id: '47c05c72-b2f2-4da1-8481-b017755af25c',
        externalId: undefined,
        name: 'AdS2',
        terms: {},
      },
      ads3: {
        codename: 'ads3',
        id: 'ba14d3d2-42c6-445e-958e-0e377c0ae132',
        externalId: undefined,
        name: 'AdS3',
        terms: {},
      },
      ads4: {
        codename: 'ads4',
        id: '270d82b1-9be2-4381-b9fd-9dc54692949b',
        externalId: undefined,
        name: 'AdS4',
        terms: {},
      },
      ads5: {
        codename: 'ads5',
        id: 'e562e009-fdf8-483e-bbc3-5e1ec43555ec',
        externalId: undefined,
        name: 'AdS5',
        terms: {},
      },
      ads6: {
        codename: 'ads6',
        id: 'a5323717-e873-41d4-b974-92c5fce4b015',
        externalId: undefined,
        name: 'AdS6',
        terms: {},
      },
      ads7: {
        codename: 'ads7',
        id: '6fa0e056-df9c-43cb-83f7-947a2e1a382a',
        externalId: undefined,
        name: 'AdS7',
        terms: {},
      },
      ads8: {
        codename: 'ads8',
        id: 'b397522f-efe2-4547-9be6-ec05fbc91452',
        externalId: undefined,
        name: 'AdS8',
        terms: {},
      },
      ads9: {
        codename: 'ads9',
        id: '304835d6-3f83-4449-868b-1c2ad3097909',
        externalId: undefined,
        name: 'AdS9',
        terms: {},
      },
      ads10: {
        codename: 'ads10',
        id: '3e1567dd-717f-4eae-a6cb-2dbff816f4f0',
        externalId: undefined,
        name: 'AdS10',
        terms: {},
      },
      ________________number_sense_and_algebra___multiplicative_strategies: {
        codename:
          '________________number_sense_and_algebra___multiplicative_strategies',
        id: '6aacb56c-5b1f-4045-b9c5-63818d20750a',
        externalId: undefined,
        name: '--------------- Number sense and algebra - Multiplicative strategies',
        terms: {},
      },
      mus1: {
        codename: 'mus1',
        id: 'db40ee7a-bb86-4dd8-a8e3-97894db955bf',
        externalId: undefined,
        name: 'MuS1',
        terms: {},
      },
      mus2: {
        codename: 'mus2',
        id: '6643753a-c736-4ab0-977c-cd54d6188204',
        externalId: undefined,
        name: 'MuS2',
        terms: {},
      },
      mus3: {
        codename: 'mus3',
        id: '0f9e66c5-cb2d-419a-9ffe-8dab2b12fa7d',
        externalId: undefined,
        name: 'MuS3',
        terms: {},
      },
      mus4: {
        codename: 'mus4',
        id: '0e0e14b0-93b3-46a4-b4a4-e1bcd27ef18d',
        externalId: undefined,
        name: 'MuS4',
        terms: {},
      },
      mus5: {
        codename: 'mus5',
        id: '950187cc-5464-47a5-826d-edf87e87843d',
        externalId: undefined,
        name: 'MuS5',
        terms: {},
      },
      mus6: {
        codename: 'mus6',
        id: 'ed94c762-1858-4333-b3f5-d397fda78063',
        externalId: undefined,
        name: 'MuS6',
        terms: {},
      },
      mus7: {
        codename: 'mus7',
        id: '6b8d2e7e-8b9d-4215-8f4e-e7ca97f68483',
        externalId: undefined,
        name: 'MuS7',
        terms: {},
      },
      mus8: {
        codename: 'mus8',
        id: 'd265f933-5a5e-4983-bb87-7bc8116f8df8',
        externalId: undefined,
        name: 'MuS8',
        terms: {},
      },
      mus9: {
        codename: 'mus9',
        id: '732bfb0b-d25a-4805-b4b9-d409edafc0fa',
        externalId: undefined,
        name: 'MuS9',
        terms: {},
      },
      mus10: {
        codename: 'mus10',
        id: 'e882b9c7-4a5a-4758-9237-9d462c62951f',
        externalId: undefined,
        name: 'MuS10',
        terms: {},
      },
      ________________number_sense_and_algebra___interpreting_fractions: {
        codename:
          '________________number_sense_and_algebra___interpreting_fractions',
        id: '7f021e1d-ef47-4cba-84f7-f59746f44c47',
        externalId: undefined,
        name: '--------------- Number sense and algebra - Interpreting fractions',
        terms: {},
      },
      inf1: {
        codename: 'inf1',
        id: 'f7a86a5e-bbbb-4cc5-9356-c40d68597593',
        externalId: undefined,
        name: 'InF1',
        terms: {},
      },
      inf2: {
        codename: 'inf2',
        id: '3f7a308f-fb11-473c-8533-b0c259c90b3d',
        externalId: undefined,
        name: 'InF2',
        terms: {},
      },
      inf3: {
        codename: 'inf3',
        id: 'fd3a78e3-dfff-4edb-9fb8-3e1672e43eae',
        externalId: undefined,
        name: 'InF3',
        terms: {},
      },
      inf4: {
        codename: 'inf4',
        id: 'a9d1599a-2119-4a69-8e2d-040838f80e83',
        externalId: undefined,
        name: 'InF4',
        terms: {},
      },
      inf5: {
        codename: 'inf5',
        id: 'e4193704-147e-4212-9918-80c98fb375ac',
        externalId: undefined,
        name: 'InF5',
        terms: {},
      },
      inf6: {
        codename: 'inf6',
        id: '70464f53-881a-47f8-bc52-992210cf3aaa',
        externalId: undefined,
        name: 'InF6',
        terms: {},
      },
      inf7: {
        codename: 'inf7',
        id: '85bf088f-be7b-4adc-a54d-4d83e1995823',
        externalId: undefined,
        name: 'InF7',
        terms: {},
      },
      inf8: {
        codename: 'inf8',
        id: 'cfdac407-126c-462d-8504-bdaeca4a5114',
        externalId: undefined,
        name: 'InF8',
        terms: {},
      },
      inf9: {
        codename: 'inf9',
        id: 'f78cfce0-59c3-4121-b978-46f89804a5d3',
        externalId: undefined,
        name: 'InF9',
        terms: {},
      },
      ________________number_sense_and_algebra___proportional_thinking: {
        codename:
          '________________number_sense_and_algebra___proportional_thinking',
        id: '5f72111d-0e09-4a58-897e-db4947130787',
        externalId: undefined,
        name: '--------------- Number sense and algebra - Proportional thinking',
        terms: {},
      },
      prt1: {
        codename: 'prt1',
        id: '3f146bb0-9da3-4b60-83bc-a94021513cd4',
        externalId: undefined,
        name: 'PrT1',
        terms: {},
      },
      prt2: {
        codename: 'prt2',
        id: '0670de90-e363-4b8e-ba4c-ce46cbbb74f9',
        externalId: undefined,
        name: 'PrT2',
        terms: {},
      },
      prt3: {
        codename: 'prt3',
        id: 'e59d68c1-8a66-40b1-9081-e1f812f34968',
        externalId: undefined,
        name: 'PrT3',
        terms: {},
      },
      prt4: {
        codename: 'prt4',
        id: '4da48498-7938-4b34-8e37-f45a59c47321',
        externalId: undefined,
        name: 'PrT4',
        terms: {},
      },
      prt5: {
        codename: 'prt5',
        id: '7f7eee2c-b896-4421-9338-c3e0851df364',
        externalId: undefined,
        name: 'PrT5',
        terms: {},
      },
      prt6: {
        codename: 'prt6',
        id: '306d1348-0b3a-4c75-ac45-88335db01a33',
        externalId: undefined,
        name: 'PrT6',
        terms: {},
      },
      prt7: {
        codename: 'prt7',
        id: '235fdb69-2991-415a-85fc-ef3ba2b86b56',
        externalId: undefined,
        name: 'PrT7',
        terms: {},
      },
      ________________number_sense_and_algebra___number_patterns_and_algebraic_thinking:
        {
          codename:
            '________________number_sense_and_algebra___number_patterns_and_algebraic_thinking',
          id: '18c50339-6dc5-47e4-a635-8a05d8f7cd34',
          externalId: undefined,
          name: '--------------- Number sense and algebra - Number patterns and algebraic thinking',
          terms: {},
        },
      npa1: {
        codename: 'npa1',
        id: '7037c700-8caa-4c10-9c2b-b2ba2afe1cc7',
        externalId: undefined,
        name: 'NPA1',
        terms: {},
      },
      npa2: {
        codename: 'npa2',
        id: 'c326f315-3a33-4134-a853-dafa3a3799b4',
        externalId: undefined,
        name: 'NPA2',
        terms: {},
      },
      npa3: {
        codename: 'npa3',
        id: 'f4d4a806-cdcd-49bd-9b49-7b2385d96392',
        externalId: undefined,
        name: 'NPA3',
        terms: {},
      },
      npa4: {
        codename: 'npa4',
        id: '299bf304-ec5b-4316-9f4b-5b714b46b659',
        externalId: undefined,
        name: 'NPA4',
        terms: {},
      },
      npa5: {
        codename: 'npa5',
        id: '019e873d-cb2a-48ca-bfaa-1a957606c4f3',
        externalId: undefined,
        name: 'NPA5',
        terms: {},
      },
      npa6: {
        codename: 'npa6',
        id: '38e79538-be0e-4114-9aa2-a3a6c9f5ea51',
        externalId: undefined,
        name: 'NPA6',
        terms: {},
      },
      npa7: {
        codename: 'npa7',
        id: 'dcb4783d-fbfe-408b-aace-23dcab02e634',
        externalId: undefined,
        name: 'NPA7',
        terms: {},
      },
      npa8: {
        codename: 'npa8',
        id: '40d0494b-7124-4bfb-9ef5-28c9984fa15d',
        externalId: undefined,
        name: 'NPA8',
        terms: {},
      },
      npa9: {
        codename: 'npa9',
        id: '80d66f34-6d98-48c9-ae54-279805bf447a',
        externalId: undefined,
        name: 'NPA9',
        terms: {},
      },
      ________________number_sense_and_algebra___understanding_money: {
        codename:
          '________________number_sense_and_algebra___understanding_money',
        id: '6400f8ae-dfaf-4783-8019-dad6b37565c1',
        externalId: undefined,
        name: '--------------- Number sense and algebra - Understanding money',
        terms: {},
      },
      unm1: {
        codename: 'unm1',
        id: '37b5aa77-1e42-4f3b-a818-2d8372b29b40',
        externalId: undefined,
        name: 'UnM1',
        terms: {},
      },
      unm2: {
        codename: 'unm2',
        id: '325c908c-6c67-4a7b-ba94-799a640245c5',
        externalId: undefined,
        name: 'UnM2',
        terms: {},
      },
      unm3: {
        codename: 'unm3',
        id: 'faa934a4-e83c-46a5-b421-0b61cbea0684',
        externalId: undefined,
        name: 'UnM3',
        terms: {},
      },
      unm4: {
        codename: 'unm4',
        id: 'e751abff-90ec-45c2-873b-cf6dba38a80c',
        externalId: undefined,
        name: 'UnM4',
        terms: {},
      },
      unm5: {
        codename: 'unm5',
        id: '63848447-7bf5-4383-8fec-d351bd76be26',
        externalId: undefined,
        name: 'UnM5',
        terms: {},
      },
      unm6: {
        codename: 'unm6',
        id: '3c8bcdc4-ffad-4077-95d0-0bf867ffbb03',
        externalId: undefined,
        name: 'UnM6',
        terms: {},
      },
      unm7: {
        codename: 'unm7',
        id: 'a8042c33-60d4-4067-b331-3104738d3289',
        externalId: undefined,
        name: 'UnM7',
        terms: {},
      },
      unm8: {
        codename: 'unm8',
        id: 'fc7cf9b7-801e-4a51-b9fe-708a4189258c',
        externalId: undefined,
        name: 'UnM8',
        terms: {},
      },
      unm9: {
        codename: 'unm9',
        id: '0903fbbb-3a72-41e8-a528-cf1fa8a7c314',
        externalId: undefined,
        name: 'UnM9',
        terms: {},
      },
      unm10: {
        codename: 'unm10',
        id: '78636064-1713-469a-9f1a-00219a70d081',
        externalId: undefined,
        name: 'UnM10',
        terms: {},
      },
      ________________measurement_and_geometry___understanding_units_of_measurement:
        {
          codename:
            '________________measurement_and_geometry___understanding_units_of_measurement',
          id: '140fd6da-1bfd-4bb3-991d-5677dec38eae',
          externalId: undefined,
          name: '--------------- Measurement and geometry - Understanding units of measurement',
          terms: {},
        },
      uum1: {
        codename: 'uum1',
        id: '0374e8e2-c4df-4e57-82be-3d4f23e979d5',
        externalId: undefined,
        name: 'UuM1',
        terms: {},
      },
      uum2: {
        codename: 'uum2',
        id: '060a44e6-f4fe-409a-ba7b-627722270980',
        externalId: undefined,
        name: 'UuM2',
        terms: {},
      },
      uum3: {
        codename: 'uum3',
        id: '8499e50d-dabd-4ef3-9564-7f675508a19d',
        externalId: undefined,
        name: 'UuM3',
        terms: {},
      },
      uum4: {
        codename: 'uum4',
        id: '678ed269-7b88-45bb-9b8a-7d89f10f7700',
        externalId: undefined,
        name: 'UuM4',
        terms: {},
      },
      uum5: {
        codename: 'uum5',
        id: '2f4cccaf-018a-4ab7-8fae-ad3b34e1bbe7',
        externalId: undefined,
        name: 'UuM5',
        terms: {},
      },
      uum6: {
        codename: 'uum6',
        id: '87ec8cc0-f2b6-4c7d-a96c-d162126b2c3c',
        externalId: undefined,
        name: 'UuM6',
        terms: {},
      },
      uum7: {
        codename: 'uum7',
        id: 'f9ebde0f-ab1a-4972-8633-b0dafa893d87',
        externalId: undefined,
        name: 'UuM7',
        terms: {},
      },
      uum8: {
        codename: 'uum8',
        id: 'a393d1cd-17ce-47d5-b81b-03240818be0a',
        externalId: undefined,
        name: 'UuM8',
        terms: {},
      },
      uum9: {
        codename: 'uum9',
        id: 'cdd6d825-e002-4d49-aaab-1f60d79cc2c6',
        externalId: undefined,
        name: 'UuM9',
        terms: {},
      },
      uum10: {
        codename: 'uum10',
        id: 'd641b3f0-f486-4fbc-af5e-c94bf6b34089',
        externalId: undefined,
        name: 'UuM10',
        terms: {},
      },
      ________________measurement_and_geometry___understanding_geometric_properties:
        {
          codename:
            '________________measurement_and_geometry___understanding_geometric_properties',
          id: '13f3d6e2-a29c-4479-a6f3-25b73e5385de',
          externalId: undefined,
          name: '--------------- Measurement and geometry - Understanding geometric properties',
          terms: {},
        },
      ugp1: {
        codename: 'ugp1',
        id: '9fe6fc86-42d7-430f-803c-55d0eb511215',
        externalId: undefined,
        name: 'UGP1',
        terms: {},
      },
      ugp2: {
        codename: 'ugp2',
        id: '2a0ff126-ea2d-4a3d-be55-c5b65e66c95a',
        externalId: undefined,
        name: 'UGP2',
        terms: {},
      },
      ugp3: {
        codename: 'ugp3',
        id: '253431b7-13a8-481c-876e-3ffe7f952800',
        externalId: undefined,
        name: 'UGP3',
        terms: {},
      },
      ugp4: {
        codename: 'ugp4',
        id: '3a066984-657e-489a-9bab-53e50a46ebfc',
        externalId: undefined,
        name: 'UGP4',
        terms: {},
      },
      ugp5: {
        codename: 'ugp5',
        id: '3e0da87b-efa8-474e-a535-32e98857a57a',
        externalId: undefined,
        name: 'UGP5',
        terms: {},
      },
      ugp6: {
        codename: 'ugp6',
        id: '5d8d7d05-3ef8-4eff-91ee-9ca6adab7cde',
        externalId: undefined,
        name: 'UGP6',
        terms: {},
      },
      ugp7: {
        codename: 'ugp7',
        id: 'b229a5f6-ef35-4eaa-955d-43ed6d6b7af9',
        externalId: undefined,
        name: 'UGP7',
        terms: {},
      },
      ________________measurement_and_geometry___positioning_and_locating: {
        codename:
          '________________measurement_and_geometry___positioning_and_locating',
        id: '9a73a3d6-dc6a-433b-85db-ca98d3cc2646',
        externalId: undefined,
        name: '--------------- Measurement and geometry - Positioning and locating',
        terms: {},
      },
      pol1: {
        codename: 'pol1',
        id: 'a629a10b-7d03-4ce4-89eb-7e8de06fe3f0',
        externalId: undefined,
        name: 'PoL1',
        terms: {},
      },
      pol2: {
        codename: 'pol2',
        id: '653a7ceb-5b34-43e2-b15f-00468df68d48',
        externalId: undefined,
        name: 'PoL2',
        terms: {},
      },
      pol3: {
        codename: 'pol3',
        id: '345d6dcb-c65e-4665-bd53-6b5ff54d1b85',
        externalId: undefined,
        name: 'PoL3',
        terms: {},
      },
      pol4: {
        codename: 'pol4',
        id: '87539fb5-d5f3-423b-8948-65ce07545846',
        externalId: undefined,
        name: 'PoL4',
        terms: {},
      },
      pol5: {
        codename: 'pol5',
        id: '9cafd68f-4bf2-4662-8b8d-f00d9b1ce4ec',
        externalId: undefined,
        name: 'PoL5',
        terms: {},
      },
      ________________measurement_and_geometry___measuring_time: {
        codename: '________________measurement_and_geometry___measuring_time',
        id: '777529c6-24f4-4fd9-9d3f-38133b0f2ecf',
        externalId: undefined,
        name: '--------------- Measurement and geometry - Measuring time',
        terms: {},
      },
      met1: {
        codename: 'met1',
        id: 'eb460315-3b8a-490a-a6e7-5612649db18a',
        externalId: undefined,
        name: 'MeT1',
        terms: {},
      },
      met2: {
        codename: 'met2',
        id: '36b1e5fd-63b5-4b40-9e03-9bc1d229ae8e',
        externalId: undefined,
        name: 'MeT2',
        terms: {},
      },
      met3: {
        codename: 'met3',
        id: 'eb6fc8da-129d-4374-9904-de66da868e32',
        externalId: undefined,
        name: 'MeT3',
        terms: {},
      },
      met4: {
        codename: 'met4',
        id: '9c5bc493-8e24-4aff-bdac-dbb8e370390f',
        externalId: undefined,
        name: 'MeT4',
        terms: {},
      },
      met5: {
        codename: 'met5',
        id: '915d0289-d3b1-41cd-be61-cb7ae87746ff',
        externalId: undefined,
        name: 'MeT5',
        terms: {},
      },
      met6: {
        codename: 'met6',
        id: 'fc222fc8-7b28-4701-9058-5c7a4029de03',
        externalId: undefined,
        name: 'MeT6',
        terms: {},
      },
      met7: {
        codename: 'met7',
        id: '14428650-9a66-446b-bd0e-4fa869ba101a',
        externalId: undefined,
        name: 'MeT7',
        terms: {},
      },
      ________________statistics_and_probability___understanding_chance: {
        codename:
          '________________statistics_and_probability___understanding_chance',
        id: 'd2589cb2-d9c5-4837-b789-019f5b039a51',
        externalId: undefined,
        name: '--------------- Statistics and probability - Understanding chance',
        terms: {},
      },
      unc1: {
        codename: 'unc1',
        id: '00b4c9ec-4b94-4cc2-882f-707fa50007d0',
        externalId: undefined,
        name: 'UnC1',
        terms: {},
      },
      unc2: {
        codename: 'unc2',
        id: 'a28c211d-3fb5-4afd-8ba8-20550e86adb0',
        externalId: undefined,
        name: 'UnC2',
        terms: {},
      },
      unc3: {
        codename: 'unc3',
        id: '4a87105f-818d-422c-8ed9-b98bb8a66610',
        externalId: undefined,
        name: 'UnC3',
        terms: {},
      },
      unc4: {
        codename: 'unc4',
        id: '605ca103-8368-4710-92dd-6f428dc07b93',
        externalId: undefined,
        name: 'UnC4',
        terms: {},
      },
      unc5: {
        codename: 'unc5',
        id: '2df5ef6f-a640-41d4-b63c-77c979d1d8ea',
        externalId: undefined,
        name: 'UnC5',
        terms: {},
      },
      unc6: {
        codename: 'unc6',
        id: '2deed13f-1df0-4974-a64b-5f2e997c1060',
        externalId: undefined,
        name: 'UnC6',
        terms: {},
      },
      ________________statistics_and_probability___interpreting_and_representing_data:
        {
          codename:
            '________________statistics_and_probability___interpreting_and_representing_data',
          id: '007382ab-f22e-487c-aebc-856135ad3b55',
          externalId: undefined,
          name: '--------------- Statistics and probability - Interpreting and representing data',
          terms: {},
        },
      ird1: {
        codename: 'ird1',
        id: '658de88a-577e-4505-a36d-52eb8d7777b9',
        externalId: undefined,
        name: 'IRD1',
        terms: {},
      },
      ird2: {
        codename: 'ird2',
        id: '24651d51-e606-4aa9-a420-06831ab40539',
        externalId: undefined,
        name: 'IRD2',
        terms: {},
      },
      ird3: {
        codename: 'ird3',
        id: '07c34817-9e39-41c1-94c5-ff0555fc4934',
        externalId: undefined,
        name: 'IRD3',
        terms: {},
      },
      ird4: {
        codename: 'ird4',
        id: 'ac84c41a-122e-419f-a913-3091f84f52d8',
        externalId: undefined,
        name: 'IRD4',
        terms: {},
      },
      ird5: {
        codename: 'ird5',
        id: 'ddbb0ed4-d4e7-4721-bf13-e1b1f7f7792c',
        externalId: undefined,
        name: 'IRD5',
        terms: {},
      },
      ird6: {
        codename: 'ird6',
        id: '87bd4a5d-a555-4001-a70d-677e3f74fbe7',
        externalId: undefined,
        name: 'IRD6',
        terms: {},
      },
      ird7: {
        codename: 'ird7',
        id: '3697f9d4-dd9f-42e9-972b-a3ddd05998ab',
        externalId: undefined,
        name: 'IRD7',
        terms: {},
      },
      ird8: {
        codename: 'ird8',
        id: '5807eab2-bee2-4c1e-b417-838909f1f2a5',
        externalId: undefined,
        name: 'IRD8',
        terms: {},
      },
    },
  },

  /**
   * Syllabus
   */
  syllabus: {
    codename: 'syllabus',
    id: '897afd5c-15be-46ab-97a6-4433bfaee097',
    externalId: undefined,
    name: 'Syllabus',
    terms: {
      k_10: {
        codename: 'k_10',
        id: '652f681f-dcdc-4fe8-bdfc-0bf993b2c215',
        externalId: undefined,
        name: '------------ K-10',
        terms: {},
      },
      aboriginal_lang_k_10_2022: {
        codename: 'aboriginal_lang_k_10_2022',
        id: '018c1cd5-5fb8-4911-acf3-84e3e6e603c7',
        externalId: undefined,
        name: 'Aboriginal Languages K–10',
        terms: {},
      },
      auslan_k_10_2023: {
        codename: 'auslan_k_10_2023',
        id: '520ee432-d0ad-49d4-a5d3-b57950e210b5',
        externalId: undefined,
        name: 'Auslan K–10',
        terms: {},
      },
      classical_lang_k_10_2022: {
        codename: 'classical_lang_k_10_2022',
        id: '26e17d57-809d-47c0-b8ca-8f045a7fd62d',
        externalId: undefined,
        name: 'Classical Languages K–10',
        terms: {},
      },
      creative_arts_k_6_2024: {
        codename: 'creative_arts_k_6_2024',
        id: 'e6e700f4-5d90-46ec-9b67-456b1ffa05fa',
        externalId: undefined,
        name: 'Creative Arts K–6',
        terms: {},
      },
      english_k_10_2022: {
        codename: 'english_k_10_2022',
        id: 'ba15c82e-2537-4b1a-8630-e31af0c4ffc2',
        externalId: undefined,
        name: 'English K–10',
        terms: {},
      },
      hsie_k_6_2024: {
        codename: 'hsie_k_6_2024',
        id: '50d96d05-ae68-4afa-a8f7-8ddb03534de8',
        externalId: undefined,
        name: 'Human Society and its Environment K–6',
        terms: {},
      },
      mathematics_k_10_2022: {
        codename: 'mathematics_k_10_2022',
        id: 'fa90d896-0d1d-4ecf-9cd0-022a5f50f0b6',
        externalId: undefined,
        name: 'Mathematics K–10',
        terms: {},
      },
      modern_lang_k_10_2022: {
        codename: 'modern_lang_k_10_2022',
        id: 'f0122027-a18b-4b27-9cac-cd0a8f55af53',
        externalId: undefined,
        name: 'Modern Languages K–10',
        terms: {},
      },
      pdhpe_k_10_2023: {
        codename: 'pdhpe_k_10_2023',
        id: '23efcaef-a4b9-4e11-ae5b-67013ff86714',
        externalId: undefined,
        name: 'Personal Development, Health and Physical Education K–10',
        terms: {},
      },
      personal_development__health_and_physical_education_k_6: {
        codename: 'personal_development__health_and_physical_education_k_6',
        id: '6809f26f-7d70-4743-be91-bef3b8f44084',
        externalId: undefined,
        name: 'Personal Development, Health and Physical Education K–6',
        terms: {},
      },
      personal_development__health_and_physical_education_7_10: {
        codename: 'personal_development__health_and_physical_education_7_10',
        id: '4f682724-1830-468d-bf51-91b0812b6ca7',
        externalId: undefined,
        name: 'Personal Development, Health and Physical Education 7–10',
        terms: {},
      },
      science_and_technology_k_6_2024: {
        codename: 'science_and_technology_k_6_2024',
        id: '97b29684-5907-47cb-8270-437560e41445',
        externalId: undefined,
        name: 'Science and Technology K–6',
        terms: {},
      },
      aboriginal_studies_7_10_2024: {
        codename: 'aboriginal_studies_7_10_2024',
        id: '64222d20-4f0a-43ba-9075-b0f9e04c9e2f',
        externalId: undefined,
        name: 'Aboriginal Studies 7–10',
        terms: {},
      },
      applied_tech_7_10_2023: {
        codename: 'applied_tech_7_10_2023',
        id: '4eedcabb-e2b2-4c57-b823-2088e2fbdcfb',
        externalId: undefined,
        name: 'Applied Technologies 7–10',
        terms: {},
      },
      child_7_10_2023: {
        codename: 'child_7_10_2023',
        id: '993f6c06-5ef3-49b6-91fa-d4e273a9fa44',
        externalId: undefined,
        name: 'Child Studies 7–10',
        terms: {},
      },
      commerce_7_10_2024: {
        codename: 'commerce_7_10_2024',
        id: 'e78e1e9d-a04c-4a5c-a26f-9a4e356be07f',
        externalId: undefined,
        name: 'Commerce 7–10',
        terms: {},
      },
      computing_7_10_2022: {
        codename: 'computing_7_10_2022',
        id: '357d85c5-249c-4224-af87-5226c7a7f06a',
        externalId: undefined,
        name: 'Computing Technology 7–10',
        terms: {},
      },
      dance_7_10_2023: {
        codename: 'dance_7_10_2023',
        id: 'faf433f5-4f92-4c70-90ab-3b77ded03155',
        externalId: undefined,
        name: 'Dance 7–10',
        terms: {},
      },
      drama_7_10_2023: {
        codename: 'drama_7_10_2023',
        id: '63716fd9-63d1-45aa-b222-adb04590cf8f',
        externalId: undefined,
        name: 'Drama 7–10',
        terms: {},
      },
      env_tech_7_10_2023: {
        codename: 'env_tech_7_10_2023',
        id: 'f6c832a1-4f65-4ee1-a9d7-8de3045dae82',
        externalId: undefined,
        name: 'Environmental Technologies 7–10',
        terms: {},
      },
      geo_ele_7_10_2023: {
        codename: 'geo_ele_7_10_2023',
        id: '17ab3282-49d5-4799-b70f-aded0971875b',
        externalId: undefined,
        name: 'Geography Elective 7–10',
        terms: {},
      },
      geography_7_10_2024: {
        codename: 'geography_7_10_2024',
        id: 'e411fc4f-4d46-4f3d-84c9-e4d8c9c7c8ce',
        externalId: undefined,
        name: 'Geography 7–10',
        terms: {},
      },
      history_ele_7_10_2023: {
        codename: 'history_ele_7_10_2023',
        id: '6591c752-7cd6-43c4-9be9-af9c686058ad',
        externalId: undefined,
        name: 'History Elective 7–10',
        terms: {},
      },
      history_7_10_2024: {
        codename: 'history_7_10_2024',
        id: '5f73a40f-7cfa-4f13-8514-25be4e7aff18',
        externalId: undefined,
        name: 'History 7–10',
        terms: {},
      },
      industrial_7_10_2023: {
        codename: 'industrial_7_10_2023',
        id: '437eb70c-d4bc-47e0-b729-2523b5b7cbbc',
        externalId: undefined,
        name: 'Industrial Technology 7–10',
        terms: {},
      },
      music_7_10_2024: {
        codename: 'music_7_10_2024',
        id: 'f971f6d0-cf11-4e2d-9bc4-2f14faac425b',
        externalId: undefined,
        name: 'Music 7–10',
        terms: {},
      },
      photo_media_7_10_2023: {
        codename: 'photo_media_7_10_2023',
        id: '3f353826-be8e-4bfd-beab-37ce8dc6364d',
        externalId: undefined,
        name: 'Photographic and Digital Media 7–10',
        terms: {},
      },
      pass_7_10_2023: {
        codename: 'pass_7_10_2023',
        id: 'b8dcecad-7184-4da7-9f26-f367f9d029ef',
        externalId: undefined,
        name: 'Physical Activity and Sports Studies 7–10',
        terms: {},
      },
      science_7_10_2023: {
        codename: 'science_7_10_2023',
        id: 'd29ad5fe-ed0c-47ce-bce3-257bcbbefd13',
        externalId: undefined,
        name: 'Science 7–10',
        terms: {},
      },
      technology_7_8_2023: {
        codename: 'technology_7_8_2023',
        id: 'abd5b9f1-cafc-40c7-8390-5bd3bd31c7ef',
        externalId: undefined,
        name: 'Technology 7–8',
        terms: {},
      },
      agricultural_tech_7_10_2023: {
        codename: 'agricultural_tech_7_10_2023',
        id: 'c7d57e9c-b9cc-4966-b19d-e49006da5ca8',
        externalId: undefined,
        name: 'Agricultural Technology 7–10',
        terms: {},
      },
      visual_arts_7_10_2024: {
        codename: 'visual_arts_7_10_2024',
        id: '995a7436-6b04-4ab1-a1e9-24d60c64f6a1',
        externalId: undefined,
        name: 'Visual Arts 7–10',
        terms: {},
      },
      visual_design_7_10_2023: {
        codename: 'visual_design_7_10_2023',
        id: '38777494-7a0d-40d4-a1ee-0fce43f1590f',
        externalId: undefined,
        name: 'Visual Design 7–10',
        terms: {},
      },
      work_edu_7_10_2023: {
        codename: 'work_edu_7_10_2023',
        id: 'a5ec4141-824e-4441-b224-83715807ac24',
        externalId: undefined,
        name: 'Work Education 7–10',
        terms: {},
      },
      n11_12_english: {
        codename: 'n11_12_english',
        id: '47f016a6-94d0-4513-a14f-0f8507c81621',
        externalId: undefined,
        name: '------------ 11-12 English',
        terms: {},
      },
      english_advanced_11_12_2024: {
        codename: 'english_advanced_11_12_2024',
        id: '21d52843-da47-4c62-8a87-68d6fadead26',
        externalId: undefined,
        name: 'English Advanced 11–12',
        terms: {},
      },
      english_eald_11_12_2024: {
        codename: 'english_eald_11_12_2024',
        id: '438840f3-e4c8-4e02-9bbf-e070756550b2',
        externalId: undefined,
        name: 'English EAL/D 11–12',
        terms: {},
      },
      english_extension_11_12_2024: {
        codename: 'english_extension_11_12_2024',
        id: '9dd50861-edc1-4e15-91a0-683c0315180f',
        externalId: undefined,
        name: 'English Extension 11–12',
        terms: {},
      },
      english_life_skills_11_12_2024: {
        codename: 'english_life_skills_11_12_2024',
        id: '36145e1f-ecc4-42ee-a548-e1a9143afc5f',
        externalId: undefined,
        name: 'English Life Skills 11–12',
        terms: {},
      },
      english_standard_11_12_2024: {
        codename: 'english_standard_11_12_2024',
        id: '683caf03-4cbd-4908-87ec-02e9f0d920d0',
        externalId: undefined,
        name: 'English Standard 11–12',
        terms: {},
      },
      english_studies_11_12_2024: {
        codename: 'english_studies_11_12_2024',
        id: '949d93dc-09fa-41aa-af38-c2560a8a07bc',
        externalId: undefined,
        name: 'English Studies 11–12',
        terms: {},
      },
      n11_12_mathematics: {
        codename: 'n11_12_mathematics',
        id: 'a6317d46-51c3-4ca9-b565-a6dbe916d835',
        externalId: undefined,
        name: '------------ 11-12 Mathematics',
        terms: {},
      },
      mathematics_advanced_11_12_2024: {
        codename: 'mathematics_advanced_11_12_2024',
        id: '9666ff33-fe88-455c-b663-3ed5f2b20801',
        externalId: undefined,
        name: 'Mathematics Advanced 11–12',
        terms: {},
      },
      mathematics_extension_1_11_12_2024: {
        codename: 'mathematics_extension_1_11_12_2024',
        id: '88df3ef8-7549-41d0-965c-868982b6163f',
        externalId: undefined,
        name: 'Mathematics Extension 1 11–12',
        terms: {},
      },
      mathematics_extension_2_11_12_2024: {
        codename: 'mathematics_extension_2_11_12_2024',
        id: '75622479-ef58-45a8-9261-4714deb6c8af',
        externalId: undefined,
        name: 'Mathematics Extension 2 11–12',
        terms: {},
      },
      mathematics_life_skills_11_12_2024: {
        codename: 'mathematics_life_skills_11_12_2024',
        id: 'ee6241bc-630c-4c06-9d88-840880208458',
        externalId: undefined,
        name: 'Mathematics Life Skills 11–12',
        terms: {},
      },
      mathematics_standard_11_12_2024: {
        codename: 'mathematics_standard_11_12_2024',
        id: '8aab6e0c-c3ff-4c89-9458-cd1ce6b5f02f',
        externalId: undefined,
        name: 'Mathematics Standard 11–12',
        terms: {},
      },
      numeracy_11_12_2023: {
        codename: 'numeracy_11_12_2023',
        id: 'a7acc8cf-592c-4281-b23d-98074d68188d',
        externalId: undefined,
        name: 'Numeracy 11–12',
        terms: {},
      },
      n11_12_science: {
        codename: 'n11_12_science',
        id: '948f55e7-3d15-47e9-a98b-f263787770f1',
        externalId: undefined,
        name: '------------ 11–12 Science',
        terms: {},
      },
      biology_11_12_2023: {
        codename: 'biology_11_12_2023',
        id: '26476fe3-4c6a-437b-bc1b-3890349d8549',
        externalId: undefined,
        name: 'Biology 11–12',
        terms: {},
      },
      chemical_ls_11_12_2023: {
        codename: 'chemical_ls_11_12_2023',
        id: 'a853c602-0520-4456-b204-0786c689bcd2',
        externalId: undefined,
        name: 'Chemical World Science Life Skills 11–12',
        terms: {},
      },
      chemistry_11_12_2023: {
        codename: 'chemistry_11_12_2023',
        id: '54a0ebbf-1e8b-416a-b6be-0527c025ed30',
        externalId: undefined,
        name: 'Chemistry 11–12',
        terms: {},
      },
      earth_11_12_2023: {
        codename: 'earth_11_12_2023',
        id: '32e9e709-075f-49a3-938b-155bd79a3aed',
        externalId: undefined,
        name: 'Earth and Environmental Science 11–12',
        terms: {},
      },
      earth_ls_11_12_2023: {
        codename: 'earth_ls_11_12_2023',
        id: 'a25174f6-4f31-4fea-8ca1-47c1d9e6cec6',
        externalId: undefined,
        name: 'Earth and Space Science Life Skills 11–12',
        terms: {},
      },
      inv_science_11_12_2023: {
        codename: 'inv_science_11_12_2023',
        id: '2c12de6b-d706-438e-b1e3-c8d083f2d014',
        externalId: undefined,
        name: 'Investigating Science 11–12',
        terms: {},
      },
      inv_science_ls_11_12_2023: {
        codename: 'inv_science_ls_11_12_2023',
        id: '457375b3-f896-491b-ab44-02f68c198bf0',
        externalId: undefined,
        name: 'Investigating Science Life Skills 11–12',
        terms: {},
      },
      living_science_ls_11_12_2023: {
        codename: 'living_science_ls_11_12_2023',
        id: '00ae98ff-9acf-43a5-ae38-ceabeb0d7347',
        externalId: undefined,
        name: 'Living World Science Life Skills 11–12',
        terms: {},
      },
      physical_sci_ls_11_12_2023: {
        codename: 'physical_sci_ls_11_12_2023',
        id: '8a5024c3-bb2e-48be-a843-a3df420537c6',
        externalId: undefined,
        name: 'Physical World Science Life Skills 11–12',
        terms: {},
      },
      physics_11_12_2023: {
        codename: 'physics_11_12_2023',
        id: '2f80e7d0-937f-4191-8bfc-d2f03538543d',
        externalId: undefined,
        name: 'Physics 11–12',
        terms: {},
      },
      science_ext_11_12_2023: {
        codename: 'science_ext_11_12_2023',
        id: '93f6a321-033f-4f52-96e9-851b283dd207',
        externalId: undefined,
        name: 'Science Extension 11–12',
        terms: {},
      },
      n11_12_technologies: {
        codename: 'n11_12_technologies',
        id: 'a58feff5-ea72-4cc5-ad45-33dfe5bfaca0',
        externalId: undefined,
        name: '------------ 11–12 Technologies',
        terms: {},
      },
      agriculture_11_12_2023: {
        codename: 'agriculture_11_12_2023',
        id: '22a39ac2-48e9-4206-84cb-0df0fa726ccb',
        externalId: undefined,
        name: 'Agriculture 11–12',
        terms: {},
      },
      agriculture_ls_11_12_2023: {
        codename: 'agriculture_ls_11_12_2023',
        id: '4d354e48-9170-4075-ba0e-ae34ee4e36ee',
        externalId: undefined,
        name: 'Agriculture Life Skills 11–12',
        terms: {},
      },
      computing_ls_11_12_2022: {
        codename: 'computing_ls_11_12_2022',
        id: '30f27450-a5ed-4d12-af8a-75e30809a8d4',
        externalId: undefined,
        name: 'Computing Technology Life Skills 11–12',
        terms: {},
      },
      design_11_12_2023: {
        codename: 'design_11_12_2023',
        id: '9feea61e-67d9-4f08-9fad-300a2b1fc2a3',
        externalId: undefined,
        name: 'Design and Technology 11–12',
        terms: {},
      },
      design_ls_11_12_2023: {
        codename: 'design_ls_11_12_2023',
        id: '6bcded62-0035-4d6e-ac43-19f93346d144',
        externalId: undefined,
        name: 'Design and Technology Life Skills 11–12',
        terms: {},
      },
      engineering_11_12_2023: {
        codename: 'engineering_11_12_2023',
        id: '4c241667-fbd4-45d5-be8c-982fdb84f0d6',
        externalId: undefined,
        name: 'Engineering Studies 11–12',
        terms: {},
      },
      ent_computing_11_12_2022: {
        codename: 'ent_computing_11_12_2022',
        id: '5ceea227-6cde-42bf-a98f-0c471f4ebbec',
        externalId: undefined,
        name: 'Enterprise Computing 11–12',
        terms: {},
      },
      food_tech_11_12_2023: {
        codename: 'food_tech_11_12_2023',
        id: 'e273fe97-2393-492d-9874-c0b1c6f5b08f',
        externalId: undefined,
        name: 'Food Technology 11–12',
        terms: {},
      },
      food_tech_ls_11_12_2023: {
        codename: 'food_tech_ls_11_12_2023',
        id: '0e20b9de-88b9-4809-a4a8-81150755070d',
        externalId: undefined,
        name: 'Food Technology Life Skills 11–12',
        terms: {},
      },
      industrial_11_12_2023: {
        codename: 'industrial_11_12_2023',
        id: '8651f26a-0695-4f99-baeb-5d17859da687',
        externalId: undefined,
        name: 'Industrial Technology 11–12',
        terms: {},
      },
      industrial_ls_11_12_2023: {
        codename: 'industrial_ls_11_12_2023',
        id: 'd1b9e7c3-9571-4e8e-8dc5-65a616aa955c',
        externalId: undefined,
        name: 'Industrial Technology Life Skills 11–12',
        terms: {},
      },
      marine_11_12_2023: {
        codename: 'marine_11_12_2023',
        id: 'e3c1c087-fa2f-4720-b66d-78a0dab7e9f7',
        externalId: undefined,
        name: 'Marine Studies 11–12',
        terms: {},
      },
      software_11_12_2022: {
        codename: 'software_11_12_2022',
        id: '86282ed6-1c95-436d-8d54-4f6d40da07b8',
        externalId: undefined,
        name: 'Software Engineering 11–12',
        terms: {},
      },
      technology_life_skills__2023_: {
        codename: 'technology_life_skills__2023_',
        id: 'd6f9bdf2-3925-4df6-87e1-6fe60ee09108',
        externalId: undefined,
        name: 'Technology Life Skills 11–12',
        terms: {},
      },
      textiles_11_12_2023: {
        codename: 'textiles_11_12_2023',
        id: 'a0b24ced-bb53-419a-a6f8-2f99d73e577b',
        externalId: undefined,
        name: 'Textiles and Design 11–12',
        terms: {},
      },
      textiles_ls_11_12_2023: {
        codename: 'textiles_ls_11_12_2023',
        id: '49b2f74c-6706-415b-9c65-40b58c28caf8',
        externalId: undefined,
        name: 'Textiles and Design Life Skills 11–12',
        terms: {},
      },
      n11_12_hsie: {
        codename: 'n11_12_hsie',
        id: '98584528-5fe0-4fec-99be-846999bbd8b9',
        externalId: undefined,
        name: '------------ 11-12 HSIE',
        terms: {},
      },
      aboriginal_11_12_2023: {
        codename: 'aboriginal_11_12_2023',
        id: 'dbe07678-980d-4d52-ab5d-bb41e4f9d011',
        externalId: undefined,
        name: 'Aboriginal Studies 11–12',
        terms: {},
      },
      aboriginal_ls_11_12_2023: {
        codename: 'aboriginal_ls_11_12_2023',
        id: '80753681-2fa1-42be-9645-0180f5664bb2',
        externalId: undefined,
        name: 'Aboriginal Studies Life Skills 11–12',
        terms: {},
      },
      ancient_history_11_12_2024: {
        codename: 'ancient_history_11_12_2024',
        id: '968da091-4977-4c9e-9c77-c9e4e7a8144d',
        externalId: undefined,
        name: 'Ancient History 11–12',
        terms: {},
      },
      ancient_history_ls_11_12_2024: {
        codename: 'ancient_history_ls_11_12_2024',
        id: 'af16fc46-c66e-4e74-a03f-dca3a4b1efc9',
        externalId: undefined,
        name: 'Ancient History Life Skills 11–12',
        terms: {},
      },
      business_ls_11_12_2023: {
        codename: 'business_ls_11_12_2023',
        id: '607c3a75-5e21-43fb-8bb4-7bfe7ca464bd',
        externalId: undefined,
        name: 'Business and Economics Life Skills 11–12',
        terms: {},
      },
      business_11_12_2023: {
        codename: 'business_11_12_2023',
        id: 'a3ccd33e-eca7-4e80-b467-0ccd8d42113e',
        externalId: undefined,
        name: 'Business Studies 11–12',
        terms: {},
      },
      economics_11_12_2023: {
        codename: 'economics_11_12_2023',
        id: '9ba17921-884c-4113-85cb-e8cb079c325b',
        externalId: undefined,
        name: 'Economics 11–12',
        terms: {},
      },
      geography_11_12_2022: {
        codename: 'geography_11_12_2022',
        id: 'd4287f85-c716-4ba6-9109-695c88e92849',
        externalId: undefined,
        name: 'Geography 11–12',
        terms: {},
      },
      geography_ls_11_12_2022: {
        codename: 'geography_ls_11_12_2022',
        id: 'd1ce3af1-b920-499e-b1d0-2aa1c65f7687',
        externalId: undefined,
        name: 'Geography Life Skills 11–12',
        terms: {},
      },
      history_extension_11_12_2024: {
        codename: 'history_extension_11_12_2024',
        id: 'ceceb0e1-a711-4950-b913-e417edccad96',
        externalId: undefined,
        name: 'History Extension 11–12',
        terms: {},
      },
      hsie_ls_11_12_2023: {
        codename: 'hsie_ls_11_12_2023',
        id: '0a2f81de-23d6-4c45-832c-9aad42f28ffc',
        externalId: undefined,
        name: 'Human Society and its Environment Life Skills 11–12',
        terms: {},
      },
      legal_11_12_2023: {
        codename: 'legal_11_12_2023',
        id: 'ede52d2c-3032-4f0d-ae87-1e4a942957d7',
        externalId: undefined,
        name: 'Legal Studies 11–12',
        terms: {},
      },
      legal_ls_11_12_2023: {
        codename: 'legal_ls_11_12_2023',
        id: '3b6be803-4582-498f-abeb-eb25b22eb767',
        externalId: undefined,
        name: 'Legal Studies Life Skills 11–12',
        terms: {},
      },
      modern_history_11_12_2024: {
        codename: 'modern_history_11_12_2024',
        id: '541f7259-0c79-4278-99b4-7c7d6e22d7a0',
        externalId: undefined,
        name: 'Modern History 11–12',
        terms: {},
      },
      modern_history_ls_11_12_2024: {
        codename: 'modern_history_ls_11_12_2024',
        id: '589925d0-9592-4063-813c-85d335a055be',
        externalId: undefined,
        name: 'Modern History Life Skills 11–12',
        terms: {},
      },
      society_11_12_2023: {
        codename: 'society_11_12_2023',
        id: '358d1da4-0cec-4a7d-bc67-c575c1e64a85',
        externalId: undefined,
        name: 'Society and Culture 11–12',
        terms: {},
      },
      society_ls_11_12_2023: {
        codename: 'society_ls_11_12_2023',
        id: '1f5d08de-fda1-43f8-ad72-64ebf388c925',
        externalId: undefined,
        name: 'Society and Culture Life Skills 11–12',
        terms: {},
      },
      religion_i_11_12_2023: {
        codename: 'religion_i_11_12_2023',
        id: '471c269d-8b79-4c58-b3d4-2e3b59ebf764',
        externalId: undefined,
        name: 'Studies of Religion 1 11–12',
        terms: {},
      },
      religion_i_ls_11_12_2023: {
        codename: 'religion_i_ls_11_12_2023',
        id: 'c0e597c1-f782-41ce-a517-c0a256fbfc2d',
        externalId: undefined,
        name: 'Studies of Religion 1 Life Skills 11–12',
        terms: {},
      },
      religion_ii_11_12_2023: {
        codename: 'religion_ii_11_12_2023',
        id: 'fb96f38b-67ad-4b53-87ee-e9bbcb318aaf',
        externalId: undefined,
        name: 'Studies of Religion 2 11–12',
        terms: {},
      },
      religion_ii_ls_11_12_2023: {
        codename: 'religion_ii_ls_11_12_2023',
        id: '55de6a33-6486-4a34-b8b8-400d669bf3f3',
        externalId: undefined,
        name: 'Studies of Religion 2 Life Skills 11–12',
        terms: {},
      },
      work_ls_11_12_2023: {
        codename: 'work_ls_11_12_2023',
        id: '2f5a4b63-987f-46c1-970b-810ec5be3c69',
        externalId: undefined,
        name: 'Work and the Community Life Skills 11–12',
        terms: {},
      },
      work_studies_11_12_2023: {
        codename: 'work_studies_11_12_2023',
        id: 'fea219aa-2497-42db-a130-34b027958ec3',
        externalId: undefined,
        name: 'Work Studies 11–12',
        terms: {},
      },
      n11_12_creatve_arts: {
        codename: 'n11_12_creatve_arts',
        id: '6d0a2dc8-f2c4-4063-9333-0ab81eacd4d9',
        externalId: undefined,
        name: '------------ 11-12 Creatve Arts',
        terms: {},
      },
      arts_ls_11_12_2023: {
        codename: 'arts_ls_11_12_2023',
        id: '8de24cdc-3ee9-47ac-87ed-b20d29b75c78',
        externalId: undefined,
        name: 'Creative Arts Life Skills 11–12',
        terms: {},
      },
      dance_11_12_2023: {
        codename: 'dance_11_12_2023',
        id: 'b623eb0d-765d-4c51-ab21-6ad04d63d699',
        externalId: undefined,
        name: 'Dance 11–12',
        terms: {},
      },
      dance_ls_11_12_2023: {
        codename: 'dance_ls_11_12_2023',
        id: '3917047b-4605-4737-8640-2514ee0a83cd',
        externalId: undefined,
        name: 'Dance Life Skills 11–12',
        terms: {},
      },
      drama_11_12_2023: {
        codename: 'drama_11_12_2023',
        id: '0ea09e16-8acb-4c09-b5cf-03a93daa2289',
        externalId: undefined,
        name: 'Drama 11–12',
        terms: {},
      },
      drama_ls_11_12_2023: {
        codename: 'drama_ls_11_12_2023',
        id: '052879e8-b7de-4ee2-97ee-8c8ac065b40d',
        externalId: undefined,
        name: 'Drama Life Skills 11–12',
        terms: {},
      },
      music_i_11_12_2023: {
        codename: 'music_i_11_12_2023',
        id: 'eb2274ab-b9de-4eec-956d-24aa7c3304fc',
        externalId: undefined,
        name: 'Music 1 11–12',
        terms: {},
      },
      music_ii_11_12_2023: {
        codename: 'music_ii_11_12_2023',
        id: '98db91ab-94b2-4cdd-9cc6-0922d405ceaf',
        externalId: undefined,
        name: 'Music 2 11–12',
        terms: {},
      },
      music_ext_11_12_2023: {
        codename: 'music_ext_11_12_2023',
        id: 'd99edc93-be0a-464e-a5a3-37f9bd4638f2',
        externalId: undefined,
        name: 'Music Extension 11–12',
        terms: {},
      },
      music_ls_11_12_2023: {
        codename: 'music_ls_11_12_2023',
        id: '12892c6f-12f7-4407-abe6-24b57db51e55',
        externalId: undefined,
        name: 'Music Life Skills 11–12',
        terms: {},
      },
      photo_video_11_12_2023: {
        codename: 'photo_video_11_12_2023',
        id: '6914554a-44ba-46d9-80ab-c1905f139072',
        externalId: undefined,
        name: 'Photography, Video and Digital Imaging 11–12',
        terms: {},
      },
      visual_arts_11_12_2023: {
        codename: 'visual_arts_11_12_2023',
        id: '8ed943f2-c072-4180-acac-60c3c004e107',
        externalId: undefined,
        name: 'Visual Arts 11–12',
        terms: {},
      },
      visual_arts_ls_11_12_2023: {
        codename: 'visual_arts_ls_11_12_2023',
        id: '084b8668-d166-49e6-a078-eb5c4c04f403',
        externalId: undefined,
        name: 'Visual Arts Life Skills 11–12',
        terms: {},
      },
      visual_design_11_12_2023: {
        codename: 'visual_design_11_12_2023',
        id: '111f71ca-242b-4b04-9957-f89b2e2af447',
        externalId: undefined,
        name: 'Visual Design 11–12',
        terms: {},
      },
      n11_12_pdhpe_: {
        codename: 'n11_12_pdhpe_',
        id: '2239860a-6829-4026-a3bf-86c7fe9fee3a',
        externalId: undefined,
        name: '------------ 11-12 PDHPE ',
        terms: {},
      },
      cfs_11_12_2023: {
        codename: 'cfs_11_12_2023',
        id: '7b157e49-4231-4940-bad4-428f174d9d96',
        externalId: undefined,
        name: 'Community and Family Studies 11–12',
        terms: {},
      },
      cfs_ls_11_12_2023: {
        codename: 'cfs_ls_11_12_2023',
        id: 'e8d9df74-dc32-4f1e-8b27-7d4b04bfc739',
        externalId: undefined,
        name: 'Community and Family Studies Life Skills 11–12',
        terms: {},
      },
      childhood_11_12_2023: {
        codename: 'childhood_11_12_2023',
        id: '0e068b34-84e6-4f5d-96b1-3eb19741f95d',
        externalId: undefined,
        name: 'Exploring Early Childhood 11–12',
        terms: {},
      },
      health_11_12_2022: {
        codename: 'health_11_12_2022',
        id: '15a2f3b7-2808-4d4d-83df-5de680cdd947',
        externalId: undefined,
        name: 'Health and Movement Science 11–12',
        terms: {},
      },
      health_ls_11_12_2022: {
        codename: 'health_ls_11_12_2022',
        id: 'fe9150f8-ebef-4721-ba7a-5e2e974f45b9',
        externalId: undefined,
        name: 'Health and Movement Science Life Skills 11–12',
        terms: {},
      },
      sport_11_12_2023: {
        codename: 'sport_11_12_2023',
        id: 'faa86525-523b-47a3-a859-a265705203ca',
        externalId: undefined,
        name: 'Sport, Lifestyle and Recreation Studies 11–12',
        terms: {},
      },
      _____________11_12_vet: {
        codename: '_____________11_12_vet',
        id: '0794b075-6387-4fe5-91e1-5079a8514228',
        externalId: undefined,
        name: '------------ 11-12 VET',
        terms: {},
      },
      auto_vet_11_12_2023: {
        codename: 'auto_vet_11_12_2023',
        id: '90c8887d-964b-4543-89dc-0153619d9ac5',
        externalId: undefined,
        name: 'Automotive',
        terms: {},
      },
      bus_vet_11_12_2023: {
        codename: 'bus_vet_11_12_2023',
        id: '219f7f25-9f35-4d94-a514-e29630474ff3',
        externalId: undefined,
        name: 'Business Services',
        terms: {},
      },
      con_vet_11_12_2023: {
        codename: 'con_vet_11_12_2023',
        id: '7b797af6-21bf-4be0-82b2-7e81144b1dae',
        externalId: undefined,
        name: 'Construction',
        terms: {},
      },
      elec_vet_11_12_2023: {
        codename: 'elec_vet_11_12_2023',
        id: '29b672bb-01d0-43f3-999e-3aaa7e326744',
        externalId: undefined,
        name: 'Electrotechnology',
        terms: {},
      },
      info_vet_11_12_2023: {
        codename: 'info_vet_11_12_2023',
        id: '32e1c134-d4be-4954-a736-882f066998d4',
        externalId: undefined,
        name: 'Information and Digital Technology',
        terms: {},
      },
      marine_tech_7_10_2023: {
        codename: 'marine_tech_7_10_2023',
        id: 'ea018853-8a7f-4504-9589-e0c7ac22415d',
        externalId: undefined,
        name: 'Marine and Aquaculture Technology 7–10',
        terms: {},
      },
      engineering_tech_7_10_2024: {
        codename: 'engineering_tech_7_10_2024',
        id: '26a72a09-3356-4afc-b093-dea153634c07',
        externalId: undefined,
        name: 'Engineering Technology 7–10',
        terms: {},
      },
      manufacturing_tech_7_10_2024: {
        codename: 'manufacturing_tech_7_10_2024',
        id: '4aaadcf6-ff54-42e3-b033-a07303d579f0',
        externalId: undefined,
        name: 'Manufacturing Technology 7–10',
        terms: {},
      },
      textiles_tech_7_10_2024: {
        codename: 'textiles_tech_7_10_2024',
        id: 'fb1e48b9-bc4c-4a70-9472-683ca774c389',
        externalId: undefined,
        name: 'Textiles Technology 7–10',
        terms: {},
      },
      food_tech_7_10_2024: {
        codename: 'food_tech_7_10_2024',
        id: '2f6401a5-2555-47e5-b8fa-28f178bf71c7',
        externalId: undefined,
        name: 'Food Technology 7–10',
        terms: {},
      },
      digital_graphics_tech_7_10_2024: {
        codename: 'digital_graphics_tech_7_10_2024',
        id: '5d077cac-923e-4b10-bab9-75688df47b69',
        externalId: undefined,
        name: 'Digital Graphics Technology 7–10',
        terms: {},
      },
      design_tech_7_10_2024: {
        codename: 'design_tech_7_10_2024',
        id: 'fd4f4257-b3b4-493e-a238-09db5104f847',
        externalId: undefined,
        name: 'Design Technology 7–10',
        terms: {},
      },
    },
  },

  /**
   * National Literacy Learning Progression
   */
  literacy: {
    codename: 'literacy',
    id: 'e09296c0-b133-42da-90c6-122f76013150',
    externalId: undefined,
    name: 'National Literacy Learning Progression',
    terms: {
      ________________speaking_and_listening___listening: {
        codename: '________________speaking_and_listening___listening',
        id: 'cb1130a3-2790-40e1-bf63-d05752527aad',
        externalId: undefined,
        name: '--------------- Speaking and listening - Listening',
        terms: {},
      },
      ecl1a: {
        codename: 'ecl1a',
        id: '5f0279a1-6e22-4a1f-ab63-c801e9533b08',
        externalId: undefined,
        name: 'ECL1a',
        terms: {},
      },
      ecl1b: {
        codename: 'ecl1b',
        id: '429336b7-90bd-46f1-97e0-efebfb40b0a1',
        externalId: undefined,
        name: 'ECL1b',
        terms: {},
      },
      lis1: {
        codename: 'lis1',
        id: '849e358c-2c35-4095-9f67-6a7074e1db30',
        externalId: undefined,
        name: 'LiS1',
        terms: {},
      },
      lis2: {
        codename: 'lis2',
        id: 'a9e8a78a-3c27-4825-825c-bda8f92f4feb',
        externalId: undefined,
        name: 'LiS2',
        terms: {},
      },
      lis3: {
        codename: 'lis3',
        id: 'f8f826e6-0b85-442b-8d28-43113b826b3a',
        externalId: undefined,
        name: 'LiS3',
        terms: {},
      },
      lis4: {
        codename: 'lis4',
        id: 'e1db2697-30e0-4a3f-bafd-f410118f7310',
        externalId: undefined,
        name: 'LiS4',
        terms: {},
      },
      lis5: {
        codename: 'lis5',
        id: 'f4d1bd81-0763-4287-b4e1-398f69b6975a',
        externalId: undefined,
        name: 'LiS5',
        terms: {},
      },
      lis6: {
        codename: 'lis6',
        id: '7f8a42ec-056f-4cd8-ace8-582cf0fdd3fb',
        externalId: undefined,
        name: 'LiS6',
        terms: {},
      },
      lis7: {
        codename: 'lis7',
        id: '8f788e95-ebf4-4249-b4e2-b05c56e2bbe5',
        externalId: undefined,
        name: 'LiS7',
        terms: {},
      },
      lis8: {
        codename: 'lis8',
        id: '9e2318f6-4ee1-405d-8290-56fb6d240ea3',
        externalId: undefined,
        name: 'LiS8',
        terms: {},
      },
      ________________speaking_and_listening___interacting: {
        codename: '________________speaking_and_listening___interacting',
        id: '35c0881d-364d-4eef-9459-ca8f6362031e',
        externalId: undefined,
        name: '--------------- Speaking and listening - Interacting',
        terms: {},
      },
      eci1a: {
        codename: 'eci1a',
        id: 'b533818b-b71d-4113-9986-22b471f91f39',
        externalId: undefined,
        name: 'ECI1a',
        terms: {},
      },
      eci1b: {
        codename: 'eci1b',
        id: 'fea743ce-e14e-44b3-b82a-782e72c8f873',
        externalId: undefined,
        name: 'ECI1b',
        terms: {},
      },
      int1: {
        codename: 'int1',
        id: '611d63a1-8c84-4a66-80aa-0c17d0214a18',
        externalId: undefined,
        name: 'InT1',
        terms: {},
      },
      int2: {
        codename: 'int2',
        id: 'ab181362-ab01-438a-9b8e-56def80f4683',
        externalId: undefined,
        name: 'InT2',
        terms: {},
      },
      int3: {
        codename: 'int3',
        id: '6a68f548-5a51-488b-8c01-20a20c4dc5b5',
        externalId: undefined,
        name: 'InT3',
        terms: {},
      },
      int4: {
        codename: 'int4',
        id: '9305ea23-e689-4c02-ae9c-14945eae7f4d',
        externalId: undefined,
        name: 'InT4',
        terms: {},
      },
      int5: {
        codename: 'int5',
        id: 'd791d21e-b8e8-4bd0-b084-8c4f9dec8ff3',
        externalId: undefined,
        name: 'InT5',
        terms: {},
      },
      int6: {
        codename: 'int6',
        id: '959f8a0b-0661-47ea-b4d1-965df5b1f598',
        externalId: undefined,
        name: 'InT6',
        terms: {},
      },
      int7: {
        codename: 'int7',
        id: 'd2907aae-f767-4c83-b03e-ac8b1520647f',
        externalId: undefined,
        name: 'InT7',
        terms: {},
      },
      ________________speaking_and_listening___speaking: {
        codename: '________________speaking_and_listening___speaking',
        id: '05695104-55a6-401f-9ccc-a7679d7832b5',
        externalId: undefined,
        name: '--------------- Speaking and listening - Speaking',
        terms: {},
      },
      ecs1a: {
        codename: 'ecs1a',
        id: 'f4f724c1-6fbb-4662-9fa8-5b0f3a5af28c',
        externalId: undefined,
        name: 'ECS1a',
        terms: {},
      },
      ecs1b: {
        codename: 'ecs1b',
        id: '988b8a32-c876-40d7-9051-bbfb595393b6',
        externalId: undefined,
        name: 'ECS1b',
        terms: {},
      },
      ecs1c: {
        codename: 'ecs1c',
        id: 'b4acd60f-3441-449c-9c41-2dbe46268dc2',
        externalId: undefined,
        name: 'ECS1c',
        terms: {},
      },
      spk1: {
        codename: 'spk1',
        id: '8c800a0a-ca15-4ca3-9aa3-c28e63372c0c',
        externalId: undefined,
        name: 'SpK1',
        terms: {},
      },
      spk2: {
        codename: 'spk2',
        id: 'c6be644d-742f-48d1-ae33-c17f71b504f2',
        externalId: undefined,
        name: 'SpK2',
        terms: {},
      },
      spk3: {
        codename: 'spk3',
        id: '21eeec8f-faa1-459b-8802-334ac56870ec',
        externalId: undefined,
        name: 'SpK3',
        terms: {},
      },
      spk4: {
        codename: 'spk4',
        id: 'f8f8945d-9e55-48d6-8b51-0e283a1ffeea',
        externalId: undefined,
        name: 'SpK4',
        terms: {},
      },
      spk5: {
        codename: 'spk5',
        id: '1d8feba5-7796-4326-a169-456b97e5a25b',
        externalId: undefined,
        name: 'SpK5',
        terms: {},
      },
      spk6: {
        codename: 'spk6',
        id: '020c7a8b-5e99-4a96-87b3-2b7ba4829cc7',
        externalId: undefined,
        name: 'SpK6',
        terms: {},
      },
      spk7: {
        codename: 'spk7',
        id: '31c5dced-c5ae-48f6-958f-15d0c48709ed',
        externalId: undefined,
        name: 'SpK7',
        terms: {},
      },
      spk8: {
        codename: 'spk8',
        id: '68d9048a-e98a-46ef-8149-01af612d4de4',
        externalId: undefined,
        name: 'SpK8',
        terms: {},
      },
      ________________reading_and_viewing___phonological_awareness: {
        codename:
          '________________reading_and_viewing___phonological_awareness',
        id: '7a6adaf5-b710-4b62-a369-9892bec209c2',
        externalId: undefined,
        name: '--------------- Reading and viewing - Phonological awareness',
        terms: {},
      },
      pha1: {
        codename: 'pha1',
        id: 'a7a83142-006e-4b5b-86d1-b6df2cf492f7',
        externalId: undefined,
        name: 'PhA1',
        terms: {},
      },
      pha2: {
        codename: 'pha2',
        id: 'ccac00fd-90f7-41c9-bd0f-3cb2a437e6c0',
        externalId: undefined,
        name: 'PhA2',
        terms: {},
      },
      pha3: {
        codename: 'pha3',
        id: '87d708d3-2d70-4193-b88f-8a7e17e44a8d',
        externalId: undefined,
        name: 'PhA3',
        terms: {},
      },
      pha4: {
        codename: 'pha4',
        id: 'ee25ceb2-f203-4285-8311-cacdb771f549',
        externalId: undefined,
        name: 'PhA4',
        terms: {},
      },
      pha5: {
        codename: 'pha5',
        id: '762dd554-27ec-4841-84b8-031f2f5330c8',
        externalId: undefined,
        name: 'PhA5',
        terms: {},
      },
      ________________reading_and_viewing___phonic_knowledge_and_word_recognition:
        {
          codename:
            '________________reading_and_viewing___phonic_knowledge_and_word_recognition',
          id: 'fc43d488-eebb-4dc5-b81a-24c5d333a953',
          externalId: undefined,
          name: '--------------- Reading and viewing - Phonic knowledge and word recognition',
          terms: {},
        },
      pkw1: {
        codename: 'pkw1',
        id: 'ec5c82ea-82cf-4cce-9463-5c3eeb6d6464',
        externalId: undefined,
        name: 'PKW1',
        terms: {},
      },
      pkw2: {
        codename: 'pkw2',
        id: '7bd92836-2ea3-45ec-b43b-73b4cc073ed2',
        externalId: undefined,
        name: 'PKW2',
        terms: {},
      },
      pkw3: {
        codename: 'pkw3',
        id: 'b8157f18-5f2c-4a4c-bebb-0a74b382dd36',
        externalId: undefined,
        name: 'PKW3',
        terms: {},
      },
      pkw4: {
        codename: 'pkw4',
        id: '28a08224-9043-400c-aaf2-cae3b23795b1',
        externalId: undefined,
        name: 'PKW4',
        terms: {},
      },
      pkw5: {
        codename: 'pkw5',
        id: '232a4f35-2d7d-4778-ba35-e8d9c59dbe1e',
        externalId: undefined,
        name: 'PKW5',
        terms: {},
      },
      pkw6: {
        codename: 'pkw6',
        id: '40a868d0-d2ca-4297-a79d-2d1304ce6f10',
        externalId: undefined,
        name: 'PKW6',
        terms: {},
      },
      pkw7: {
        codename: 'pkw7',
        id: '01b0e18b-4db5-4fb4-be10-b8576065ebd6',
        externalId: undefined,
        name: 'PKW7',
        terms: {},
      },
      pkw8: {
        codename: 'pkw8',
        id: '33b13ceb-025a-4cc0-825c-2c90859ce39d',
        externalId: undefined,
        name: 'PKW8',
        terms: {},
      },
      pkw9: {
        codename: 'pkw9',
        id: 'f3c062f8-4981-4559-8e65-0dde17f8f1a4',
        externalId: undefined,
        name: 'PKW9',
        terms: {},
      },
      ________________reading_and_viewing___fluency: {
        codename: '________________reading_and_viewing___fluency',
        id: 'd0f4e2a5-2a1e-4bb7-84dc-34961a05c697',
        externalId: undefined,
        name: '--------------- Reading and viewing - Fluency',
        terms: {},
      },
      fly1: {
        codename: 'fly1',
        id: 'bb36aa30-5e3e-40ff-863e-1b8b817e3b09',
        externalId: undefined,
        name: 'FlY1',
        terms: {},
      },
      fly2: {
        codename: 'fly2',
        id: '51351aa7-5f07-4ad2-8fbc-1b59129e2f77',
        externalId: undefined,
        name: 'FlY2',
        terms: {},
      },
      fly3: {
        codename: 'fly3',
        id: '23eeefd9-4f25-4def-82bb-5e604cf1b28a',
        externalId: undefined,
        name: 'FlY3',
        terms: {},
      },
      fly4: {
        codename: 'fly4',
        id: '08acfcc2-50d9-4e34-b862-22db9546790b',
        externalId: undefined,
        name: 'FlY4',
        terms: {},
      },
      fly5: {
        codename: 'fly5',
        id: 'd2abbcad-548d-437d-a09f-dc9d2407a14a',
        externalId: undefined,
        name: 'FlY5',
        terms: {},
      },
      fly6: {
        codename: 'fly6',
        id: '1d607315-c148-4563-b4bb-8556b19ecff4',
        externalId: undefined,
        name: 'FlY6',
        terms: {},
      },
      ________________reading_and_viewing___understanding_texts: {
        codename: '________________reading_and_viewing___understanding_texts',
        id: '20b3e7a8-1dd2-451b-b1f2-2e3644255183',
        externalId: undefined,
        name: '--------------- Reading and viewing - Understanding texts',
        terms: {},
      },
      ecu1a: {
        codename: 'ecu1a',
        id: '85c48c90-d405-4e10-b1f2-fdf162dad67b',
        externalId: undefined,
        name: 'ECU1a',
        terms: {},
      },
      ecu1b: {
        codename: 'ecu1b',
        id: '27fe6943-bfdb-43da-ad35-eea1a916bd7a',
        externalId: undefined,
        name: 'ECU1b',
        terms: {},
      },
      unt1: {
        codename: 'unt1',
        id: 'deb77d10-42b8-4c5f-9a1d-8c937fb0840b',
        externalId: undefined,
        name: 'UnT1',
        terms: {},
      },
      unt2: {
        codename: 'unt2',
        id: 'bdb58b3c-1342-4f69-9a16-09ece9856c9c',
        externalId: undefined,
        name: 'UnT2',
        terms: {},
      },
      unt3: {
        codename: 'unt3',
        id: '20a369a1-f706-477f-9051-6a776ab2eb2a',
        externalId: undefined,
        name: 'UnT3',
        terms: {},
      },
      unt4: {
        codename: 'unt4',
        id: 'be8575fa-c5b0-4769-9ef8-7febb3362493',
        externalId: undefined,
        name: 'UnT4',
        terms: {},
      },
      unt5: {
        codename: 'unt5',
        id: '5224e1c1-4876-4534-b1b1-76d64c84963c',
        externalId: undefined,
        name: 'UnT5',
        terms: {},
      },
      unt6: {
        codename: 'unt6',
        id: 'a61fa1f5-522c-40fc-86f5-c3e60fe56e90',
        externalId: undefined,
        name: 'UnT6',
        terms: {},
      },
      unt7: {
        codename: 'unt7',
        id: 'f030847c-1f5b-431a-9c38-4c61dd5db7cd',
        externalId: undefined,
        name: 'UnT7',
        terms: {},
      },
      unt8: {
        codename: 'unt8',
        id: '60e95707-1473-4d23-8f85-07e76e098358',
        externalId: undefined,
        name: 'UnT8',
        terms: {},
      },
      unt9: {
        codename: 'unt9',
        id: '063ca326-2288-4910-b1b7-865bed738f39',
        externalId: undefined,
        name: 'UnT9',
        terms: {},
      },
      unt10: {
        codename: 'unt10',
        id: 'd60b8764-3311-4ff9-a07f-dc2c9c9ed88a',
        externalId: undefined,
        name: 'UnT10',
        terms: {},
      },
      unt11: {
        codename: 'unt11',
        id: '4c0a3407-292e-490a-a7db-ee9b530fa27b',
        externalId: undefined,
        name: 'UnT11',
        terms: {},
      },
      ________________writing___creating_texts: {
        codename: '________________writing___creating_texts',
        id: '90ec9550-b24e-4480-9731-8285d26a1e55',
        externalId: undefined,
        name: '--------------- Writing - Creating texts',
        terms: {},
      },
      ecc1a: {
        codename: 'ecc1a',
        id: 'af52cb3e-2e98-4ae5-a5e6-c9bbb8079a3c',
        externalId: undefined,
        name: 'ECC1a',
        terms: {},
      },
      ecc1b: {
        codename: 'ecc1b',
        id: '7f56d4b1-0f0f-4e07-aaf3-7d7ba1ffa841',
        externalId: undefined,
        name: 'ECC1b',
        terms: {},
      },
      crt1: {
        codename: 'crt1',
        id: 'a3185099-a621-43bd-a9eb-8af7d982cdad',
        externalId: undefined,
        name: 'CrT1',
        terms: {},
      },
      crt2: {
        codename: 'crt2',
        id: 'd46db7e4-268f-4d66-ad58-b050f810c959',
        externalId: undefined,
        name: 'CrT2',
        terms: {},
      },
      crt3: {
        codename: 'crt3',
        id: '1a6c5677-ca07-4802-8b3e-33e2d5151888',
        externalId: undefined,
        name: 'CrT3',
        terms: {},
      },
      crt4: {
        codename: 'crt4',
        id: 'b8dd32cf-5996-4927-8178-7f7b1b60e6b3',
        externalId: undefined,
        name: 'CrT4',
        terms: {},
      },
      crt6: {
        codename: 'crt6',
        id: 'e515d056-26b5-4430-b5d3-1ddddba5e3e9',
        externalId: undefined,
        name: 'CrT6',
        terms: {},
      },
      crt5: {
        codename: 'crt5',
        id: '72ca7591-16ca-4d93-b9d1-0b0abafc6c4c',
        externalId: undefined,
        name: 'CrT5',
        terms: {},
      },
      crt7: {
        codename: 'crt7',
        id: '27838547-1ad1-46e6-8c68-7a3ea2779078',
        externalId: undefined,
        name: 'CrT7',
        terms: {},
      },
      crt8: {
        codename: 'crt8',
        id: '674d3605-ce7f-4efa-81f9-95a672ddbc6d',
        externalId: undefined,
        name: 'CrT8',
        terms: {},
      },
      crt9: {
        codename: 'crt9',
        id: '5cfde4bf-6e0a-4910-9655-0e4a87bb5f55',
        externalId: undefined,
        name: 'CrT9',
        terms: {},
      },
      crt10: {
        codename: 'crt10',
        id: '33fd44d4-1a4b-41f6-97cc-3db1dab0a9f1',
        externalId: undefined,
        name: 'CrT10',
        terms: {},
      },
      crt11: {
        codename: 'crt11',
        id: '70b3dd8b-09cb-4ad3-b2c9-65b49ed08af8',
        externalId: undefined,
        name: 'CrT11',
        terms: {},
      },
      ________________writing___grammar: {
        codename: '________________writing___grammar',
        id: '3fde17d7-3883-481d-90a8-bc49e814f17c',
        externalId: undefined,
        name: '--------------- Writing - Grammar',
        terms: {},
      },
      gra1: {
        codename: 'gra1',
        id: '63b4a708-dad3-4a77-96f9-3f98b7d2fec7',
        externalId: undefined,
        name: 'GrA1',
        terms: {},
      },
      gra2: {
        codename: 'gra2',
        id: 'bbbff154-2783-4c74-b626-f917d47aa3ff',
        externalId: undefined,
        name: 'GrA2',
        terms: {},
      },
      gra3: {
        codename: 'gra3',
        id: 'cbeeb708-4e98-463e-9e98-c4cb95607ee3',
        externalId: undefined,
        name: 'GrA3',
        terms: {},
      },
      gra4: {
        codename: 'gra4',
        id: 'fba77c0b-00a4-4b61-adb5-0e287db654a9',
        externalId: undefined,
        name: 'GrA4',
        terms: {},
      },
      gra5: {
        codename: 'gra5',
        id: 'a8266d9f-5848-4f38-839d-e42c0521f94a',
        externalId: undefined,
        name: 'GrA5',
        terms: {},
      },
      gra6: {
        codename: 'gra6',
        id: 'b19e50a3-4147-442d-a6b5-75d2527df95e',
        externalId: undefined,
        name: 'GrA6',
        terms: {},
      },
      gra7: {
        codename: 'gra7',
        id: '70c79c30-3077-4fa5-9ca1-049c61bc4084',
        externalId: undefined,
        name: 'GrA7',
        terms: {},
      },
      ________________writing___punctuation: {
        codename: '________________writing___punctuation',
        id: 'f9795e7e-1118-4d79-802c-b31b0c9f628a',
        externalId: undefined,
        name: '--------------- Writing - Punctuation',
        terms: {},
      },
      pun1: {
        codename: 'pun1',
        id: 'fa4c5eff-8468-40dc-bc9a-d6505de79633',
        externalId: undefined,
        name: 'PuN1',
        terms: {},
      },
      pun2: {
        codename: 'pun2',
        id: 'e88ad923-9bed-4717-8f8b-e19422cadeef',
        externalId: undefined,
        name: 'PuN2',
        terms: {},
      },
      pun3: {
        codename: 'pun3',
        id: '70578951-fe7d-499f-8332-0ad45d65fda6',
        externalId: undefined,
        name: 'PuN3',
        terms: {},
      },
      pun4: {
        codename: 'pun4',
        id: 'c2880cf6-5a5f-46ec-88fe-16510265ef40',
        externalId: undefined,
        name: 'PuN4',
        terms: {},
      },
      pun5: {
        codename: 'pun5',
        id: '49c34016-40bc-44a6-a3b9-984b4e0a7fb6',
        externalId: undefined,
        name: 'PuN5',
        terms: {},
      },
      pun6: {
        codename: 'pun6',
        id: 'de124b20-4e7f-4105-9bd0-6ca912f6f3e8',
        externalId: undefined,
        name: 'PuN6',
        terms: {},
      },
      pun7: {
        codename: 'pun7',
        id: 'd41eb58e-0d44-472b-9a53-8efa4014329f',
        externalId: undefined,
        name: 'PuN7',
        terms: {},
      },
      pun8: {
        codename: 'pun8',
        id: '4d7d9a5e-3851-41a2-b773-22603073e9a4',
        externalId: undefined,
        name: 'PuN8',
        terms: {},
      },
      ________________writing___spelling: {
        codename: '________________writing___spelling',
        id: '979e10e6-ab15-49c2-8e49-ddd98cd73500',
        externalId: undefined,
        name: '--------------- Writing - Spelling',
        terms: {},
      },
      spg1: {
        codename: 'spg1',
        id: '3d5e2724-8b40-47a3-a383-878a9de60c7b',
        externalId: undefined,
        name: 'SpG1',
        terms: {},
      },
      spg2: {
        codename: 'spg2',
        id: 'ee22cdcc-a590-4834-80ad-e9521ca6746a',
        externalId: undefined,
        name: 'SpG2',
        terms: {},
      },
      spg3: {
        codename: 'spg3',
        id: '0666205f-671a-42ce-96e6-8939c79e64b1',
        externalId: undefined,
        name: 'SpG3',
        terms: {},
      },
      spg4: {
        codename: 'spg4',
        id: '04b26343-bf78-4680-b384-73d42b24bf4a',
        externalId: undefined,
        name: 'SpG4',
        terms: {},
      },
      spg5: {
        codename: 'spg5',
        id: '84770826-1f61-4332-9eb7-c7c45333e917',
        externalId: undefined,
        name: 'SpG5',
        terms: {},
      },
      spg6: {
        codename: 'spg6',
        id: '5afbf339-58c7-456f-8663-8ab3662369c5',
        externalId: undefined,
        name: 'SpG6',
        terms: {},
      },
      spg7: {
        codename: 'spg7',
        id: '30c83375-df0b-4413-867e-4b5b11af5145',
        externalId: undefined,
        name: 'SpG7',
        terms: {},
      },
      spg8: {
        codename: 'spg8',
        id: '46acda8e-1909-4a0f-b796-97613207d0dc',
        externalId: undefined,
        name: 'SpG8',
        terms: {},
      },
      spg9: {
        codename: 'spg9',
        id: '327fbb21-b6eb-4ddb-b836-d134ab1a2a5e',
        externalId: undefined,
        name: 'SpG9',
        terms: {},
      },
      spg10: {
        codename: 'spg10',
        id: '2388b2c5-0e3e-41e6-8fe9-3f17814ff772',
        externalId: undefined,
        name: 'SpG10',
        terms: {},
      },
      spg11: {
        codename: 'spg11',
        id: '2c7ce7ae-72f4-42bf-b586-cb123435b618',
        externalId: undefined,
        name: 'SpG11',
        terms: {},
      },
      spg12: {
        codename: 'spg12',
        id: '079fb288-9db7-4a0e-9654-075bc399dee0',
        externalId: undefined,
        name: 'SpG12',
        terms: {},
      },
      spg13: {
        codename: 'spg13',
        id: 'cf0e327c-63e2-45d3-a94c-aec740df2554',
        externalId: undefined,
        name: 'SpG13',
        terms: {},
      },
      spg14: {
        codename: 'spg14',
        id: 'b668139a-b8be-448c-a182-670c575294aa',
        externalId: undefined,
        name: 'SpG14',
        terms: {},
      },
      ________________writing___handwriting_and_keyboarding: {
        codename: '________________writing___handwriting_and_keyboarding',
        id: 'fba139f8-b49d-4fd4-8835-52829b440b23',
        externalId: undefined,
        name: '--------------- Writing - Handwriting and keyboarding',
        terms: {},
      },
      hwk1: {
        codename: 'hwk1',
        id: '87f2e40c-3620-4fed-afde-8b68307aa7f0',
        externalId: undefined,
        name: 'HwK1',
        terms: {},
      },
      hwk2: {
        codename: 'hwk2',
        id: '43d01bac-f2c9-447d-97f8-f7f6a55da405',
        externalId: undefined,
        name: 'HwK2',
        terms: {},
      },
      hwk3: {
        codename: 'hwk3',
        id: '6ef29911-f17e-4911-b895-1a023bbaa82e',
        externalId: undefined,
        name: 'HwK3',
        terms: {},
      },
      hwk4: {
        codename: 'hwk4',
        id: '5c7609c3-5ecc-41f1-9d4e-efccd58be558',
        externalId: undefined,
        name: 'HwK4',
        terms: {},
      },
      hwk5: {
        codename: 'hwk5',
        id: 'cabddd9e-0121-40fa-aaa4-ca0aee339a15',
        externalId: undefined,
        name: 'HwK5',
        terms: {},
      },
      hwk6: {
        codename: 'hwk6',
        id: '04801692-f799-4be8-817d-dc16bf528a56',
        externalId: undefined,
        name: 'HwK6',
        terms: {},
      },
      hwk7: {
        codename: 'hwk7',
        id: 'e8adb4f9-6118-49d0-ae4c-df2f62403735',
        externalId: undefined,
        name: 'HwK7',
        terms: {},
      },
      hwk8: {
        codename: 'hwk8',
        id: '07879838-2d46-4d3b-a920-7606ab2a4a01',
        externalId: undefined,
        name: 'HwK8',
        terms: {},
      },
    },
  },

  /**
   * Enrolment Type
   */
  enrolment_type: {
    codename: 'enrolment_type',
    id: 'eeebb2e0-0547-49fb-a7e0-b7e52eb90ece',
    externalId: undefined,
    name: 'Enrolment Type',
    terms: {
      mandatory: {
        codename: 'mandatory',
        id: '3dc6864f-26c5-4f76-b2d4-4aaa8d36a7d4',
        externalId: undefined,
        name: 'Mandatory',
        terms: {},
      },
      elective: {
        codename: 'elective',
        id: 'a6bb320d-a35a-4b1a-b8e8-7db55408a166',
        externalId: undefined,
        name: 'Elective',
        terms: {},
      },
    },
  },

  /**
   * Content Accordion
   */
  content_accordion: {
    codename: 'content_accordion',
    id: 'f6b5bdfd-ec77-4109-9555-18bf761c7a2d',
    externalId: undefined,
    name: 'Content Accordion',
    terms: {
      early_stage_1: {
        codename: 'early_stage_1',
        id: '68c16afe-468c-416f-8911-e961455744c2',
        externalId: undefined,
        name: 'Early Stage 1',
        terms: {},
      },
      stage_1: {
        codename: 'stage_1',
        id: '3cddab3c-9332-4891-8078-41984f5a346a',
        externalId: undefined,
        name: 'Stage 1',
        terms: {},
      },
      stage_2: {
        codename: 'stage_2',
        id: 'a53f2cb5-079a-4be9-b1ec-db9015c6f691',
        externalId: undefined,
        name: 'Stage 2',
        terms: {},
      },
      stage_3: {
        codename: 'stage_3',
        id: '498c893e-223f-4ba7-a393-d870ed2ae43a',
        externalId: undefined,
        name: 'Stage 3',
        terms: {},
      },
      stage_4: {
        codename: 'stage_4',
        id: 'aab5e049-7500-4221-bed8-763bdb611545',
        externalId: undefined,
        name: 'Stage 4',
        terms: {},
      },
      stage_5: {
        codename: 'stage_5',
        id: 'e540340c-b322-45aa-89c6-e71ffc780cd8',
        externalId: undefined,
        name: 'Stage 5',
        terms: {},
      },
      n11: {
        codename: 'n11',
        id: '7945d76f-de92-4856-8515-d511b2d36ed8',
        externalId: undefined,
        name: 'Year 11',
        terms: {},
      },
      n12: {
        codename: 'n12',
        id: '084b218b-1637-44e4-95fb-400c02860c65',
        externalId: undefined,
        name: 'Year 12',
        terms: {},
      },
      life_skills: {
        codename: 'life_skills',
        id: '5cb88674-f203-4753-bf6f-01520c3fa246',
        externalId: undefined,
        name: 'Life Skills',
        terms: {},
      },
    },
  },

  /**
   * Stage
   */
  stage: {
    codename: 'stage',
    id: '3163763f-c8a5-415b-bd31-a2440d0ff2f5',
    externalId: undefined,
    name: 'Stage',
    terms: {
      early_stage_1: {
        codename: 'early_stage_1',
        id: '58f13ff6-2c0f-4093-9e9f-bc4d84941d40',
        externalId: undefined,
        name: 'Early Stage 1',
        terms: {},
      },
      stage_1: {
        codename: 'stage_1',
        id: '560dde49-321c-42da-803e-6b14ce4b9a6d',
        externalId: undefined,
        name: 'Stage 1',
        terms: {},
      },
      stage_2: {
        codename: 'stage_2',
        id: '379714a5-a1ba-49cf-88a3-dc113f63662b',
        externalId: undefined,
        name: 'Stage 2',
        terms: {},
      },
      stage_3: {
        codename: 'stage_3',
        id: '35ebff1f-f49b-4861-881d-4aae3bf40be4',
        externalId: undefined,
        name: 'Stage 3',
        terms: {},
      },
      stage_4: {
        codename: 'stage_4',
        id: '636f4f53-1d54-4586-bb8f-25b3f07bfc5b',
        externalId: undefined,
        name: 'Stage 4',
        terms: {},
      },
      stage_5: {
        codename: 'stage_5',
        id: '76d4a7fc-a2a8-4bb7-9aae-a1b9269b8949',
        externalId: undefined,
        name: 'Stage 5',
        terms: {},
      },
      stage_6: {
        codename: 'stage_6',
        id: 'b582a6d7-c422-4fcf-89fd-6947cb534620',
        externalId: undefined,
        name: 'Stage 6',
        terms: {},
      },
    },
  },

  /**
   * Syllabus Status
   */
  syllabus_status: {
    codename: 'syllabus_status',
    id: 'a7728af2-ad95-478b-bd9e-b08b549849c5',
    externalId: undefined,
    name: 'Syllabus Status',
    terms: {
      new: {
        codename: 'new',
        id: '2f86c64b-83fb-4eb5-b473-9203535ffcb8',
        externalId: undefined,
        name: 'New',
        terms: {},
      },
      under_development: {
        codename: 'under_development',
        id: 'ec3a9827-5463-48a7-a9e9-8797825fb8e2',
        externalId: undefined,
        name: 'Under development',
        terms: {},
      },
      under_consultation: {
        codename: 'under_consultation',
        id: '02322eb9-0243-44bb-90fb-07a16800442a',
        externalId: undefined,
        name: 'Under consultation',
        terms: {},
      },
      outgoing: {
        codename: 'outgoing',
        id: '035f6f50-5827-4915-aace-4f840f4709c5',
        externalId: undefined,
        name: 'Outgoing',
        terms: {},
      },
      current: {
        codename: 'current',
        id: '5a086e6b-9e08-42bd-941f-abcc5f446b72',
        externalId: undefined,
        name: 'Current',
        terms: {},
      },
    },
  },

  /**
   * Asset Published Month
   */
  assetpublishedmonth: {
    codename: 'assetpublishedmonth',
    id: 'f6582c89-eea5-4c44-b99f-d2b5bee83843',
    externalId: undefined,
    name: 'Asset Published Month',
    terms: {
      n1: {
        codename: 'n1',
        id: 'd1d905f5-3ba7-4162-9a65-a5ea88a09c9a',
        externalId: undefined,
        name: '1',
        terms: {},
      },
      n2: {
        codename: 'n2',
        id: '46168df2-406a-43c5-9f2d-3418e4ba4eb4',
        externalId: undefined,
        name: '2',
        terms: {},
      },
      n3: {
        codename: 'n3',
        id: 'a2f1994b-4743-4115-a679-1b08aa2668e6',
        externalId: undefined,
        name: '3',
        terms: {},
      },
      n4: {
        codename: 'n4',
        id: 'ea15d44d-53e4-40eb-b48f-d6f625a23b8c',
        externalId: undefined,
        name: '4',
        terms: {},
      },
      n5: {
        codename: 'n5',
        id: '90d04ee4-2d22-4468-8542-eff05526f21a',
        externalId: undefined,
        name: '5',
        terms: {},
      },
      n6: {
        codename: 'n6',
        id: '8d8d8df9-0df1-46c5-99f5-2fccd8df943a',
        externalId: undefined,
        name: '6',
        terms: {},
      },
      n7: {
        codename: 'n7',
        id: '479fabf7-2bcd-4c26-8b20-e1a45d909814',
        externalId: undefined,
        name: '7',
        terms: {},
      },
      n8: {
        codename: 'n8',
        id: '3578163a-7c0c-4ce8-8d4d-3bff494d1eba',
        externalId: undefined,
        name: '8',
        terms: {},
      },
      n9: {
        codename: 'n9',
        id: '70dd3a22-9339-409a-b907-f8d065a35fb7',
        externalId: undefined,
        name: '9',
        terms: {},
      },
      n10: {
        codename: 'n10',
        id: '2983a127-a3b1-44fb-842f-ab574fd974ed',
        externalId: undefined,
        name: '10',
        terms: {},
      },
      n11: {
        codename: 'n11',
        id: '19510ba4-fe1e-40d4-a107-c4f3a60fc010',
        externalId: undefined,
        name: '11',
        terms: {},
      },
      n12: {
        codename: 'n12',
        id: '5f027b99-a2da-4819-847f-9f4ff908fdbd',
        externalId: undefined,
        name: '12',
        terms: {},
      },
    },
  },

  /**
   * Key learning area
   */
  key_learning_area: {
    codename: 'key_learning_area',
    id: '419c60fd-0ec8-43b7-9648-db23d146d148',
    externalId: undefined,
    name: 'Key learning area',
    terms: {
      english: {
        codename: 'english',
        id: 'd032f3c1-fd15-4ecb-b472-b7e95e92c566',
        externalId: undefined,
        name: 'English',
        terms: {},
      },
      mathematics: {
        codename: 'mathematics',
        id: '5e3fc6ec-37cf-436a-b6eb-80ceb80dee5d',
        externalId: undefined,
        name: 'Mathematics',
        terms: {},
      },
      science: {
        codename: 'science',
        id: '150767c3-7de7-4107-ab44-c82f5bb248f8',
        externalId: undefined,
        name: 'Science',
        terms: {},
      },
      tas: {
        codename: 'tas',
        id: '4069a745-2939-49f4-86c0-9cd8b017228c',
        externalId: undefined,
        name: 'Technological and Applied Studies',
        terms: {},
      },
      hsie: {
        codename: 'hsie',
        id: '13582cba-aa01-4918-b7c9-41929ee3332f',
        externalId: undefined,
        name: 'HSIE',
        terms: {},
      },
      creative_arts: {
        codename: 'creative_arts',
        id: '0f3a9251-7705-4e60-90dc-9e57bcc925f7',
        externalId: undefined,
        name: 'Creative Arts',
        terms: {},
      },
      pdhpe: {
        codename: 'pdhpe',
        id: '3c0b50df-0047-447d-bcdf-8e3bfecc1508',
        externalId: undefined,
        name: 'PDHPE',
        terms: {},
      },
      languages: {
        codename: 'languages',
        id: '6105b7c3-b727-460d-8b7a-8d0801ef85c1',
        externalId: undefined,
        name: 'Languages',
        terms: {},
      },
      vet: {
        codename: 'vet',
        id: '597b9da7-dc43-4cce-a555-38814c3c7647',
        externalId: undefined,
        name: 'VET',
        terms: {},
      },
    },
  },

  /**
   * Course Hours
   */
  coursehours: {
    codename: 'coursehours',
    id: 'b00a7b05-3550-4957-afae-f0fc78e124a2',
    externalId: undefined,
    name: 'Course Hours',
    terms: {
      n100: {
        codename: 'n100',
        id: '67e11807-d860-436a-b00e-e80e1f7fb820',
        externalId: undefined,
        name: '100',
        terms: {},
      },
      n200: {
        codename: 'n200',
        id: '75f34bca-851b-42be-8a7b-dd2ba0125ecb',
        externalId: undefined,
        name: '200',
        terms: {},
      },
      n300: {
        codename: 'n300',
        id: 'd3d75958-2fff-475a-a82d-75c3861c23b9',
        externalId: undefined,
        name: '300',
        terms: {},
      },
      n400: {
        codename: 'n400',
        id: '00f7fe55-9fcb-4bb5-8512-e3fa380ce91a',
        externalId: undefined,
        name: '400',
        terms: {},
      },
    },
  },

  /**
   * Resource Type Web
   */
  resource_type_web: {
    codename: 'resource_type_web',
    id: 'b3dc6589-d76d-4d19-9676-48f1909827f6',
    externalId: undefined,
    name: 'Resource Type Web',
    terms: {
      web_resource: {
        codename: 'web_resource',
        id: 'a12fa944-1d80-4721-9399-548cf034f17f',
        externalId: undefined,
        name: 'Web Resource',
        terms: {},
      },
    },
  },

  /**
   * Syllabus Type
   */
  syllabustype: {
    codename: 'syllabustype',
    id: 'edbd9c31-268c-46b6-b19f-2c0dc1da3475',
    externalId: undefined,
    name: 'Syllabus Type',
    terms: {
      mainstream: {
        codename: 'mainstream',
        id: 'e1bbb3e8-63b8-40f5-a7f6-c998b4b7fee9',
        externalId: undefined,
        name: 'Mainstream',
        terms: {},
      },
      life_skills: {
        codename: 'life_skills',
        id: '496ef53f-24c9-4471-bdb9-a88cedbea0e8',
        externalId: undefined,
        name: 'Life Skills',
        terms: {},
      },
      vet: {
        codename: 'vet',
        id: '7c462600-424c-47f1-af16-e701c69c53e0',
        externalId: undefined,
        name: 'VET',
        terms: {},
      },
    },
  },

  /**
   * Stage Year
   */
  stage_year: {
    codename: 'stage_year',
    id: '470fa2dc-dbe4-4798-aaac-63180be3db75',
    externalId: undefined,
    name: 'Stage Year',
    terms: {
      k: {
        codename: 'k',
        id: '6215c0d2-783e-42e1-801b-6a7f8df0cc1e',
        externalId: undefined,
        name: 'K',
        terms: {},
      },
      n1: {
        codename: 'n1',
        id: '490e6e6f-aeeb-441a-88a6-129380ff492a',
        externalId: undefined,
        name: '1',
        terms: {},
      },
      n2: {
        codename: 'n2',
        id: '55b1e5c3-6c79-47a2-8ecb-04daa0f629b6',
        externalId: undefined,
        name: '2',
        terms: {},
      },
      n3: {
        codename: 'n3',
        id: 'ef7964ef-794a-4a9d-a1a8-bc330305f7c0',
        externalId: undefined,
        name: '3',
        terms: {},
      },
      n4: {
        codename: 'n4',
        id: '7fe450d0-fdf0-42fc-80a7-5bb2a4d59ddc',
        externalId: undefined,
        name: '4',
        terms: {},
      },
      n5: {
        codename: 'n5',
        id: '36129ed0-d9fc-4049-84a7-a87241fd3d3f',
        externalId: undefined,
        name: '5',
        terms: {},
      },
      n6: {
        codename: 'n6',
        id: 'ef288091-e550-4ec2-abf3-96e94684163d',
        externalId: undefined,
        name: '6',
        terms: {},
      },
      n7: {
        codename: 'n7',
        id: '8228dcf3-f4c2-4180-b02d-4c9ef208ca32',
        externalId: undefined,
        name: '7',
        terms: {},
      },
      n8: {
        codename: 'n8',
        id: 'b60ab790-ca48-4548-bc66-1b41d40ea759',
        externalId: undefined,
        name: '8',
        terms: {},
      },
      n9: {
        codename: 'n9',
        id: 'a64e439c-d7db-4133-ab4e-c9e5ead07a82',
        externalId: undefined,
        name: '9',
        terms: {},
      },
      n10: {
        codename: 'n10',
        id: '40116b24-041c-45c7-babe-987bd40bb393',
        externalId: undefined,
        name: '10',
        terms: {},
      },
      n11: {
        codename: 'n11',
        id: '92c7ae30-5b32-46dc-a002-0c1c0114fd41',
        externalId: undefined,
        name: '11',
        terms: {},
      },
      n12: {
        codename: 'n12',
        id: 'd172b4a4-bc4c-4b14-a6e9-6813452dc8f9',
        externalId: undefined,
        name: '12',
        terms: {},
      },
    },
  },

  /**
   * Glossary Type
   */
  glossary_type: {
    codename: 'glossary_type',
    id: '4924b109-2c57-46dd-a776-2a45418a07a6',
    externalId: undefined,
    name: 'Glossary Type',
    terms: {
      ace: {
        codename: 'ace',
        id: '46000400-7971-4297-843c-f37a2a91331b',
        externalId: undefined,
        name: 'ACE',
        terms: {},
      },
      assessment: {
        codename: 'assessment',
        id: '11b17a45-8486-4da0-aa33-f9773e9def47',
        externalId: undefined,
        name: 'Assessment',
        terms: {},
      },
    },
  },

  /**
   * Workflow Step
   */
  workflow_step: {
    codename: 'workflow_step',
    id: '46121b32-60ac-425e-95ef-d1d45a55d10a',
    externalId: undefined,
    name: 'Workflow Step',
    terms: {
      draft: {
        codename: 'draft',
        id: 'fdbeec33-9421-47ba-bfef-3a263567410a',
        externalId: undefined,
        name: 'Draft',
        terms: {},
      },
      review: {
        codename: 'review',
        id: '781eb696-d2b5-4d07-ab82-0d9ab856725c',
        externalId: undefined,
        name: 'Review',
        terms: {},
      },
      approval: {
        codename: 'approval',
        id: '544d873f-0635-452d-acdd-1329acb8654f',
        externalId: undefined,
        name: 'Approval',
        terms: {},
      },
      ready_for_release: {
        codename: 'ready_for_release',
        id: 'a6a833e9-0c6e-4467-885e-38c5c18c556a',
        externalId: undefined,
        name: 'Ready for Release',
        terms: {},
      },
      published: {
        codename: 'published',
        id: '5db0af71-b607-4f28-a5cd-d7eb741481f6',
        externalId: undefined,
        name: 'Published',
        terms: {},
      },
      archived: {
        codename: 'archived',
        id: '5a428086-2af4-4f4a-affb-9818b17da843',
        externalId: undefined,
        name: 'Archived',
        terms: {},
      },
    },
  },

  /**
   * Language
   */
  language: {
    codename: 'language',
    id: '17d3381c-61bd-470d-83c7-8b8930613cf5',
    externalId: undefined,
    name: 'Language',
    terms: {
      arabic: {
        codename: 'arabic',
        id: '4357f9a1-7357-4a31-ae16-3b357575d4b8',
        externalId: undefined,
        name: 'Arabic',
        terms: {},
      },
      chinese: {
        codename: 'chinese',
        id: 'dfd827eb-ff64-471a-ad8e-0d3f2f929b61',
        externalId: undefined,
        name: 'Chinese',
        terms: {},
      },
      classical_greek: {
        codename: 'classical_greek',
        id: '17c3e950-7653-485e-a970-f7c804897e60',
        externalId: undefined,
        name: 'Classical Greek',
        terms: {},
      },
      classical_hebrew: {
        codename: 'classical_hebrew',
        id: 'c163fa1a-e5af-47e3-8aac-38d92a05d77b',
        externalId: undefined,
        name: 'Classical Hebrew',
        terms: {},
      },
      french: {
        codename: 'french',
        id: 'd8e1f3f3-0f1b-44ff-a674-f811d193c778',
        externalId: undefined,
        name: 'French',
        terms: {},
      },
      german: {
        codename: 'german',
        id: '68a2c97d-27d2-48a0-aadc-9c0dbdaa758d',
        externalId: undefined,
        name: 'German',
        terms: {},
      },
      hindi: {
        codename: 'hindi',
        id: '6758327d-9b59-45d4-a632-84c7aad4e1ac',
        externalId: undefined,
        name: 'Hindi',
        terms: {},
      },
      indonesian: {
        codename: 'indonesian',
        id: '1b2cafa4-2047-4ffe-82b7-430c49a1fa9f',
        externalId: undefined,
        name: 'Indonesian',
        terms: {},
      },
      italian: {
        codename: 'italian',
        id: '6bf5b963-cca1-4adb-b431-6f5ff01f0a16',
        externalId: undefined,
        name: 'Italian',
        terms: {},
      },
      japanese: {
        codename: 'japanese',
        id: '76858376-dd46-464d-a75d-c4c1ff8d950c',
        externalId: undefined,
        name: 'Japanese',
        terms: {},
      },
      korean: {
        codename: 'korean',
        id: '113d57f1-f345-4f0c-bf87-524177e64b35',
        externalId: undefined,
        name: 'Korean',
        terms: {},
      },
      latin: {
        codename: 'latin',
        id: '5b2341dc-93ef-480d-ba9f-e14df3d05590',
        externalId: undefined,
        name: 'Latin',
        terms: {},
      },
      macedonian: {
        codename: 'macedonian',
        id: '674ca837-b884-4d26-8f02-66f7e11807cc',
        externalId: undefined,
        name: 'Macedonian',
        terms: {},
      },
      modern_greek: {
        codename: 'modern_greek',
        id: 'd904a2e4-3375-435e-8aab-4f18d2452e08',
        externalId: undefined,
        name: 'Modern Greek',
        terms: {},
      },
      modern_hebrew: {
        codename: 'modern_hebrew',
        id: 'd5c15aae-1219-45a8-9b52-e237b495da9c',
        externalId: undefined,
        name: 'Modern Hebrew',
        terms: {},
      },
      persian: {
        codename: 'persian',
        id: '013469ff-8860-420d-864f-ccd7e1254494',
        externalId: undefined,
        name: 'Persian',
        terms: {},
      },
      punjabi: {
        codename: 'punjabi',
        id: '56a65186-c5bb-4dc5-82f8-1ed1c28c0534',
        externalId: undefined,
        name: 'Punjabi',
        terms: {},
      },
      russian: {
        codename: 'russian',
        id: '28e3b311-6ad7-4ae9-bd5f-c6bc79d46f73',
        externalId: undefined,
        name: 'Russian',
        terms: {},
      },
      sanskrit: {
        codename: 'sanskrit',
        id: 'f51f5840-0107-4147-835e-488094e1cc4d',
        externalId: undefined,
        name: 'Sanskrit',
        terms: {},
      },
      spanish: {
        codename: 'spanish',
        id: '64610d9b-f85f-49c5-954a-4fade3705c7d',
        externalId: undefined,
        name: 'Spanish',
        terms: {},
      },
      tamil: {
        codename: 'tamil',
        id: 'd4885715-a634-409f-8a1f-0d9f4baaa94e',
        externalId: undefined,
        name: 'Tamil',
        terms: {},
      },
      turkish: {
        codename: 'turkish',
        id: '47e0e1d6-4a60-47b5-bc9b-a1d89ac7e802',
        externalId: undefined,
        name: 'Turkish',
        terms: {},
      },
      vietnamese: {
        codename: 'vietnamese',
        id: '23539ad4-8181-4aa5-a908-7f22ccddf132',
        externalId: undefined,
        name: 'Vietnamese',
        terms: {},
      },
    },
  },

  /**
   * Course Units
   */
  courseunits: {
    codename: 'courseunits',
    id: '80b1d0d9-bbe7-4686-9b80-dc049f8e14b4',
    externalId: undefined,
    name: 'Course Units',
    terms: {
      n1: {
        codename: 'n1',
        id: '824ece51-b01f-439f-a5c2-8a54b46f4d44',
        externalId: undefined,
        name: '1',
        terms: {},
      },
      n2: {
        codename: 'n2',
        id: '5e37e673-c908-455c-a3fb-231bf331f1e2',
        externalId: undefined,
        name: '2',
        terms: {},
      },
      n3: {
        codename: 'n3',
        id: 'a13d7c61-0c86-4edc-acd4-cec2f81d3d0c',
        externalId: undefined,
        name: '3',
        terms: {},
      },
      n4: {
        codename: 'n4',
        id: 'dc57ba43-4d0d-4263-b7a5-1e2471bf6342',
        externalId: undefined,
        name: '4',
        terms: {},
      },
    },
  },

  /**
   * Stages Hirachy Test
   */
  stages_hirachy_test: {
    codename: 'stages_hirachy_test',
    id: 'f0993400-00b0-4025-916d-c9df5bc8e34f',
    externalId: undefined,
    name: 'Stages Hirachy Test',
    terms: {
      primary: {
        codename: 'primary',
        id: '331db9df-e613-4ee3-b838-04f457171a97',
        externalId: undefined,
        name: 'primary',
        terms: {
          n1: {
            codename: 'n1',
            id: 'c58f20b0-1e89-4682-86dd-ce330604f1bb',
            externalId: undefined,
            name: '1',
            terms: {},
          },
          n2: {
            codename: 'n2',
            id: 'acb01dd9-c3ce-4dbf-8609-a7bfd0a2c8bc',
            externalId: undefined,
            name: '2',
            terms: {},
          },
        },
      },
      secondary: {
        codename: 'secondary',
        id: '7e3a6c86-5678-42ad-a27f-493817c864ac',
        externalId: undefined,
        name: 'secondary',
        terms: {
          n3: {
            codename: 'n3',
            id: '88084cd4-f04d-4047-bf46-611a20c4e37b',
            externalId: undefined,
            name: '3',
            terms: {},
          },
          n4: {
            codename: 'n4',
            id: '882b8d0d-d467-434f-a85f-1d8252d8ea22',
            externalId: undefined,
            name: '4',
            terms: {},
          },
        },
      },
    },
  },
};
