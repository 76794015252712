import { GridCol } from '@/components/nsw/grid/GridCol'
import { GridWrapper } from '@/components/nsw/grid/GridWrapper'
import { SideNav } from '@/components/nsw/side-nav/SideNav'
import { Syllabus } from '@/kontent/content-types'
import { CommonContentTab } from '@/pages/learning-areas/[learningarea]/[syllabus]/[tab]'
import { CommonPageProps } from '@/types'
import { getLinkedItems, isRichtextEmpty } from '@/utils'
import parseHTML, { Element, domToReact } from 'html-react-parser'
import { NextRouter, useRouter } from 'next/router'
import slugify from 'slugify'
import InPageNav from '../InPageNav'
import RichText from '../RichText'
import { HeadingComponent } from '../RichTextComponent'
import { SyllabusTabsTitle } from '../ui/syllabus-tabs/SyllabusTabsTitle'

export const overviewNavStyle = {
	'.nsw-side-nav__item': {
		borderLeft: '2px solid var(--nsw-brand-accent)',
	},
	'.nsw-side-nav__item:first-of-type': {
		borderTop: '0',
	},
} as const

export const Overview = ({
	mappings,
	data,
}: CommonPageProps<Syllabus, CommonContentTab<Syllabus>>) => {
	const router = useRouter()
	const { route, query } = router
	const {
		learningarea: paramLearningArea,
		syllabus: paramSyllabus,
		section,
	} = (router?.['components']?.[route] as NextRouter)?.query || query
	const { syllabus: syllabusResponse } = data
	const syllabus = syllabusResponse.item

	const staticSideNavItems = [
		{
			text: 'Syllabus overview',
			href: `/learning-areas/${paramLearningArea}/${paramSyllabus}/overview`,
			isActive: !section,
			onClick: null,
			scroll: false,
		},
		{
			text: 'Course description',
			href: `/learning-areas/${paramLearningArea}/${paramSyllabus}/overview/course`,
			isActive: section == 'course',
			onClick: null,
			scroll: false,
		},
	]

	const overviewCourseItems = getLinkedItems(
		syllabus.elements.courses,
		syllabusResponse.linkedItems,
	).filter((a) => a.elements.display_overview.value[0].name === 'Yes')

	const hideCourseTab =
		overviewCourseItems.length == 0 &&
		isRichtextEmpty(syllabus.elements.course_content.value)

	if (hideCourseTab && section == 'course') {
		// redirect to overview
	}

	const sideNavItems = getLinkedItems(
		syllabus.elements.additional_content,
		syllabusResponse.linkedItems,
	)

	const currentSectionTitle =
		section == 'course' || !section
			? staticSideNavItems.find((item) => item.isActive).text
			: sideNavItems?.find((item) => item.elements.slug.value === section)
					?.elements.title.value

	// No section = index page
	const richTextContent = !section
		? syllabus.elements.web_content_rtb__content
		: section == 'course'
		? syllabus.elements.course_content
		: sideNavItems?.find((item) => {
				return item.elements.slug.value === section
		  })?.elements.content

	return (
		<div className="px-4 lg:px-0 pt-8">
			<SyllabusTabsTitle
				tabId="course-overview"
				mappings={mappings}
				syllabusCodename={syllabus.system.codename}
				className="relative !mb-0"
			/>
			<GridWrapper className="mt-0">
				<GridCol lg={3}>
					<SideNav
						css={overviewNavStyle}
						className="block"
						items={[
							...staticSideNavItems.filter((a) => {
								return hideCourseTab
									? a.text !== 'Course description'
									: true
							}),
							...sideNavItems.map((item) => {
								return {
									text: item.elements.title.value,
									href: `/learning-areas/${paramLearningArea}/${paramSyllabus}/overview/${item.elements.slug?.value}`,
									isActive:
										item.elements.slug.value === section,
									onClick: null,
									scroll: false,
								}
							}),
						]}
					/>
				</GridCol>
				<GridCol lg={9}>
					<div>
						<h3 className="mb-8">{currentSectionTitle}</h3>
						<RichText
							currentPath={router.asPath}
							mappings={mappings}
							data-kontent-item-id={syllabus.system.id}
							data-kontent-element-codename="web_content_rtb__content"
							linkedItems={syllabusResponse.linkedItems}
							className="cms-content-formatting"
							richTextElement={richTextContent}
							suffixForHeading={syllabus.system.codename}
							renderFnBefore={(nodes) => {
								const courseHeadings = overviewCourseItems?.map(
									(item) => {
										const text = `Further information for ${item.elements.name.value}`
										let id = slugify(
											item.elements.slug?.value,
											{ lower: true },
										)

										return parseHTML(`<h4>${text}</h4>`, {
											replace: (domNode: Element) => {
												return (
													<HeadingComponent
														as={domNode.name}
														id={id}
														attributes={null}
														disableCopyUrl={true}
													>
														{domToReact(
															domNode.children,
														)}
													</HeadingComponent>
												)
											},
										})
									},
								)
								let combinedHeadings =
									section !== 'course'
										? (nodes as JSX.Element)
										: []

								if (section == 'course') {
									if (
										isRichtextEmpty(
											syllabus.elements.course_content
												.value,
										)
									) {
										combinedHeadings = courseHeadings
									} else {
										combinedHeadings = Array.isArray(nodes)
											? [
													...(nodes as JSX.Element[]),
													...courseHeadings,
											  ]
											: (combinedHeadings = [
													nodes as JSX.Element,
													...courseHeadings,
											  ])
									}
								}
								return (
									<InPageNav
										richTextElements={
											combinedHeadings as JSX.Element[]
										}
										className="!mt-0"
									/>
								)
							}}
							resolveCourseItems={
								overviewCourseItems.length > 0 &&
								section == 'course'
									? overviewCourseItems
									: null
							}
						/>
					</div>
				</GridCol>
			</GridWrapper>
		</div>
	)
}

export default Overview
